const transformNewsLetter = (item: any, locale: string) => {
  if (!item) {
    return null;
  }
  return {
    __typename: 'NewsletterSubscription',
    campaignName: item?.campaignName || '',
    description: item.formDescription?.text,
    key: item.id,
    privacyPolicyUrl: item?.privacyPolicyPageLink?.slug,
    title: item.formHeading,
  };
};

export default transformNewsLetter;
