import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';

import { useAuth } from '../../../shared/auth/authContext';
import { withErrorBoundary } from '../../ErrorBoundary';
import Lock from '../../Lock/Lock';

import { StyledDescription, StyledImageWrapper, StyledLink, StyledTitle, StyledWrapper } from './Styled';

type Image = {
  title: string;
  alt?: string;
  asset: { gatsbyImageData: IGatsbyImageData };
};

export interface ArticleCardProps {
  title?: string;
  description?: string;
  image?: Image;
  ctaLabel?: string;
  link?: string;
  gated?: string;
}

const styling = tv({
  slots: {
    imageWrapper: 'relative',
  },
});

const getImageData = (image?: Image): IGatsbyImageData | null => {
  if (image && image.asset) {
    return image.asset.gatsbyImageData;
  }

  console.warn('NewsCard: No image data provided', JSON.stringify(image));

  return null;
};

const ArticleCard = ({ title, description, image, ctaLabel, link, gated, ...rest }: ArticleCardProps) => {
  const intl = useIntl();

  console.log('is gated? ' + gated);

  const gatedContentAvailable = process.env.GATED_CONTENT === 'true';
  const isLockedForNonAuthenticatedUsers = gatedContentAvailable && gated;
  const auth = useAuth();
  if (!auth) {
    return null;
  }
  const { state } = auth;
  const { imageWrapper } = styling();

  return (
    <StyledWrapper to={link} {...rest}>
      <StyledImageWrapper className={imageWrapper()}>
        {image && getImageData(image) && (
          <GatsbyImage
            alt={image.alt ? image.alt : image.title}
            image={getImageData(image) as IGatsbyImageData}
            objectFit="cover"
            style={{ height: '100%', margin: '0 auto' }}
          />
        )}
        {isLockedForNonAuthenticatedUsers && !state.isAuthenticated && <Lock />}
      </StyledImageWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledWrapper>
  );
};

export default withErrorBoundary(ArticleCard, { componentName: 'ArticleCard' });
