export const getUserPreferences = (accessToken: any, userState: any) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  let endpoint = '/.netlify/functions/getCustomerPreferences';

  if (process.env.NODE_ENV === 'development') {
    endpoint = `http://localhost:9999${endpoint}`;
  }

  return fetch(endpoint, {
    body: JSON.stringify({
      userAccessToken: accessToken,
      userEmailAddress: userState?.emails[0],
    }),
    headers,
    method: 'POST',
  }).then(response => response.json());
};
