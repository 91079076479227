import fetchPonyfill from 'fetch-ponyfill';

const { fetch, Headers } = fetchPonyfill();

// @todo: real implementation for newsletter service - TBD

const handleNewsletterFormSubmission = async (email: string) => {
  const method = 'POST';

  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  const options: RequestInit = {
    credentials: 'include',
    headers,
    method,
  };

  options.body = JSON.stringify({ email });

  const result = await fetch('/', options);

  return !!(result && result.status);
};

export default handleNewsletterFormSubmission;
