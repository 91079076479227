import richTextToJson from '../../utils/contentful-rich-text-raw-to-json';

const transformImageCard = (item: any, locale: string) => {
  return {
    __typename: 'ImageCard',
    description: item.shortDescription?.text,
    image: item.file,
    key: item.id,
    title: item.title,
    // change when pages are ready
    url: '/',
  };
};

export default transformImageCard;
