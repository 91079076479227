import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import extractCta from '../Cta';

const transformHeroBanner = (item: any) => {
  return {
    __typename: 'HeroBanner',
    alignText: item.alignText,
    cta: extractCta(item),
    ctaStyle: item.callToActionStyle,
    description: item.description?.raw ? documentToPlainTextString(JSON.parse(item.description.raw)) : '',
    height: item.height,
    image: {
      ...item.image,
      asset: { file: item.image?.asset?.file, gatsbyImageData: item.image?.imageWithFocalPoint },
    },
    key: item.id,
    overlayDesktop: item.overlayDesktop?.file?.url,
    overlayMobile: item.overlayMobile?.file?.url,
    overlayTablet: item.overlayTablet?.file?.url,
    secondCta: extractCta({ cta: item.callToActionSecond }),
    secondCtaStyle: item.callToActionSecondStyle,
    title: item.title,
    titleTag: item.titleTag,
    video: item.video,
  };
};

export default transformHeroBanner;
