interface Props {
  className?: string;
}
const TrashCan = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 19" className={className}>
    <path d="M5.625 14.3125C5.625 14.6289 5.34375 14.875 5.0625 14.875C4.74609 14.875 4.5 14.6289 4.5 14.3125V7C4.5 6.71875 4.74609 6.4375 5.0625 6.4375C5.34375 6.4375 5.625 6.71875 5.625 7V14.3125ZM8.4375 14.3125C8.4375 14.6289 8.15625 14.875 7.875 14.875C7.55859 14.875 7.3125 14.6289 7.3125 14.3125V7C7.3125 6.71875 7.55859 6.4375 7.875 6.4375C8.15625 6.4375 8.4375 6.71875 8.4375 7V14.3125ZM11.25 14.3125C11.25 14.6289 10.9688 14.875 10.6875 14.875C10.3711 14.875 10.125 14.6289 10.125 14.3125V7C10.125 6.71875 10.3711 6.4375 10.6875 6.4375C10.9688 6.4375 11.25 6.71875 11.25 7V14.3125ZM11.1445 1.12891L12.4453 3.0625H14.9062C15.3633 3.0625 15.75 3.44922 15.75 3.90625C15.75 4.39844 15.3633 4.75 14.9062 4.75H14.625V15.4375C14.625 17.0195 13.3594 18.25 11.8125 18.25H3.9375C2.35547 18.25 1.125 17.0195 1.125 15.4375V4.75H0.84375C0.351562 4.75 0 4.39844 0 3.90625C0 3.44922 0.351562 3.0625 0.84375 3.0625H3.26953L4.57031 1.12891C4.92188 0.601562 5.55469 0.25 6.22266 0.25H9.49219C10.1602 0.25 10.793 0.601562 11.1445 1.12891ZM5.30859 3.0625H10.4062L9.73828 2.07812C9.70312 2.00781 9.59766 1.9375 9.49219 1.9375H6.22266C6.11719 1.9375 6.01172 2.00781 5.97656 2.07812L5.30859 3.0625ZM2.8125 15.4375C2.8125 16.0703 3.30469 16.5625 3.9375 16.5625H11.8125C12.4102 16.5625 12.9375 16.0703 12.9375 15.4375V4.75H2.8125V15.4375Z" />
  </svg>
);

export default TrashCan;
