import { useConfiguration } from '@arvesta-websites/configuration';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './slick-custom.css';

import { HeadingTag, withErrorBoundary } from '../../components';
import HeroBanner, { type HeroBannerProps } from '../HeroBanner';
import LogoCard, { type LogoCardProps } from '../LogoCard';

import { CarouselItemCard, type CarouselItemCardProps, CarouselWrapper } from './components';
import { StyledCardWrapper } from './Styled';
import { getSliderSettings } from './utils';

interface CarouselPropsBase {
  title: string;
  titleTag: HeadingTag;
}

export type CarouselProps =
  | (CarouselPropsBase & {
      contentEntries: HeroBannerProps[];
      carouselType: 'Hero';
    })
  | (CarouselPropsBase & {
      contentEntries: CarouselItemCardProps[];
      carouselType: 'Image Card';
    })
  | (CarouselPropsBase & {
      contentEntries: LogoCardProps[];
      carouselType: 'Logo Card';
    });

const Carousel = ({ title, titleTag, contentEntries, carouselType }: CarouselProps) => {
  const config = useConfiguration();
  const isHero = carouselType === 'Hero';
  const isLogo = carouselType === 'Logo Card';
  const sliderRef = useRef<Slider>(null);
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [currentOverlaySVG, setCurrentOverlaySVG] = useState(
    carouselType === 'Hero' ? (contentEntries[0] as HeroBannerProps).overlayDesktop || null : null,
  );
  const [currentHeight, setCurrentHeight] = useState(
    carouselType === 'Hero' ? (contentEntries[0] as HeroBannerProps).height || 'Regular' : 'Regular',
  );

  const controlHeight = useMemo(() => {
    if (!isHero) return undefined;

    if (currentOverlaySVG !== null) return '80px';
    if (config.heroBanner.customMask) return config.carousel?.controlHeight;
    return '0px';
  }, [isHero, currentOverlaySVG, config.heroBanner.customMask, config.carousel?.controlHeight]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth);

      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  const shouldBeInfinite = useCallback(() => {
    if (isHero && contentEntries.length > 1) {
      return true;
    }

    if (isLogo && contentEntries.length > 4) {
      return true;
    }

    return !isHero && !isLogo && contentEntries.length > 3;
  }, [contentEntries.length, isHero, isLogo]);

  const handleBeforeChange = useCallback(
    (newSlide: number) => {
      const nextEntry = contentEntries[newSlide];

      if (carouselType === 'Hero') {
        const heroEntry = nextEntry as HeroBannerProps;
        setCurrentOverlaySVG(heroEntry.overlayDesktop || null);
        if (heroEntry && heroEntry.height) {
          setCurrentHeight(heroEntry.height);
        }
      }
    },
    [contentEntries, carouselType],
  );

  const handleAfterChange = useCallback(
    (currentSlide: number) => {
      const currentEntry = contentEntries[currentSlide];

      if (carouselType === 'Hero') {
        const heroEntry = currentEntry as HeroBannerProps;
        setCurrentOverlaySVG(heroEntry.overlayDesktop || null);
        if (heroEntry && heroEntry.height) {
          setCurrentHeight(heroEntry.height);
        }
      }
    },
    [contentEntries, carouselType],
  );

  const settings = useMemo(
    () => ({
      ...getSliderSettings(isHero, isLogo, config.heroBanner?.mask, controlHeight, shouldBeInfinite()),
      afterChange: handleAfterChange,
      beforeChange: handleBeforeChange,
    }),
    [isHero, isLogo, config.heroBanner?.mask, handleBeforeChange, handleAfterChange, controlHeight, shouldBeInfinite],
  );

  if (carouselType === 'Hero') {
    return (
      <CarouselWrapper
        title={title}
        titleTag={titleTag}
        isHero={true}
        windowWidth={windowWidth}
        shouldBeInfinite={shouldBeInfinite()}
        sliderRef={sliderRef}
        hasOverlaySVG={currentOverlaySVG}
        height={currentHeight}
      >
        <Slider {...settings} ref={sliderRef}>
          {contentEntries.map(entry => {
            return (
              <HeroBanner
                cta={entry.cta}
                ctaStyle={entry.ctaStyle}
                image={entry.image}
                key={entry.key}
                secondCta={entry.secondCta}
                secondCtaStyle={entry.secondCtaStyle}
                title={entry.title}
                alignText={entry.alignText}
                partOfCarousel={true}
                height={entry.height}
                overlayDesktop={entry?.overlayDesktop}
                overlayTablet={entry?.overlayTablet}
                overlayMobile={entry?.overlayMobile}
                titleTag="div"
              />
            );
          })}
        </Slider>
      </CarouselWrapper>
    );
  }

  if (carouselType === 'Logo Card') {
    return (
      <CarouselWrapper
        title={title}
        titleTag={titleTag}
        isHero={false}
        windowWidth={windowWidth}
        shouldBeInfinite={shouldBeInfinite()}
        sliderRef={sliderRef}
      >
        <Slider {...settings} ref={sliderRef}>
          {contentEntries.map((entry, id) => {
            return <LogoCard image={entry.image} cta={entry.cta} title={entry.title} key={id.toString()} />;
          })}
        </Slider>
      </CarouselWrapper>
    );
  }

  return (
    <CarouselWrapper
      title={title}
      titleTag={titleTag}
      isHero={false}
      windowWidth={windowWidth}
      shouldBeInfinite={shouldBeInfinite()}
      sliderRef={sliderRef}
    >
      <Slider {...settings} ref={sliderRef}>
        {contentEntries.map((entry, id) => {
          return (
            <StyledCardWrapper key={id}>
              <CarouselItemCard
                key={id.toString()}
                ctaLabel={entry.ctaLabel}
                description={entry.description}
                image={entry.image}
                link={entry.link}
                title={entry.title}
                gated={entry.gated ?? false}
              />
            </StyledCardWrapper>
          );
        })}
      </Slider>
    </CarouselWrapper>
  );
};

export default withErrorBoundary(Carousel, { componentName: 'Carousel' });
