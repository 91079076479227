import { LockCta } from '@arvesta-websites/ui-babel';
import {
  DocumentDownload,
  type DocumentDownloadProps,
  HeroSectionModule,
  InfoSectionModule,
  NewsListingModule,
  type NewsListingModuleProps,
  ProductCardModule,
  type ProductCardModuleProps,
  RichText,
  type RichTextProps,
} from '@arvesta-websites/ui-babel';
import React from 'react';

import {
  DocumentDownloadContainer,
  StyledCenteredText,
  StyledContainer,
  StyledHeaderContainer,
  StyledModuleContainer,
} from './Styled';

export type collection = {
  __typename: string;
  title: string;
  shortDescription?: string;
  cta?: any;
};

interface NewsPageProps {
  title: string;
  shortDescription?: string;
  date?: string;
  contentsSection: RichTextProps;
  image: any;
  featuredProducts?: ProductCardModuleProps;
  newsSection?: NewsListingModuleProps;
  documentDownload?: DocumentDownloadProps;
  isAuthenticated: boolean;
  isLockedForNonAuthenticatedUsers?: boolean;
}

const NewsPage = ({
  title,
  shortDescription,
  contentsSection,
  image,
  featuredProducts,
  newsSection,
  documentDownload,
  isAuthenticated,
  isLockedForNonAuthenticatedUsers,
}: NewsPageProps) => {
  return (
    <>
      <StyledContainer>
        <StyledHeaderContainer>
          <HeroSectionModule title={title} shortDescription={shortDescription} />
        </StyledHeaderContainer>
      </StyledContainer>
      {isAuthenticated || !isLockedForNonAuthenticatedUsers ? (
        <>
          <InfoSectionModule image={image} />
          <StyledCenteredText>
            <RichText {...contentsSection} />
            {documentDownload && (
              <DocumentDownloadContainer>
                <DocumentDownload
                  documentTitle={documentDownload.documentTitle}
                  documentType={documentDownload.documentType}
                  fileSize={documentDownload.fileSize}
                  downloadLink={documentDownload.downloadLink}
                  showDocumentInfo
                />
              </DocumentDownloadContainer>
            )}
          </StyledCenteredText>
          <StyledModuleContainer data-appsearch-ignore="true">
            {featuredProducts && <ProductCardModule {...featuredProducts} greyVariant />}
            {newsSection && <NewsListingModule {...newsSection} />}
          </StyledModuleContainer>
        </>
      ) : (
        <LockCta></LockCta>
      )}
    </>
  );
};

export default NewsPage;
