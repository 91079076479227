import richTextToJson from '../../utils/contentful-rich-text-raw-to-json';

const transformRichTextSection = (item: any, locale: string) => {
  return {
    __typename: 'Text',
    alignment: item.alignment,
    anchorSlug: item?.anchorSlug,
    data: item.richText ? richTextToJson(item.richText, locale).json : null,
    key: item.id,
    widthSize: item.widthSize,
  };
};

export default transformRichTextSection;
