import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

interface LocationProps {
  location: {
    search: string;
  };
}

const RedirectPage: React.FC<LocationProps> = ({ location }) => {
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const destination = queryParams.get('destination');

    if (destination) {
      navigate(`/${destination}`, { replace: true });
    } else {
      navigate('/', { replace: true }); // Fallback if no destination is provided
    }
  }, [location.search]);

  return null; // This component doesn't need to render anything
};

export default RedirectPage;
