import { Box, Flex } from 'rebass/styled-components';
import styled, { keyframes } from 'styled-components';

import Media from '../../utils/Media';
//

const slideIn = keyframes`
  from {
    top: -80px;
  }

  to {
    top: 0px;
  }
`;

const slideInActiveSubMenu = keyframes`
  from {
    top: -160px;
  }

  to {
    top: 0px;
  }
`;

const slideOut = keyframes`
  from {
    top: 0px;
  }

  to {
    top: -80px;
  }
`;

const slideOutActiveSubMenu = keyframes`
  from {
    top: 0px;
  }

  to {
    top: -160px;
  }
`;

export const StyledHamburgerMenu = styled(Box)`
  justify-content: center;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  width: 3.75rem;

  > span {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
  }

  svg {
    vertical-align: middle;
  }

  ${Media({
    display: ['flex', null, null, 'none'],
  })}
`;

export const StyledHeader = styled(Box)`
  background-color: ${props => props.theme.header.backgroundColor};
  color: ${props => props.theme.header.color};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;

  &.is-visible.active-secondary-menu {
    visibility: visible;
    animation: ${slideInActiveSubMenu} 0.25s linear;
    transition: 0.25s linear;
  }
  &.is-hidden.active-secondary-menu {
    visibility: hidden;
    animation: ${slideOutActiveSubMenu} 0.25s linear;
    transition: 0.25s linear;
  }

  &.is-visible {
    visibility: visible;
    animation: ${slideIn} 0.25s linear;
    transition: 0.25s linear;
  }
  &.is-hidden {
    visibility: hidden;
    animation: ${slideOut} 0.25s linear;
    transition: 0.25s linear;
  }
`;

export const StyledHeaderBottom = styled(Box)`
  margin: -1px auto 0;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.headerBottom.backgroundColor};
  ul {
    margin: 0 auto;
  }

  ${Media({
    display: ['none', null, null, 'flex'],
    height: ['80px', '80px', '80px', '88px'],
  })}
`;

export const StyledHeaderInnerContainer = styled(Box)`
  ${Media({
    'padding-left': [null, null, '20px'],
    'padding-right': [null, null, '20px'],
  })}
  min-height: 77px;
`;

export const StyledHeaderTop = styled(Flex)`
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  max-width: 1324px;
  min-height: 76px;

  > div {
    &:first-child {
      @media all and (min-width: 920px) {
        width: calc(200 / 1324 * 100%);
      }

      @media all and (min-width: ${props => props.theme.sizes.lg}) {
        width: calc(350 / 1324 * 100%);
      }
    }
  }
`;

export const StyledHeaderTopSecondaryVariant = styled.div`
  background-color: #e9eae4;
  padding: 6px 0;

  ${Media({
    display: ['none', null, null, 'block'],
  })}
`;

export const StyledHeaderTopSecondaryVariantInner = styled.div`
  max-width: 1324px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
`;

export const StyledMobileIcons = styled(Box)`
  ${Media({
    display: ['flex', null, null, 'none'],
  })}
`;

export const StyledMobileNavigation = styled(Box)`
  position: fixed;
  z-index: 100;
  right: 0;
  top: 0;
  height: 100%;
  width: 320px;
  ${Media({
    width: ['320px', null, '70%', '320px'],
  })}
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
`;

export const StyledRightSection = styled(Box)`
  width: calc(350 / 1324 * 100%);
  justify-content: flex-end;

  ${Media({
    display: ['none', null, null, 'flex'],
  })}
`;

export const StyledSearchMenu = styled(Box)`
  justify-content: center;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  width: 3.75rem;
  padding-left: 35px;

  > span {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
  }

  svg {
    vertical-align: middle;
  }

  ${Media({
    display: ['flex', null, null, 'none'],
  })}
`;

export const StyledSeparator = styled(Box)`
  margin: 0 auto;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
`;
