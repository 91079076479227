// eslint-disable-next-line @typescript-eslint/no-var-requires
const ElasticAppSearch = require('@elastic/app-search-javascript');

const client = ElasticAppSearch.createClient({
  endpointBase: process.env.SEARCH_ENDPOINT_BASE,
  engineName: process.env.SEARCH_ENGINE_NAME,
  searchKey: process.env.SEARCH_KEY,
});

const search = (query: string, locale: string) => {
  const options = {
    filters: {
      all: [{ page_lang: locale }, { site_id: [process.env.SEARCH_SITE_ID] }],
    },
    page: {
      size: 30,
    },
  };

  return client
    .search(`\"${query}\"`, options) // eslint-disable-line
    .then((resultList: any) => {
      return resultList.results
        .map((result: any) => {
          return {
            date: result.data.date ? result.data.date.raw : null,
            description: result.data.description.raw,
            headings: result.data.headings.raw,
            id: result.data.id.raw,
            image: result.data.image ? result.data.image.raw : null,
            location: result.data.location ? result.data.location.raw : null,
            page_id: result.data.page_id.raw,
            page_lang: result.data.page_lang.raw,
            page_type: result.data.page_type.raw,
            page_updated: result.data.page_updated.raw,
            path: result.data.path.raw,
            preview_image: result.data.preview_image ? result.data.preview_image.raw : null,
            // eslint-disable-next-line no-underscore-dangle
            score: result.data._meta.score,
            site_id: result.data.site_id.raw,
            text: result.data.text.raw,
            title: result.data?.title?.raw ?? '',
          };
        })
        .filter((item: any) => item.score >= 0.9)
        .filter((item: any) => item.path !== '/')
        .filter((item: any) => item.path !== '/en/')
        .filter((item: any) => item.path !== '/fr/')
        .filter((item: any) => item.path !== '/de/')
        .filter((item: any) => item.path !== '/nl');
    })
    .catch((error: any) => {
      console.error(error.errorMessages);
    });
};

export default search;
