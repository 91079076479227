import fetchPonyfill from 'fetch-ponyfill';

const { fetch, Headers } = fetchPonyfill();

const queryParams = (params: any) =>
  Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

const handleNetlifyFormSubmission = (name: string, values: any) => {
  const method = 'POST';

  const headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  const options: RequestInit = {
    credentials: 'include',
    headers,
    method,
  };

  options.body = queryParams({ 'form-name': name, ...values });

  const result = fetch('/', options)
    .then(response => {
      return {
        status: response.status || 500,
      };
    })
    .catch(() => {
      return { status: 500 };
    });

  return result;
};

export default handleNetlifyFormSubmission;
