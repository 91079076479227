export const FONTMAP: Record<string, string[]> = {
  alliance: [
    'https://fonts.googleapis.com/css2?family=Encode+Sans:wght@300;400;600;700&display=swap',
    'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap',
  ],
  arieblok: ['/fonts/ropasans.css'],
  arvesta: ['/fonts/encodesans.css'],
  aveveagrarisch: ['https://use.typekit.net/hji8mfd.css'],
  avevebiochem: ['https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap'],
  avevemedia: ['https://use.typekit.net/hji8mfd.css'],
  aveveevents: ['/fonts/encodesans.css'],
  belcrop: [],
  cofabel: ['/fonts/johndeeresans.css'],
  equifirst: ['/fonts/myriadpro.css'],
  equilannoo: ['https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap'],
  eurotuin: ['https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap'],
  hafner: ['/fonts/futura.css'],
  hermoo: ['https://fonts.googleapis.com/css2?family=Lato:wght@300;400;600;700&display=swap'],
  hobbyfirst: ['/fonts/titilliumWeb.css'],
  hortiplan: [
    'https://fonts.googleapis.com/css2?family=Encode+Sans&family=Nunito+Sans:wght@300;400;600;700&display=swap',
  ],
  kasperfaunafood: ['https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&display=swap'],
  lannoo: ['https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap'],
  naturalgranen: ['/fonts/stagsans.css'],
  nuverta: [
    'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
  ],
  osmo: ['https://fonts.googleapis.com/css2?family=Hind:wght@300;400;600;700&display=swap'],
  palital: [],
  peka: ['/fonts/helvetica.css'],
  pentatrading: ['https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap'],
  pomagro: ['https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&display=swap'],
  proxani: [
    'https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap',
    'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
  ],
  sabe: ['https://fonts.googleapis.com/css2?family=Krub:wght@300;400;600;700&display=swap'],
  sanac: [
    'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
  ],
  scoriethom: ['/fonts/encodesans.css'],
  servagri: ['/fonts/encodesans.css'],
  spoormans: ['https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&display=swap'],
  walagri: ['/fonts/encodesans.css'],
};
