const transformVideo = (item: any) => {
  return {
    __typename: 'Video',
    key: item.id,
    position: item.position,
    size: item.size,
    videoUrl: item.videoUrl,
  };
};

export default transformVideo;
