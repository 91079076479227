import { ContentfulArticleDetailPage, ContentfulArticleDetailPageConnection } from '../../__generated__/graphql-types';

type Category = {
  children: any[];
  contentful_id: string;
  id: string;
  title: string;
  article_detail_page: any;
  slug: string;
};

type ArticleDetailPage = Omit<ContentfulArticleDetailPage, 'shortDescription'> & {
  shortDescription: { text: string };
  gated: boolean;
};

const transformArticles = (articles: ArticleDetailPage[]) => {
  if (!articles) {
    return [];
  }

  return articles?.filter(Boolean)?.map(article => {
    return {
      categories: article.categories,
      contentfulId: article.contentful_id,
      description: article.shortDescription?.text,
      gated: article.gated,
      id: article.id,
      image: { ...article.imageCard?.image, asset: { gatsbyImageData: article.imageCard?.image?.imageWithFocalPoint } },
      link: article.slug,
      title: article.title,
    };
  });
};

const calculateArticleCount = (
  category: { contentful_id: string; children?: { contentful_id: string }[] },
  allArticles: any[],
) => {
  const relevantCategoryIds = [category.contentful_id];
  if (category.children) {
    category.children.forEach(child => {
      relevantCategoryIds.push(child.contentful_id);
    });
  }

  return allArticles.filter(article =>
    article.categories?.some((cat: { contentful_id: string }) => relevantCategoryIds.includes(cat.contentful_id)),
  ).length;
};

const transformCategories = (categories: Category[], allArticles: any[]) => {
  return categories?.map(category => {
    return {
      __typename: 'Category',
      articleCount: calculateArticleCount(category, allArticles),
      articles: transformArticles([
        ...(category.article_detail_page ?? []),
        ...(category?.children?.map(child => child.article_detail_page).flat() ?? []),
      ]),
      children: category.children?.map(child => {
        return {
          __typename: 'Category',
          articleCount: calculateArticleCount(
            {
              children: [],
              contentful_id: child.contentful_id,
            },
            allArticles,
          ),
          articles: transformArticles(child.article_detail_page),
          contentfulId: child.contentful_id,
          id: child.id,
          label: child.title,
          slug: child.slug,
        };
      }),
      contentfulId: category.contentful_id,
      id: category.id,
      label: category.title,
      slug: category.slug,
    };
  });
};

const articleProperties = (article: ArticleDetailPage) => {
  return {
    categories: article.categories ?? undefined,
    contentfulId: article.contentful_id,
    description: article.shortDescription?.text,
    gated: article.gated,
    id: article.id,
    image: { ...article.imageCard?.image, asset: { gatsbyImageData: article.imageCard?.image?.imageWithFocalPoint } },
    link: article.slug,
    title: article.title,
  };
};

type ArticleListingSectionData = {
  categories: Category[];
  categoriesTitle?: string;
  contentful_id: string;
  id: string;
  shortDescription: { text: string };
  title: string;
  titleTag?: string;
};

const transformArticleListingSection = (
  data: ArticleListingSectionData,
  articleList: ContentfulArticleDetailPageConnection,
) => {
  const mainCategoryIds = new Set(data.categories?.map(category => category.contentful_id) ?? []);

  const transformedArticles = articleList.nodes
    .filter(article => article.categories?.some(category => mainCategoryIds.has(category.contentful_id)))
    .map(article => {
      return {
        ...articleProperties(article),
        categories: article.categories,
      };
    });

  return {
    __typename: 'ArticleListingSection',
    articles: transformedArticles,
    categories: transformCategories(data.categories, transformedArticles),
    categoriesTitle: data.categoriesTitle,
    contentfulId: data.contentful_id,
    id: data.id,
    shortDescription: data.shortDescription?.text,
    title: data.title,
    titleTag: data.titleTag,
  };
};

// TODO
/**
    if (category.children) {
      category.children.forEach(child => {
        if (child.article_detail_page) {
          child.article_detail_page.forEach(article => {
            if (uniqueArticleIds.includes(article.id)) {
              return;
            }

            const gated = article.gated === true;

            articles.push({
              contentfulId: article.contentful_id,
              description: article.shortDescription?.text,
              gated: gated,
              id: article.id,
              image: {
                ...article.imageCard?.image,
                asset: { gatsbyImageData: article.imageCard?.image?.imageWithFocalPoint },
              },
              link: article.slug,
              title: article.title,
            });
            uniqueArticleIds.push(article.id);
          });
        }
      });
    }

 */

export default transformArticleListingSection;
