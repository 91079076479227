import { CloseCustom, Filter } from '@arvesta-websites/icons';
import { navigate as gatsbyNavigate } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { HeadingTag, withErrorBoundary } from '../../components';

import ArticlesList from './ArticleList';
import Filters from './Filters';
import {
  StyledArticleWrapper,
  StyledBottomNavigationMobile,
  StyledFilterButton,
  StyledFilterButtonMobile,
  StyledFilterTitleMobile,
  StyledFilterWrapper,
  StyledIconButton,
  StyledInnerButton,
  StyledMobileFilter,
  StyledMobileFilterWrapper,
  StyledMobileFooter,
  StyledMobileHeader,
  StyledTitle,
  StyledTitleWrapper,
  StyledWrapper,
} from './Styled';
type Image = {
  title: string;
  alt?: string;
  asset: {
    gatsbyImageData: IGatsbyImageData;
  };
};
export type Article = {
  categories?: Category[];
  contentfulId: string;
  description: string;
  id: string;
  image: Image;
  link: string;
  title: string;
  gated?: boolean;
};

export type Category = {
  __typename: 'Category';
  articleCount: number;
  contentfulId: string;
  id: string;
  label: string;
  slug: string;
  articles: Article[];
  children?: Category[];
};

export interface ArticleListingProps {
  title?: string | null;
  titleTag?: HeadingTag;
  articles?: Article[];
  categories: Category[];
  categoriesTitle?: string;
  contentfulId: string;
  id: string;
  shortDescription?: string;
  __typename: 'ArticleListingSection';
}

const FilterResetButton = ({ onClick }: { onClick: () => void }) => {
  const intl = useIntl();
  return (
    <span className="cursor-pointer hover:font-bold hover:underline mt-2 text-content text-sm" onClick={onClick}>
      {intl.formatMessage({ id: 'articleListing.filtersClear' })}
    </span>
  );
};

const MAX_ITEMS = 18;

const ArticleListing = ({ categories, categoriesTitle, articles, title, titleTag }: ArticleListingProps) => {
  // console.log(articles);

  const [activeCategoriesId, setActiveCategoriesId] = useState<{ child: string | null; parent: string | null }>({
    child: null,
    parent: null,
  });
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const [numberOfArticlesToShow, setNumberOfArticlesToShow] = useState(MAX_ITEMS);
  const [filteredArticles, setFilteredArticles] = useState(articles || []);

  const intl = useIntl();

  const navigationTimeout = useRef<NodeJS.Timeout>();

  const setArticles = useCallback(
    (selectedCategory: Category) => {
      const params = new URLSearchParams(document.location.search);
      setNumberOfArticlesToShow(MAX_ITEMS);

      const isSubCategory = categories.some(parent => parent.children?.some(child => child.id === selectedCategory.id));

      if (isSubCategory) {
        const parentCategory = categories.find(
          parent =>
            (activeCategoriesId.parent === parent.id || !activeCategoriesId.parent) &&
            parent.children?.some(child => child.id === selectedCategory.id),
        );

        if (parentCategory) {
          setActiveCategoriesId({
            child: selectedCategory.id,
            parent: parentCategory.id,
          });

          const filtered = articles?.filter(article =>
            article.categories?.some(
              category =>
                category.id === selectedCategory.id &&
                article.categories?.some(parentCat => parentCat.id === parentCategory.id),
            ),
          );
          setFilteredArticles(filtered || []);

          params.set('category', selectedCategory.slug);
          params.set('parent', parentCategory.slug);
        }
      } else {
        setActiveCategoriesId({
          child: null,
          parent: selectedCategory.id,
        });

        const filtered = articles?.filter(article =>
          article.categories?.some(category => category.id === selectedCategory.id),
        );
        setFilteredArticles(filtered || []);

        params.set('category', selectedCategory.slug);
        params.delete('parent');
      }

      // Update URL without triggering navigation events
      const url = `?${params.toString()}`;
      window.history.replaceState(null, '', url);
    },
    [articles, categories, activeCategoriesId],
  );

  const loadMoreItems = useCallback(() => {
    setNumberOfArticlesToShow(prev => prev + MAX_ITEMS);
  }, []);

  const clearFilters = useCallback(() => {
    setActiveCategoriesId({ child: null, parent: null });
    setFilteredArticles(articles || []);

    const params = new URLSearchParams(document.location.search);
    params.delete('category');
    const url = `${window.location.pathname}?${params.toString()}`;
    window.history.pushState({ path: url }, '', url);
  }, [articles]);

  const handleMenuClick = useCallback(
    (category: Category) => {
      setNumberOfArticlesToShow(MAX_ITEMS);
      setArticles(category);
    },
    [setArticles],
  );

  useEffect(() => {
    return () => {
      if (navigationTimeout.current) {
        clearTimeout(navigationTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const category = params.get('category');
    const parent = params.get('parent');

    if (category) {
      const allCategories = categories
        .map(item => {
          if (item?.children) {
            return [...item.children, item];
          }
          return item;
        })
        .flat();

      const categoryObj = allCategories.find(item => item.slug === category);

      if (categoryObj) {
        if (parent) {
          const parentObj = categories.find(item => item.slug === parent);
          if (parentObj && parentObj.children?.some(child => child.slug === category)) {
            setActiveCategoriesId({
              child: categoryObj.id,
              parent: parentObj.id,
            });

            const filtered = articles?.filter(article =>
              article.categories?.some(
                category =>
                  category.id === categoryObj.id &&
                  article.categories?.some(parentCat => parentCat.id === parentObj.id),
              ),
            );
            setFilteredArticles(filtered || []);
          }
        } else {
          setActiveCategoriesId({
            child: null,
            parent: categoryObj.id,
          });

          const filtered = articles?.filter(article =>
            article.categories?.some(category => category.id === categoryObj.id),
          );
          setFilteredArticles(filtered || []);
        }
      }
    }
  }, []);

  return (
    <div className="pt-10 articleListing">
      <StyledWrapper>
        <StyledFilterWrapper hasTitle={!!title}>
          {categoriesTitle && <h2 className="font-bold">{categoriesTitle}</h2>}
          <Filters
            categories={categories}
            activeCategoriesId={activeCategoriesId}
            handleMenuClick={handleMenuClick}
            articles={articles || []}
          />
          {(activeCategoriesId.child || activeCategoriesId.parent) && <FilterResetButton onClick={clearFilters} />}
        </StyledFilterWrapper>

        <StyledArticleWrapper>
          <StyledTitleWrapper>
            <StyledTitle variant="h2" tag={titleTag}>
              {title}
            </StyledTitle>
            <StyledFilterButton onClick={() => setMobileMenuVisible(!mobileMenuVisible)}>
              <StyledInnerButton>
                <Filter />
                {intl.formatMessage({ id: 'articleListing.filters' })}
              </StyledInnerButton>
            </StyledFilterButton>
          </StyledTitleWrapper>
          <ArticlesList
            articles={filteredArticles}
            numberOfArticlesToShow={numberOfArticlesToShow}
            handleLoadMore={loadMoreItems}
          />
        </StyledArticleWrapper>
      </StyledWrapper>
      {mobileMenuVisible && (
        <StyledMobileFilterWrapper>
          <StyledMobileFilter>
            <StyledMobileHeader>
              <StyledFilterTitleMobile>{intl.formatMessage({ id: 'articleListing.filters' })}</StyledFilterTitleMobile>
              <StyledIconButton onClick={() => setMobileMenuVisible(false)}>
                <CloseCustom />
              </StyledIconButton>
            </StyledMobileHeader>

            <StyledBottomNavigationMobile>
              {categoriesTitle && <h2 className="font-bold">{categoriesTitle}</h2>}
              <Filters
                categories={categories}
                activeCategoriesId={activeCategoriesId}
                handleMenuClick={handleMenuClick}
                articles={articles || []}
              />
              {(activeCategoriesId.child || activeCategoriesId.parent) && <FilterResetButton onClick={clearFilters} />}
            </StyledBottomNavigationMobile>

            <StyledMobileFooter>
              <StyledFilterButtonMobile onClick={() => setMobileMenuVisible(false)}>
                {intl.formatMessage({ id: 'articleListing.applyFilters' })}
              </StyledFilterButtonMobile>
            </StyledMobileFooter>
          </StyledMobileFilter>
        </StyledMobileFilterWrapper>
      )}
    </div>
  );
};
export default withErrorBoundary(ArticleListing, { componentName: 'ArticleListingModule' });
