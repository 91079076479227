import richTextToJson from '../../utils/contentful-rich-text-raw-to-json';
import extractCta from '../Cta';

const COLUMN_TYPES = {
  IMAGE: 'ContentfulImage',
  IMAGE_CARD: 'ContentfulImageCardImageLink',
  INFO_BLOCK: 'ContentfulInfoBlock',
  RICH_TEXT: 'ContentfulRichTextSection',
};

const mapContent = (items: any, locale: string) => {
  return items?.map(item => {
    if (item.__typename === COLUMN_TYPES.IMAGE) {
      return { ...item.asset, type: item.__typename };
    }

    if (item.__typename === COLUMN_TYPES.RICH_TEXT) {
      return {
        richText: {
          json: item?.richText ? richTextToJson(item?.richText, locale).json : null,
        },
        type: item.__typename,
      };
    }

    if (item.__typename === COLUMN_TYPES.IMAGE_CARD) {
      return {
        description: item.shortDescription?.text,
        id: item.id,
        image: { ...item.image, asset: { gatsbyImageData: item.image?.imageWithFocalPoint } },
        imageTitle: item.imageTitle,
        link: item.cardLink?.slug,
        title: item.title,
        type: item.__typename,
      };
    }

    if (item.__typename === COLUMN_TYPES.INFO_BLOCK) {
      return {
        cta: item.callToAction,
        description: item?.richText ? richTextToJson(item?.richText, locale).json : null,
        id: item.id,
        title: item.title,
        type: item.__typename,
      };
    }
  });
};

const transformColumnComponent = (item: any, locale: string) => ({
  __typename: 'ColumnsComponent',
  content: mapContent(item.rowContent, locale),
  key: item.id,
});

export default transformColumnComponent;
