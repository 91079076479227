import { Box } from 'rebass';
import styled from 'styled-components';

import Media from '../../utils/Media';
import { HEADER_VARIANTS } from '../TopLevelHeader/Header';

interface FooterProps {
  hasNewsletter?: boolean;
}

export const StyledContainer = styled(Box)`
  flex-grow: 1;
`;

export const StyledFooter: React.FC<FooterProps> = styled(Box)<FooterProps>`
  flex-shrink: 0;
  margin-top: ${props => (props.hasNewsletter ? '0' : '57px !important')};
`;

type IStyledMain = {
  headerVariant: string;
  headerIncludesBreadcrumb: boolean;
  isHomePage: boolean;
};

export const StyledHeader = styled(Box)`
  flex-shrink: 0;
`;

export const StyledLegacyContentWrapper = styled.div`
  padding-bottom: 1rem;
  max-width: ${props => props.theme.sizes.xl};
  flex-shrink: 0;
  overflow-x: hidden;
  margin: 0 auto;
`;

export const StyledMain = styled.main<IStyledMain>`
  overflow: hidden;
  padding-bottom: 1rem;
  max-width: ${props => props.theme.sizes.xl};
  flex-shrink: 0;
  margin: 0 auto;
  ${props =>
    props.headerVariant === HEADER_VARIANTS.topLevel
      ? Media({ marginTop: ['79px', null, null, '88px'] })
      : `margin-top: 0;`}

  ${props =>
    !props.headerIncludesBreadcrumb || props.isHomePage
      ? Media({ paddingTop: ['86px', null, null, '127px'] })
      : 'padding-top: 0;'}

  &.active-secondary-menu {
    ${Media({
      paddingTop: ['0px', null, null, '88px'],
    })}
  }

  #content-wrapper {
    padding: 0 50px;

    @media (max-width: 1024px) {
      padding: 0 20px;
    }
  }
`;

export const StyledModuleContainer = styled.div<StyledModuleContainer>`
  max-width: ${props => props.theme.sizes.xl};
  position: relative;

  ${Media({
    marginTop: ['56px', null, null, '81px'],
  })}

  ${props =>
    props.extraSmallTopSpacer &&
    `
    margin-top: 1.7rem !important;
  `}

  ${props =>
    props.smallTopSpacer &&
    `
    margin-top: 56px !important;
  `}
`;

interface StyledModuleContainer {
  smallTopSpacer?: boolean;
  extraSmallTopSpacer?: boolean;
}

export const StyledModuleContainerInfoVariant = styled.div`
  max-width: ${props => props.theme.sizes.xl};
  position: relative;

  ${Media({
    marginTop: ['56px', null, null, '81px'],
  })}
`;

export const StyledModuleCtaContainer = styled.div`
  ${Media({
    marginTop: ['23px', null, null, '39px'],
  })}
`;

export const StyledRootContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: stretch;
  font-family: ${props => props.theme.fonts.body};
`;
