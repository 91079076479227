import { User } from '@arvesta-websites/icons';
import React, { useEffect, useRef, useState } from 'react';

import { Cta } from '../Cta';

interface DropdownProps {
  label: string;
  items: { to: string | (() => void); label: string }[];
  isMobileMenu?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({ label, items, isMobileMenu = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (!isMobileMenu) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isMobileMenu]);

  return (
    <div ref={dropdownRef} className={`relative inline-block text-left`}>
      <div>
        <button
          className={`cursor-pointer h-8 ${!isMobileMenu ? 'text-white hover:text-header-ribbon-hover hover:underline' : 'mobile-menu-item'} ribonItem no-underline underline-offset-base flex items-center whitespace-nowrap opacity-100`}
          onClick={toggleDropdown}
        >
          <User className="mr-[5px] h-[17px] w-[17px] fill-current" />
          {label}
          <svg
            className={`-mr-1 ml-2 h-5 w-5 transition-transform duration-200 ${isOpen && isMobileMenu ? '-rotate-90' : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className={`${isMobileMenu ? 'flex flex-col mt-2' : 'origin-top-right absolute right-0 mt-2 shadow-lg bg-white z-50'}`}
        >
          <div
            className={`${isMobileMenu ? '' : 'py-1'}`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {items.map((item, index) => (
              <React.Fragment key={index}>
                {typeof item.to === 'string' ? (
                  <Cta
                    to={item.to}
                    className={`block px-4 py-1 text-sm text-gray-700 ${isMobileMenu ? '' : 'hover:bg-gray-100 hover:text-gray-900'} whitespace-nowrap`}
                  >
                    {item.label}
                  </Cta>
                ) : (
                  <div
                    onClick={typeof item.to === 'function' ? item.to : undefined}
                    className={`block px-4 py-1 text-sm text-gray-700 ${isMobileMenu ? '' : 'hover:bg-gray-100 hover:text-gray-900'} whitespace-nowrap cursor-pointer`}
                  >
                    {item.label}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
