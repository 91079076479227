import { LockIcon } from '@arvesta-websites/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';

import { useAuth } from '../../shared/auth/authContext';
import { handleLogin } from '../../shared/auth/authUtils';
import Button from '../Button/Button';
import { withErrorBoundary } from '../ErrorBoundary';

const styling = tv({
  slots: {
    bgGradient: 'z-0 bg-gradient-to-t from-white to-transparent absolute bottom-full left-0 w-full h-[200px]',
    componentWrapper: 'relative pb-[60px] pt-[30px]',
    icon: 'w-4 h-4 fill-current text-white',
    lockCtaContainer: 'relative z-1 flex bg-white shadow-[0_10px_20px_rgba(0,0,0,0.25),0_-10px_20px_rgba(0,0,0,0.1)]',
    lockCtaContent: 'flex p-8 w-full items-center justify-between',
    lockCtaIconContainer: 'bg-primary text-white p-4 h-auto flex items-center',
    lockCtaText: 'text-sm text-gray-600',
    lockCtaTextContainer: 'flex-1 pr-16',
    lockCtaTitle: 'text-lg font-semibold mb-4',
  },
});

const LockCta = () => {
  const {
    bgGradient,
    componentWrapper,
    icon,
    lockCtaContainer,
    lockCtaIconContainer,
    lockCtaContent,
    lockCtaTextContainer,
    lockCtaTitle,
    lockCtaText,
  } = styling();

  const intl = useIntl();
  const auth = useAuth();
  if (!auth) {
    return null;
  }
  const { state } = auth;

  return (
    <div className={componentWrapper()}>
      <div className={bgGradient()} />
      <div className={lockCtaContainer()}>
        <div className={lockCtaIconContainer()}>
          <LockIcon className={icon()} />
        </div>
        <div className={lockCtaContent()}>
          <div className={lockCtaTextContainer()}>
            <h3 className={lockCtaTitle()}>{intl.formatMessage({ id: 'lockcta.title' })}</h3>
            <p className={lockCtaText()}>{intl.formatMessage({ id: 'lockcta.description' })}</p>
          </div>
          {state.status !== 'loading' && state.isAuthenticated === false && (
            <Button variant="primary" onClick={() => handleLogin(intl.locale)}>
              {intl.formatMessage({ id: 'lockcta.buttonLabel' })}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(LockCta, { componentName: 'LockCta' });
