import { Cta, withErrorBoundary } from '@arvesta-websites/ui-babel';
import * as React from 'react';

import { NavItemType } from '../types';

import { StyledNavItem, StyledNavigation, StyledSelectedNavItem } from './Styled';

interface Props {
  items: Array<NavItemType>;
  currentHoverItem: NavItemType | null;
  currentPageUrl: string;
  activeMenuItem: NavItemType | null;
  onMouseEnter: (item: NavItemType) => void;
  variant?: string;
  isHomepage: boolean;
  pageId: string;
}

const showActiveParentItem = (
  navigation: NavItemType[],
  currentPageUrl: string,
  currentItem: NavItemType,
  pageId: string,
) => {
  const parentItem = navigation.find(item =>
    item.children?.some(childItem => {
      return childItem.to !== '' && currentPageUrl.includes(childItem.to);
    }),
  );

  return parentItem?.to === currentItem.to;
};

const hasChildren = (item: NavItemType) => {
  return (item.children && item.children.length > 0) || (item.childrenDivided && item.childrenDivided.length > 0);
};

const determineLink = (item: NavItemType) => {
  if (item.to !== '') {
    return item.to;
  }

  return item.externalUrl;
};

const PrimaryNavigation = ({
  items,
  activeMenuItem,
  currentPageUrl,
  onMouseEnter,
  variant,
  isHomepage,
  pageId,
}: Props) => {
  if (isHomepage) {
    return null;
  }

  return (
    <StyledNavigation variant={variant}>
      {items.map((item, idx) => {
        if (!hasChildren(item)) {
          return (
            <StyledNavItem
              // eslint-disable-next-line react/no-array-index-key
              key={`primary-${item.to}-${idx}`}
              onMouseLeave={() => onMouseEnter({} as NavItemType)}
              variant={variant}
              onFocus={() => onMouseEnter(item)}
            >
              <Cta
                to={determineLink(item)}
                onClick={e => {
                  onMouseEnter(item);
                }}
              >
                {item.label}
              </Cta>
            </StyledNavItem>
          );
        }

        if (activeMenuItem?.to === item.to || showActiveParentItem(items, currentPageUrl, item, pageId)) {
          return (
            <StyledSelectedNavItem
              // eslint-disable-next-line react/no-array-index-key
              key={`primary-active-${item.to}-${idx}`}
              onMouseLeave={() => onMouseEnter({} as NavItemType)}
              onFocus={() => onMouseEnter(item)}
              variant={variant}
            >
              <Cta
                to={determineLink(item)}
                onClick={e => {
                  onMouseEnter(item);
                }}
              >
                {item.label}
              </Cta>
            </StyledSelectedNavItem>
          );
        }

        return (
          <StyledNavItem
            // eslint-disable-next-line react/no-array-index-key
            key={`primary-${item.to}-${idx}`}
            onMouseLeave={() => onMouseEnter({} as NavItemType)}
            variant={variant}
            onFocus={() => onMouseEnter(item)}
          >
            <Cta
              to={determineLink(item)}
              onClick={e => {
                onMouseEnter(item);
              }}
            >
              {item.label}
            </Cta>
          </StyledNavItem>
        );
      })}
    </StyledNavigation>
  );
};

export default withErrorBoundary(PrimaryNavigation, { componentName: 'TopLevelHeader/PrimaryNavigation' });
