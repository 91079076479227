import { useAuth } from '@arvesta-websites/ui-babel';
import { Location } from '@reach/router';
import { PageProps, graphql } from 'gatsby';

import {
  ContentfulAdviceConnection,
  ContentfulAdvicesSection,
  ContentfulArticleDetailPage,
  ContentfulBasicPage,
  ContentfulBasicPageConnection,
  ContentfulCategoryConnection,
  ContentfulDealerV2Connection,
  ContentfulEventConnection,
  ContentfulNewsConnection,
  ContentfulProductConnection,
  ContentfulSettings,
  Site,
} from '../__generated__/graphql-types';
import Layout from '../components/layout';
import { getContentsSectionCollection, transformerMap } from '../transforms/PageItemsMap';
import { BasicPage as BasicPageComponent } from '../ui-lib/components';
import * as Utils from '../utils';

type BasicPageQueryType = {
  contentfulBasicPage: ContentfulBasicPage;
  contentfulSettings: ContentfulSettings;
  allContentfulEvent: ContentfulEventConnection;
  allContentfulNews: ContentfulNewsConnection;
  allContentfulDealerV2: ContentfulDealerV2Connection;
  allContentfulAdvice: ContentfulAdviceConnection;
  allContentfulAdvicesSection: ContentfulAdvicesSection;
  allcontentfulArticleDetailPage: ContentfulArticleDetailPage;
  allContentfulProduct: ContentfulProductConnection;
  allContentfulCategory: ContentfulCategoryConnection;
  translatedSlugs: ContentfulBasicPageConnection;
  site: Site;
};

type PageContextType = {
  id: number;
  node_locale: string;
  isHomepage?: boolean;
  pageRelatedMenuItems: string[];
};

const BasicPage = ({ data, pageContext, location }: PageProps<BasicPageQueryType, PageContextType>) => {
  const { contentsSection, image, updatedAt, seoMetadata, slug, newsletterSubscription, title, hero, gated } =
    data.contentfulBasicPage;

  const gatedContentAvailable = process.env.GATED_CONTENT === 'true';
  const isLockedForNonAuthenticatedUsers = gatedContentAvailable && gated;
  const auth = useAuth();
  if (!auth) {
    return null;
  }
  const { state } = auth;

  const modules = Utils.parseModules(contentsSection || [], data.contentfulSettings, location);

  const pageProps = {
    contentfulSettings: data.contentfulSettings,
    id: pageContext.id,
    isHomepage: pageContext.isHomepage ?? false,
    ogPageType: 'website',
    pageImage: image || undefined,
    pageLocale: pageContext.node_locale,
    pageSettings: seoMetadata,
    pageSlug: slug || '',
    pageTitle: title,
    pageType: 'basicpage',
    siteUrl: data.site.siteMetadata?.siteUrl || '',
    updatedAt,
  };
  const subscribeForm = {
    showFooterSubscribeForm: data.contentfulSettings.footerShowSubscribeForm ?? false,
    showFooterSubscribeFormCountryField:
      data.contentfulSettings.footer.newsletterForm?.showSubscribeFormCountryField || false,
    subscribeFormHeading: data.contentfulSettings.footer.newsletterForm?.formHeading,
  };

  const extractHeroReference = () => {
    if (hero.__typename === 'ContentfulHeroBanner') {
      return transformerMap['hero-banner'](hero, pageContext.node_locale);
    }

    if (hero.__typename === 'ContentfulHeroDuoBanner') {
      return transformerMap['hero-duo-banner'](hero, pageContext.node_locale);
    }

    if (hero.__typename === 'ContentfulListingManual') {
      return transformerMap['listing-manual'](hero, pageContext.node_locale);
    }

    return null;
  };

  const newsletterFromBasicPage = transformerMap['news-letter-subscription'](newsletterSubscription, '');
  const newsletterForDefaultFooter =
    newsletterFromBasicPage ??
    (data.contentfulSettings.footerShowSubscribeForm &&
      transformerMap['news-letter-subscription'](data.contentfulSettings.footer?.newsletterForm, ''));

  return (
    <Location>
      {({ location }) => (
        <Layout
          heroReference={hero && extractHeroReference()}
          newsletter={newsletterForDefaultFooter}
          pageProps={pageProps}
          subscribeForm={subscribeForm}
          translatedSlugs={data.translatedSlugs?.nodes ?? []}
          pageRelatedMenuItems={pageContext.pageRelatedMenuItems}
        >
          <BasicPageComponent
            contentsSectionCollection={getContentsSectionCollection(modules, data, location, pageContext, title)}
            title={seoMetadata?.title || undefined}
            isAuthenticated={state.isAuthenticated}
            isLockedForNonAuthenticatedUsers={isLockedForNonAuthenticatedUsers}
          />
        </Layout>
      )}
    </Location>
  );
};

export default BasicPage;

export const pageQuery = graphql`
  query ($id: String!, $node_locale: String!, $brand_tag: String!, $contentful_id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    translatedSlugs: allContentfulBasicPage(filter: { contentful_id: { eq: $contentful_id } }) {
      nodes {
        contentful_id
        slug
        node_locale
      }
    }
    contentfulBasicPage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      title
      slug
      updatedAt(formatString: "YYYYMMDDHHmmss")
      gated
      seoMetadata {
        id
        description
        keywords
        no_index
        no_follow
        canonicalUrlpath
        title
      }
      image {
        title
        alt: altText
        imageWithFocalPoint(layout: FULL_WIDTH, quality: 85, width: 1440, height: 600, resizingBehavior: FILL)
        socialImageWithFocalPoint: imageWithFocalPoint(
          layout: FIXED
          quality: 80
          width: 1200
          height: 630
          resizingBehavior: CROP
        )
        asset {
          # fixed(width: 1440, height: 600, resizingBehavior: FILL, quality: 85) {
          #   ...QueryImageFixed
          # }
          gatsbyImageData(layout: FULL_WIDTH, quality: 85, width: 1440, height: 600, resizingBehavior: FILL)
        }
        socialImage: asset {
          # fixed(width: 1200, height: 1200, resizingBehavior: CROP, quality: 100) {
          #   ...QueryImageFixed
          # }
          gatsbyImageData(layout: FIXED, quality: 100, width: 1200, height: 630, resizingBehavior: CROP)
        }
      }
      hero {
        ... on ContentfulListingManual {
          ...QueryListingManual
        }
        ... on ContentfulHeroBanner {
          ...QueryHeroBanner
        }
        ... on ContentfulHeroDuoBanner {
          ...QueryHeroDuoBanner
        }
      }
      contentsSection {
        __typename
        ... on ContentfulHeroSection {
          ...QueryHeroSection
        }
        ... on ContentfulInfoSection {
          ...QueryInfoSection
        }
        ... on ContentfulUpcomingEvents {
          ...QueryUpcomingEvents
        }
        ... on ContentfulLatestNewsSection {
          ...QueryLatestNewsSection
        }
        ... on ContentfulProductsServicesSection {
          ...QueryProductsServices
        }
        ... on ContentfulCtaBanner {
          ...QueryCtaBanner
        }
        ... on ContentfulContactsSection {
          ...QueryContactsSection
        }
        ... on ContentfulProductCategoriesSection {
          ...QueryProductCategoriesSection
        }
        ... on ContentfulRichTextSection {
          ...QueryRichTextSection
        }
        ... on ContentfulContactsSection {
          ...QueryContactsSection
        }
        ... on ContentfulFindADealerSection {
          ...QueryFindADealerSection
        }
        ... on ContentfulAdviceListingSection {
          ...QueryAdviceListingSection
        }
        ... on ContentfulProductsListingTableSection {
          ...QueryProductListingTable
        }
        ... on ContentfulNewsListingSection {
          ...QueryNewsListing
        }
        ... on ContentfulEventsListingSection {
          ...QueryEventsListing
        }
        ... on ContentfulPastEventsSection {
          ...QueryPastEventsSection
        }
        ... on ContentfulProductListingSection {
          ...QueryProductListingSection
        }
        ... on ContentfulCategoryListingSection {
          ...QueryCategoryListingSection
        }
        # TODO check if this may be removed
        ... on ContentfulAttachment {
          ...QueryDocument
        }
        ... on ContentfulImageAndText {
          ...QueryImageAndText
        }
        ... on ContentfulNewsletterSubscription {
          ...QueryNewsletterSubscription
        }
        ... on ContentfulSubcategoryListingSection {
          ...QuerySubcategoryListingSection
        }
        ... on ContentfulShortBanner {
          ...QueryShortBanner
        }
        ... on ContentfulMediaBlock {
          ...QueryMediaBlock
        }
        ... on ContentfulTable {
          ...QueryTable
        }
        ... on ContentfulCustomBlocksSection {
          ...QueryCustomBlocksSection
        }
        ... on ContentfulAdvicesSection {
          ...QueryAdvicesSection
        }
        ... on ContentfulListingManual {
          ...QueryListingManual
        }
        ... on ContentfulCtaGroup {
          ...QueryCtaGroup
        }
        ... on ContentfulFeaturesSection {
          ...QueryFeaturesSection
        }
        ... on ContentfulMosaicSection {
          ...QueryMosaicSection
        }
        ... on ContentfulHeroBanner {
          ...QueryHeroBanner
        }
        ... on ContentfulColumns {
          ...QueryColumns
        }
        ... on ContentfulInfoBlock {
          ...QueryInfoBlock
        }
        ... on ContentfulVideo {
          ...QueryVideo
        }
        ... on ContentfulArticleListingSection {
          ...QueryArticleListingSection
        }
        ... on ContentfulImage {
          ...QueryImage
        }
        ... on ContentfulEmbeddedContent {
          ...QueryEmbeddedContent
        }
        ... on ContentfulProductCollectionLister {
          ...QueryProductCollectionLister
        }
      }
      newsletterSubscription {
        ...QueryForm
      }
    }

    contentfulSettings(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      ...QuerySiteSettings
    }

    allContentfulEvent(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
    ) {
      edges {
        node {
          id
          # TODO discuss how to migrate to startDate and endDate
          displayDate
          startDate
          slug
          shortDescription {
            text: shortDescription
          }
          location {
            __typename
            ... on ContentfulLocation {
              title
              address {
                address
              }
            }
            ... on ContentfulRichTextSection {
              richText {
                raw
              }
            }
          }
          hero {
            title
            image {
              title
              alt: altText
              imageWithFocalPoint(layout: FIXED, quality: 80, width: 510, height: 340, resizingBehavior: FILL)
              asset {
                gatsbyImageData(layout: FIXED, width: 510, height: 340, quality: 85, resizingBehavior: FILL)
              }
            }
          }
        }
      }
    }

    allContentfulNews(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
      sort: { date: DESC }
    ) {
      edges {
        node {
          updatedAt
          title
          slug
          id
          date
          shortDescription {
            text: shortDescription
          }
          image {
            title
            alt: altText
            imageWithFocalPoint(layout: CONSTRAINED, quality: 80, resizingBehavior: FILL)
            asset {
              gatsbyImageData(layout: CONSTRAINED, quality: 85, resizingBehavior: FILL)
            }
          }
          gated
        }
      }
    }

    allContentfulDealerV2(
      sort: { name: ASC }
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
    ) {
      edges {
        node {
          id
          name
          email
          openingHours {
            raw
          }
          services
          telephone
          teamMembers {
            jobTitle
            name
            telephone
          }
          owner {
            slug
            id
          }
          address {
            postalCode
            cityTown
            countryCode
            location {
              lat
              lon
            }
            number
            street
          }
        }
      }
    }

    allContentfulAdvice(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
    ) {
      edges {
        node {
          title
          shortDescription {
            text: shortDescription
          }
          slug
          image {
            imageWithFocalPoint(layout: FIXED, height: 670, width: 670, quality: 80)
            asset {
              gatsbyImageData(layout: FIXED, height: 670, width: 670, quality: 80)
            }
          }
        }
      }
    }

    allContentfulAdvicesSection(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
    ) {
      edges {
        node {
          __typename
          shortDescription {
            text: shortDescription
          }
          title
          cta {
            internalEntry {
              ...FragmentNavigationLink
            }
            title
            externalUrl
          }
          advices {
            __typename
            ... on ContentfulAdvice {
              id
              slug
              title
              image {
                imageWithFocalPoint(layout: FIXED, height: 670, width: 670, quality: 80)
                asset {
                  gatsbyImageData(layout: FIXED, height: 670, width: 670, quality: 80)
                }
              }
              shortDescription {
                text: shortDescription
              }
            }
          }
        }
      }
    }

    allContentfulProduct(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
    ) {
      edges {
        node {
          id
          title
          price
          gated
          category {
            slug
          }
          subcategory {
            slug
          }
          enquiryCta {
            externalUrl
            internalEntry {
              ...FragmentNavigationLink
            }
            title
          }
          shortDescription {
            text: shortDescription
          }
          image {
            title
            alt: altText
            imageWithFocalPoint(layout: FULL_WIDTH, quality: 80)
            # asset {
            #  gatsbyImageData(layout: FULL_WIDTH, quality: 85)
            # }
          }
          document {
            title
            asset {
              description
              file {
                contentType
                url
                details {
                  size
                }
                fileName
              }
            }
          }
          slug
          cardCTAText
          linkToDetailPage
          linkToCustomPage {
            externalUrl
            internalEntry {
              ...FragmentNavigationLink
            }
            title
          }
          additionalDocuments {
            id
            title
            asset {
              description
              file {
                contentType
                url
                details {
                  size
                }
                fileName
              }
            }
          }
          contentsSection {
            raw
            references {
              __typename
              ... on ContentfulAdvice {
                contentful_id
                slug
              }

              ... on ContentfulBasicPage {
                contentful_id
                slug
              }

              ... on ContentfulEvent {
                contentful_id
                slug
              }

              ... on ContentfulNews {
                contentful_id
                slug
              }

              ... on ContentfulProduct {
                contentful_id
                slug
              }

              ... on ContentfulAsset {
                contentful_id
                file {
                  url
                }
              }

              ... on ContentfulAttachment {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                title
                asset {
                  file {
                    url
                    details {
                      size
                    }
                    contentType
                  }
                }
              }

              ... on ContentfulBlockquote {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                quote {
                  text: quote
                }
                author
                authorInfo
                image {
                  title
                  alt: altText
                  imageWithFocalPoint(quality: 80)
                  asset {
                    file {
                      url
                    }
                    gatsbyImageData
                  }
                }
              }

              ... on ContentfulImage {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                title
                alt: altText
                imageWithFocalPoint(quality: 80)
                asset {
                  file {
                    url
                    details {
                      image {
                        width
                        height
                      }
                    }
                  }
                  gatsbyImageData
                }
              }

              ... on ContentfulMediaBlock {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                title
                shortDescription {
                  text: shortDescription
                }
                caption {
                  text: caption
                }
                orientation
                media {
                  ... on ContentfulImage {
                    __typename
                    contentful_id
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    title
                    alt: altText
                    portraitImageWithFocalPoint: imageWithFocalPoint(
                      layout: CONSTRAINED
                      aspectRatio: 0.74
                      quality: 80
                    )
                    squareImageWithFocalPoint: imageWithFocalPoint(layout: CONSTRAINED, aspectRatio: 1, quality: 80)
                    landscapeImageWithFocalPoint: imageWithFocalPoint(
                      layout: CONSTRAINED
                      aspectRatio: 1.77
                      quality: 80
                    )
                    asset {
                      file {
                        url
                      }
                      portraitImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.74)
                      squareImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
                      landscapeImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.77)
                    }
                  }

                  ... on ContentfulVideo {
                    contentful_id
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    videoUrl
                  }
                }
              }

              ... on ContentfulShortBanner {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                title
                image {
                  title
                  alt: altText
                  imageWithFocalPoint(quality: 80)
                  asset {
                    file {
                      url
                      details {
                        image {
                          width
                          height
                        }
                      }
                    }
                    gatsbyImageData
                  }
                }
                cta {
                  title
                  internalEntry {
                    __typename
                    ... on ContentfulAdvice {
                      contentful_id
                      slug
                    }

                    ... on ContentfulBasicPage {
                      contentful_id
                      slug
                    }

                    ... on ContentfulEvent {
                      contentful_id
                      slug
                    }

                    ... on ContentfulNews {
                      contentful_id
                      slug
                    }

                    ... on ContentfulProduct {
                      contentful_id
                      slug
                    }
                  }
                  externalUrl
                }
              }

              ... on ContentfulTextSectionHighlight {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                richTextContents {
                  raw
                  references {
                    __typename
                    ... on ContentfulAdvice {
                      contentful_id
                      slug
                    }

                    ... on ContentfulBasicPage {
                      contentful_id
                      slug
                    }

                    ... on ContentfulEvent {
                      contentful_id
                      slug
                    }

                    ... on ContentfulNews {
                      contentful_id
                      slug
                    }

                    ... on ContentfulProduct {
                      contentful_id
                      slug
                    }

                    ... on ContentfulAsset {
                      contentful_id
                      file {
                        url
                      }
                    }
                  }
                }
              }

              ... on ContentfulVideo {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                videoUrl
              }

              ... on ContentfulRichTextSection {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                alignment
                richText {
                  raw
                  references {
                    __typename
                    ... on ContentfulAdvice {
                      contentful_id
                      slug
                    }

                    ... on ContentfulBasicPage {
                      contentful_id
                      slug
                    }

                    ... on ContentfulEvent {
                      contentful_id
                      slug
                    }

                    ... on ContentfulNews {
                      contentful_id
                      slug
                    }

                    ... on ContentfulProduct {
                      contentful_id
                      slug
                    }

                    ... on ContentfulAsset {
                      contentful_id
                      file {
                        url
                      }
                    }

                    ... on ContentfulAttachment {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      title
                      asset {
                        file {
                          url
                          details {
                            size
                          }
                          contentType
                        }
                      }
                    }

                    ... on ContentfulBlockquote {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      quote {
                        text: quote
                      }
                      author
                      authorInfo
                      image {
                        title
                        alt: altText
                        imageWithFocalPoint(quality: 80)
                        asset {
                          file {
                            url
                          }
                          gatsbyImageData
                        }
                      }
                    }

                    ... on ContentfulImage {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      title
                      alt: altText
                      imageWithFocalPoint(quality: 80)
                      asset {
                        file {
                          url
                          details {
                            image {
                              width
                              height
                            }
                          }
                        }
                        gatsbyImageData
                      }
                    }

                    ... on ContentfulMediaBlock {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      title
                      shortDescription {
                        text: shortDescription
                      }
                      caption {
                        text: caption
                      }
                      orientation
                      media {
                        ... on ContentfulImage {
                          __typename
                          contentful_id
                          sys {
                            contentType {
                              sys {
                                id
                              }
                            }
                          }
                          title
                          alt: altText
                          portraitImageWithFocalPoint: imageWithFocalPoint(
                            layout: CONSTRAINED
                            aspectRatio: 0.74
                            quality: 80
                          )
                          squareImageWithFocalPoint: imageWithFocalPoint(
                            layout: CONSTRAINED
                            aspectRatio: 1
                            quality: 80
                          )
                          landscapeImageWithFocalPoint: imageWithFocalPoint(
                            layout: CONSTRAINED
                            aspectRatio: 1.77
                            quality: 80
                          )
                          asset {
                            file {
                              url
                            }
                            portraitImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.74)
                            squareImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
                            landscapeImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.77)
                          }
                        }

                        ... on ContentfulVideo {
                          contentful_id
                          sys {
                            contentType {
                              sys {
                                id
                              }
                            }
                          }
                          videoUrl
                        }
                      }
                    }

                    ... on ContentfulShortBanner {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      title
                      image {
                        title
                        alt: altText
                        imageWithFocalPoint(quality: 80)
                        asset {
                          file {
                            url
                            details {
                              image {
                                width
                                height
                              }
                            }
                          }
                          gatsbyImageData
                        }
                      }
                      cta {
                        title
                        internalEntry {
                          __typename
                          ... on ContentfulAdvice {
                            contentful_id
                            slug
                          }

                          ... on ContentfulBasicPage {
                            contentful_id
                            slug
                          }

                          ... on ContentfulEvent {
                            contentful_id
                            slug
                          }

                          ... on ContentfulNews {
                            contentful_id
                            slug
                          }

                          ... on ContentfulProduct {
                            contentful_id
                            slug
                          }
                        }
                        externalUrl
                      }
                    }

                    ... on ContentfulTable {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      table {
                        tableData
                      }
                      fixFirstColumn
                    }

                    ... on ContentfulTextSectionHighlight {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      richTextContents {
                        raw
                        references {
                          __typename
                          ... on ContentfulAdvice {
                            contentful_id
                            slug
                          }

                          ... on ContentfulBasicPage {
                            contentful_id
                            slug
                          }

                          ... on ContentfulEvent {
                            contentful_id
                            slug
                          }

                          ... on ContentfulNews {
                            contentful_id
                            slug
                          }

                          ... on ContentfulProduct {
                            contentful_id
                            slug
                          }

                          ... on ContentfulAsset {
                            contentful_id
                            file {
                              url
                            }
                          }
                        }
                      }
                    }

                    ... on ContentfulVideo {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      videoUrl
                    }
                  }
                }
              }
            }
          }
          isBrand
          logo {
            title
            alt: altText
            imageWithFocalPoint(layout: FIXED, width: 150, quality: 80)
            asset {
              gatsbyImageData(layout: FIXED, width: 150, quality: 80)
            }
          }
          categories
          filters
          address {
            text: address
          }
          productsAndServices {
            text: productsAndServices
          }
        }
      }
    }

    # TODO Check if can be removed
    allContentfulCategory(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
    ) {
      edges {
        node {
          slug
          id
          title
          gated
          image {
            title
            alt: altText
            imageWithFocalPoint(layout: CONSTRAINED, quality: 80)
            asset {
              gatsbyImageData(layout: CONSTRAINED, quality: 85)
            }
          }
          shortDescription {
            text: shortDescription
          }
          cta {
            externalUrl
            internalEntry {
              ...FragmentNavigationLink
            }
            title
          }
        }
      }
    }

    allContentfulArticleDetailPage(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
      sort: { date: DESC }
    ) {
      nodes {
        contentful_id
        slug
        title
        shortDescription {
          text: shortDescription
        }
        imageCard {
          image {
            title
            alt: altText
            imageWithFocalPoint(layout: CONSTRAINED, quality: 80)
          }
        }
        gated
        categories {
          ... on ContentfulCategory {
            __typename
            contentful_id
            title
            slug
            id
            article_detail_page {
              contentful_id
              id
              title
              slug
              shortDescription {
                text: shortDescription
              }
              imageCard {
                title
                image {
                  title
                  alt: altText
                  imageWithFocalPoint(layout: CONSTRAINED, quality: 80)
                  asset {
                    gatsbyImageData(layout: CONSTRAINED, quality: 85)
                  }
                }
              }
              gated
            }
            children: contentfulchildren {
              __typename
              id
              contentful_id
              title
              slug
              article_detail_page {
                contentful_id
                id
                title
                slug
                shortDescription {
                  text: shortDescription
                }
                imageCard {
                  title
                  image {
                    title
                    alt: altText
                    imageWithFocalPoint(layout: CONSTRAINED, quality: 80)
                    asset {
                      gatsbyImageData(layout: CONSTRAINED, quality: 85)
                    }
                  }
                }
                gated
              }
            }
          }
          ... on ContentfulSubcategory {
            __typename
            contentful_id
            title
            slug
            id
            category {
              id
              __typename
              contentful_id
              title
              slug
              article_detail_page {
                contentful_id
                id
                title
                slug
                shortDescription {
                  text: shortDescription
                }
                imageCard {
                  title
                  image {
                    title
                    alt: altText
                    imageWithFocalPoint(layout: CONSTRAINED, quality: 80)
                    asset {
                      gatsbyImageData(layout: CONSTRAINED, quality: 85)
                    }
                  }
                }
                gated
              }
            }
          }
        }
      }
    }
  }
`;
