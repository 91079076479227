import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import {
  ContentfulAdvice,
  ContentfulArticleDetailPage,
  ContentfulBasicPage,
  ContentfulCallToAction,
  ContentfulCategoryContentfulSubcategoryUnion,
  ContentfulEvent,
  ContentfulHeroBanner,
  ContentfulImageCard,
  ContentfulImageCardLink,
  ContentfulImageCardShort,
  ContentfulListingManual,
  ContentfulNews,
  ContentfulProduct,
  ContentfulRecipe,
} from '../../__generated__/graphql-types';
import extractCta from '../Cta';

const CAROUSEL_TYPES = {
  HERO: 'Hero',
  IMAGE_CARD: 'Image Card',
  LOGO_CARD: 'Logo Card',
} as const;

type ImageCard = ContentfulImageCard & { __typename: 'ContentfulImageCard' };
type HeroBanner = ContentfulHeroBanner & { __typename: 'ContentfulHeroBanner' };
type ImageCardLink = ContentfulImageCardLink & { __typename: 'ContentfulImageCardLink' };
type ImageCardShort = ContentfulImageCardShort & { __typename: 'ContentfulImageCardShort' };

type AdvicePage = ContentfulAdvice & { __typename: 'ContentfulAdvice' };
type ArticleDetailPage = ContentfulArticleDetailPage & { __typename: 'ContentfulArticleDetailPage' };
type BasicPage = ContentfulBasicPage & { __typename: 'ContentfulBasicPage' };
type Event = ContentfulEvent & { __typename: 'ContentfulEvent' };
type News = ContentfulNews & { __typename: 'ContentfulNews' };
type Product = ContentfulProduct & { __typename: 'ContentfulProduct' };
type Recipe = ContentfulRecipe & { __typename: 'ContentfulRecipe' };
type CallToAction = ContentfulCallToAction & { __typename: 'ContentfulCallToAction' };

type ContentEntries = (ImageCard | HeroBanner | ImageCardLink | ImageCardShort)[];
type CardLinks = AdvicePage | ArticleDetailPage | BasicPage | Event | News | Product | Recipe | CallToAction;

type ListingManual = Omit<ContentfulListingManual, 'type' | 'contentEntries'> & {
  carouselType: 'Image Card' | 'Hero' | 'Logo Card';
  contentEntries: ContentEntries;
};

const isImageCardLink = (entries: ContentEntries): entries is ImageCardLink[] => {
  return entries?.map(entry => entry.__typename === 'ContentfulImageCardLink').includes(true);
};
const isHeroBanner = (entries: ContentEntries): entries is HeroBanner[] => {
  return entries?.map(entry => entry.__typename === 'ContentfulHeroBanner').includes(true);
};
const isImageCard = (entries: ContentEntries): entries is ImageCard[] => {
  return entries?.map(entry => entry.__typename === 'ContentfulImageCard').includes(true);
};
const isImageCardShort = (entries: ContentEntries): entries is ImageCardShort[] => {
  return entries?.map(entry => entry.__typename === 'ContentfulImageCardShort').includes(true);
};

const isCallToAction = (entry: CardLinks): entry is CallToAction => {
  return entry.__typename === 'ContentfulCallToAction';
};

const getArticlesFromCategory = (
  category: ContentfulCategoryContentfulSubcategoryUnion,
  uniqueArticlesIds: string[],
) => {
  return (category?.article_detail_page ?? [])?.reduce((acc, article) => {
    if (uniqueArticlesIds.includes(article.id)) {
      return acc;
    }

    uniqueArticlesIds.push(article.id);

    return [
      ...acc,
      {
        ctaLabel: 'Lees meer',
        date: article.date,
        description: article?.imageCard?.shortDescription?.shortDescription,
        gated: article?.gated,
        image: article?.imageCard?.image,
        key: article?.id,
        link: article?.slug,
        title: article?.imageCard?.title,
      },
    ];
  }, []);
};

const getImageCardEntries = (entries: (ContentfulImageCard | ContentfulImageCardShort)[], locale: string) => {
  if (isImageCardShort(entries as ContentEntries)) {
    return transformImageCardShortEntries(entries, locale);
  }

  if (isImageCard(entries as ContentEntries)) {
    return transformNewsCardEntries(entries);
  }

  return [];
};

const transformImageCardShortEntries = (contentEntries: ContentfulImageCardShort[], locale: string) => {
  return (
    contentEntries?.map(entry => {
      return {
        ctaLabel: 'Lees meer',
        description: entry.shortDescription?.shortDescription,
        image: entry.image,
        key: entry.id,
        link: entry?.article_detail_page ? `/${locale}/${entry.article_detail_page[0]?.slug}` : '/',
        title: entry.title,
      };
    }) ?? []
  );
};

const transformCarouselEntries = (entry: ListingManual, locale: string) => {
  const uniqueArticlesIds: string[] = [];
  const allArticles: any[] = [];

  if (entry.categories?.length) {
    entry.categories?.forEach(category => {
      const categoryEntries = getArticlesFromCategory(category, uniqueArticlesIds);
      allArticles.push(...categoryEntries);

      if (category?.contentfulchildren?.length) {
        category?.contentfulchildren?.forEach(subCategory => {
          const subCategoryEntries = getArticlesFromCategory(subCategory, uniqueArticlesIds);
          allArticles.push(...subCategoryEntries);
        });
      }
    });
  }

  let manualEntries: any[] = [];

  if (entry.contentEntries) {
    switch (entry.carouselType) {
      case CAROUSEL_TYPES.HERO:
        manualEntries = isHeroBanner(entry.contentEntries) ? transformHeroBannerEntries(entry.contentEntries) : [];
        break;
      case CAROUSEL_TYPES.IMAGE_CARD:
        manualEntries = getImageCardEntries(entry.contentEntries, locale) ?? [];
        break;
      case CAROUSEL_TYPES.LOGO_CARD:
        manualEntries = isImageCardLink(entry.contentEntries) ? transformLogoCardEntries(entry.contentEntries) : [];
        break;
    }
  }

  const combinedEntries = [...allArticles, ...manualEntries];

  if (combinedEntries.some(entry => entry.date)) {
    return combinedEntries.sort((a, b) => {
      const dateA = new Date(a.date || 0);
      const dateB = new Date(b.date || 0);
      return dateB.getTime() - dateA.getTime();
    });
  }

  return combinedEntries;
};

const generateNewsCardLink = (entry: ContentfulImageCard) => {
  if (isCallToAction(entry.cardLink as CardLinks)) {
    const cardLink = entry.cardLink as CallToAction;
    const internalEntry = cardLink?.internalEntry as Exclude<CardLinks, CallToAction>;

    return cardLink?.externalUrl ?? `/${internalEntry?.slug}`;
  }

  const cardLink = entry.cardLink as Exclude<CardLinks, CallToAction>;

  return `/${cardLink?.slug}`;
};

const transformNewsCardEntries = (contentEntries: ContentfulImageCard[]) => {
  return (
    contentEntries?.map(entry => {
      return {
        ctaLabel: entry.callToActionLabel,
        description: entry.shortDescription?.shortDescription,
        gated: entry?.gated,
        image: entry.image,

        key: entry.id,
        link: generateNewsCardLink(entry),
        title: entry.title,
      };
    }) ?? []
  );
};

const transformHeroBannerEntries = (contentEntries: ContentfulHeroBanner[]) => {
  return (
    contentEntries?.map(entry => {
      return {
        alignText: entry.alignText,
        cta: extractCta(entry),
        ctaStyle: entry.callToActionStyle,
        description: entry?.description?.raw ? documentToPlainTextString(JSON.parse(entry.description.raw)) : '',
        height: entry.height,
        image: entry.image,
        key: entry.id,
        overlayDesktop: entry?.overlayDesktop?.file?.url,
        overlayMobile: entry?.overlayMobile?.file?.url,
        secondCta: extractCta({ cta: entry.callToActionSecond }),
        secondCtaStyle: entry.callToActionSecondStyle,
        title: entry.title,
      };
    }) ?? []
  );
};

const transformLogoCardEntries = (contentEntries: ContentfulImageCardLink[]) => {
  return (
    contentEntries?.map(entry => {
      return {
        cta: extractCta({ cta: entry.cardLink }),
        image: entry.image,
        key: entry.id,
        title: entry.title,
      };
    }) ?? []
  );
};

const transformListingManual = (item: ListingManual, locale: string) => {
  return {
    __typename: 'ListingManual',
    carouselType: item.carouselType,
    contentEntries: transformCarouselEntries(item, locale),
    key: item.id,
    title: item.title,
    titleTag: item.titleTag,
  };
};

export default transformListingManual;
