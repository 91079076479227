export default {
  'brandListing.all': 'Todos',
  'brandListing.byCategories': 'Por categorías',
  'brandListing.clear': 'Borrar',
  'brandListing.productsAndServices': 'Productos y servicios',
  'brandListing.seeProductsFor': 'Ver productos de',
  'brandListing.similarBrands': 'Marcas con productos y servicios similares',
  'brandListing.visitWebsite': 'Ir al sitio web',
  'cards.advice.link': 'Leer el artículo',
  'cards.category.link': 'Leer más',
  'cards.event.link': 'Ver evento',
  'cards.news.link': 'Seguir leyendo',
  'cards.product.link': 'Ver más',
  'footer.copyright': '© {year} Arvesta. Todos los derechos reservados.',
  'footer.newsletter.error': 'Se ha producido un error con la información que ha introducido. Inténtelo de nuevo.',
  'footer.newsletter.heading':
    '¿Le gustaría recibir noticias y consejos sobre lo que le interesa? ¡Se los enviaremos encantadamente!',
  'footer.newsletter.placeholder': 'Escriba su dirección de correo electrónico',
  'footer.newsletter.subscribe': 'Apuntarme',
  'footer.newsletter.success': 'Ya está suscrito. ¡Gracias! Pronto tendrá noticias nuestras.',
  'footer.subscribe.enteremail': 'Escriba su dirección de correo electrónico',
  'footer.subscribe.failure': 'Se ha producido un error con la información que ha introducido. Inténtelo de nuevo.',
  'footer.subscribe.invalid_email': 'Correo electrónico no válido',
  'footer.subscribe.subscribe': 'Apuntarme',
  'footer.subscribe.subscribing': 'Registro en curso',
  'footer.subscribe.success': 'Ya está suscrito. ¡Gracias! Pronto tendrá noticias nuestras.',
  'forms.contact.description': 'Rellene el siguiente formulario y nuestro equipo se pondrá en contacto con usted.',
  'forms.contact.email.label': 'Dirección de correo electrónico',
  'forms.contact.email.placeholder': 'Escriba su dirección de correo electrónico',
  'forms.contact.errors.email': 'Correo electrónico no válido',
  'forms.contact.errors.required': 'Campo obligatorio',
  'forms.contact.errors.telephone': `Por favor, ingrese sólo números, espacios y el símbolo "+". El primer carácter debe ser un "+" o un número.`,
  'forms.contact.failure': 'Se ha producido un error con la información que ha introducido. Inténtelo de nuevo.',
  'forms.contact.fullname.label': 'Nombre completo',
  'forms.contact.fullname.placeholder': 'Escriba su nombre completo.',
  'forms.contact.message.label': 'Mensaje',
  'forms.contact.message.placeholder': 'Escriba su mensaje',
  'forms.contact.newsletter.title':
    '¿Quiere recibir correos electrónicos con noticias, actualizaciones de productos e información sobre eventos? Podrá apuntarse más adelante.',
  'forms.contact.other': 'Otro',
  'forms.contact.submit': 'Enviar',
  'forms.contact.success': 'Gracias, ya estamos estudiando su mensaje.',
  'forms.contact.telephone.label': 'Número de teléfono',
  'forms.contact.telephone.placeholder': 'Ingrese su número de teléfono',
  'forms.contact.title': 'Envíenos un mensaje',
  'forms.profile.account.delete.account': 'Eliminar cuenta',
  'forms.profile.account.delete.cancel': 'Cancelar',
  'forms.profile.account.delete.confirm': 'Confirmar',
  'forms.profile.account.delete.confirmation': '¿Está seguro de que desea eliminar su cuenta?',
  'forms.profile.account.delete.success':
    'Lástima que eligieras borrar tu cuenta en este sitio web. ¿Quizás nos volvamos a ver?',
  'forms.profile.account.delete.success.title': 'Cuenta eliminada',
  'forms.profile.change.password': 'Cambiar contraseña',
  'forms.profile.preferneces.toast.success': 'Sus preferencias se han guardado correctamente',
  'forms.profile.whatsapp': 'Número de teléfono para notificaciones de WhatsApp',
  'globals.download': 'Descargar',
  'globals.featuredProducts': 'Productos destacados',
  'globals.no': 'No',
  'globals.yes': 'Sí',
  'header.search.placeholder': 'Buscararch',
  'header.selectlanguage': 'Seleccionar idioma',
  'legacy.cookie.description': 'Si continúa navegando por el sitio, usted acepta el uso que hacemos de',
  'legacy.footer.headoffice': 'Oficina central',
  'legacy.footer.headquarters': 'Sede',
  'legacy.header.about': 'sobre',
  'legacy.header.contact': 'contacto',
  'legacy.header.jobs': 'vacantes',
  'legacy.header.news': 'noticias y prensa',
  'legacy.header.products': 'productos y servicios',
  'lockcta.buttonLabel': 'Iniciar sesión',
  'lockcta.description':
    'Inicia sesión o crea una cuenta gratuita en Arvesta para descubrir todos nuestros artículos interesantes. ¡De esta manera siempre estarás un paso por delante!',
  'lockcta.title': '¿Leer más?',
  'logout.label': 'Cerrar sesión',
  'newsletter.email.error': 'Por favor, introduce una dirección de correo electrónico válida.',
  'newsletter.email.success': 'Te suscribiste con éxito. En breve tendrá noticias nuestras.',
  'newsletter.input.placeholder': 'E-mailadress',
  'newsletter.terms.error.sectionOne': 'Por favor acepta las',
  'newsletter.terms.error.sectionTwo': 'condiciones generales de privacidad',
  'newsletter.terms.privacyPolicy': 'política de privacidad',
  'newsletter.terms.sectionOne': 'estoy de acuerdo con el',
  'newsletter.terms.sectionTwo': 'de Proxani',
  'newsletter.terms.subscribe': 'Suscribir',
  'newsletter.terms.subscribe.error':
    'Desafortunadamente, algo salió mal durante su registro. Vuelva a intentarlo más tarde.',
  'owner.storecard.contact': 'Contacto',
  'owner.storecard.openinghours': 'Horario de apertura',
  'owner.storecard.route': 'Direcciones',
  'owner.storecard.services': 'Servicios',
  'product.certifications.title': 'Certificaciones de productos',
  'profile.label': 'Perfil',
  'profile.slug': 'perfil',
  'sections.advice.loadmore': 'Cargar más consejos',
  'sections.category.loadmore': 'Cargar más categorías',
  'sections.contact.offices': 'Oficinas',
  'sections.contact.openinghours': 'Horario de apertura',
  'sections.contact.salesteam': 'Equipo',
  'sections.contact.viewonmap': 'Ver en el mapa',
  'sections.dealer.clear': 'BORRAR',
  'sections.dealer.invalidaddress': 'No se pudo encontrar la dirección',
  'sections.dealer.loadmore': 'Ver todos los distribuidores',
  'sections.dealer.maplink': 'Ver en el mapa',
  'sections.dealer.placeholder': 'introduzca su código postal y municipio',
  'sections.dealer.search': 'Buscar',
  'sections.dealer.total': '{total} Resultados de "{query}"',
  'sections.dealerOverview.clear_filters': 'Borrar todos los filtros',
  'sections.dealerOverview.details': 'Detalles',
  'sections.dealerOverview.no_results': 'No hay resultados',
  'sections.dealerOverview.remove_filter': 'Quitar filtro',
  'sections.dealerOverview.route': 'Direcciones',
  'sections.dealerOverview.services': 'Servicios',
  'sections.dealerOverview.show_all': 'Volver a la lista de todas las tiendas',
  'sections.events.loadmore': 'Cargar más eventos',
  'sections.events.loadmorepast': 'Cargar más eventos finalizados',
  'sections.news.loadmore': 'Cargar más noticias',
  'sections.product.loadmore': 'Cargar más',
  'sections.product.noproductsfoundfor': 'No se han encontrado productos para',
  'sections.product.table.brochure': 'FOLLETO',
  'sections.product.table.download': 'Descargar',
  'sections.product.table.number': 'N.º DE ART.',
  'sections.product.table.product': 'PRODUCTO',
  'sections.product.table.weight': 'PESO',
  'sections.search.events': 'Eventos',
  'sections.search.news': 'Noticias',
  'sections.search.noncategorised': 'No categorizados',
  'sections.search.noresults.title': 'Lo sentimos, no hemos encontrado ningún resultado para ',
  'sections.search.products': 'Productos',
  'sections.search.results.title': '{total} Resultados de "{query}"',
  'sections.search.seeallevents': 'Ver todos los eventos',
  'sections.search.seeallnews': 'Ver todas las noticias',
  'sections.search.seeallproducts': 'Ver todos los productos',
};
