const transformTable = (item: any) => {
  return {
    __typename: 'Table',
    fixFirstColumn: !!item.fixFirstColumn,
    headings: item.table?.tableData[0] || [],
    rows: item.table?.tableData.slice(1) || [],
  };
};

export default transformTable;
