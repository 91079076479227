import { Cart as CartIcon, ChevronLeft, CloseCustom as CloseIcon } from '@arvesta-websites/icons';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { BoxProps } from 'rebass';
import { tv } from 'tailwind-variants';

import type { LocaleType, NavItemType, SubnavItem } from '../../../../../types';
import Utils from '../../../../../utils';
import Cta from '../../../Cta';
import { withErrorBoundary } from '../../../ErrorBoundary';
import { LangSwitcherDefault } from '../../LangSwitcher';
import { SecondaryNavigationMobileItemDefault } from '../../SecondaryNavigationMobileItem';
import { SignInButton } from '../../SignInButton';

const FEEDEXPRESS = 'FeedExpress';

const gatedContentAvailable = process.env.GATED_CONTENT && process.env.GATED_CONTENT === 'true';

export interface SecondaryNavigationMobileProps extends BoxProps {
  items: Array<NavItemType>;
  ribonLinks?: Array<NavItemType>;
  onClose: () => void;
  defaultLocale: LocaleType;
  activeLocales: Array<string>;
}

const secondaryNavigationMobile = tv({
  slots: {
    bottomSection: 'bg-header-foreground h-full mobileHeaderBottom',
    cartIcon: 'mr-[5px] w-[17px] h-[17px]',
    chevronLeft: 'w-[18px] h-[18px]',
    divider: 'w-full h-px bg-header-line opacity-10',
    iconButton: 'cursor-pointer mt-[5px] text-white',
    mobileHeader:
      'flex items-center justify-between bg-primary h-[85px] text-white px-[15px] w-full z-10 mobileMenuButtonWrapper',
    mobileLanguageSelect: 'flex items-center justify-between px-5 pt-2.5',
    navItemUnderline:
      'list-none navItem flex items-center justify-between cursor-point w-full underline text-primary underline-offset-[5px]',
    navWrapper: 'h-full w-full pr-[55px] lg:pr-[100px]',
    ribbonItem: 'flex items-center cursor-pointer text-content ribonItem no-underline underline-offset-[3px]',
    ribbonMenu: 'flex flex-col m-0 gap-5 p-5 min-h-min',
    secondaryNavigationList: 'flex flex-col m-0 p-5 min-h-min gap-5 bg-header mobileHeaderTop',
    selectText: 'selectLanguage text-content',
  },
  variants: {
    hidden: {
      true: { iconButton: 'invisible' },
    },
  },
});

const transformToLayers = (tree: SubnavItem[], layers: SubnavItem[][] = [], level = 0): SubnavItem[][] => {
  if (!layers[level]) {
    layers[level] = [];
  }

  for (const item of tree) {
    layers[level].push(item);

    if (item.children.length) {
      transformToLayers(item.children, layers, level + 1);
    }

    if (item.childrenDivided.length) {
      const firstDividedItem = item.childrenDivided[0];
      if (firstDividedItem) {
        firstDividedItem.isDivided = true;
      }
      transformToLayers(item.childrenDivided, layers, level + 1);
    }
  }

  return layers;
};

const SecondaryNavigationMobile = ({
  items,
  ribonLinks,
  onClose,
  defaultLocale,
  activeLocales,
}: SecondaryNavigationMobileProps) => {
  const intl = useIntl();
  const [activeLevel, updateActiveLevel] = useState(1);
  const [openItems, updateOpenItems] = useState<Array<SubnavItem>>([]);
  const [currentParent, updateCurrentParent] = useState<SubnavItem | null>(null);
  const [previousParent, updatePreviousParent] = useState<SubnavItem | null>(null);
  const [layers] = useState<SubnavItem[][]>(transformToLayers(items));

  const handleNavigation = (item: SubnavItem) => {
    if (activeLevel >= 3) {
      if (openItems.find(openItem => openItem.id === item.id)) {
        const index = openItems.findIndex(openItem => openItem.id === item.id);
        const newOpenItems = [...openItems];
        newOpenItems.splice(index, 1);
        updateOpenItems(newOpenItems);
      } else {
        updateOpenItems([...openItems, item]);
      }
    } else if (item.children?.length) {
      updatePreviousParent(currentParent);
      updateCurrentParent(item);
      updateActiveLevel(activeLevel + 1);
    }
  };

  const {
    mobileHeader,
    cartIcon,
    chevronLeft,
    iconButton,
    navWrapper,
    secondaryNavigationList,
    navItemUnderline,
    bottomSection,
    ribbonMenu,
    ribbonItem,
    mobileLanguageSelect,
    selectText,
    divider,
  } = secondaryNavigationMobile();

  return (
    <Fragment>
      <div className={mobileHeader()}>
        <button
          onClick={() => {
            updateCurrentParent(previousParent);
            updateActiveLevel(activeLevel - 1);
            updateOpenItems([]);
          }}
          className={iconButton({ hidden: activeLevel === 1 })}
        >
          <ChevronLeft className={chevronLeft()} />
        </button>
        {activeLevel > 1 && currentParent?.label}
        <button onClick={() => onClose()} className={iconButton()}>
          <CloseIcon />
        </button>
      </div>

      <div className={navWrapper()}>
        <ul className={secondaryNavigationList()}>
          {layers[activeLevel - 1].map((item: SubnavItem) => {
            if (currentParent?.id !== item.parentId && activeLevel > 1) return null;
            return (
              <>
                {item.isDivided && <div className={divider()} />}
                {!!item.label && (
                  <SecondaryNavigationMobileItemDefault
                    locale={defaultLocale}
                    activeLevel={activeLevel}
                    item={item}
                    key={item.id}
                    onClickItem={() => handleNavigation(item)}
                    onClose={() => onClose()}
                    open={item.open}
                    openItems={openItems}
                  />
                )}
              </>
            );
          })}
          {activeLevel >= 3 && (
            <Cta to={currentParent?.to} className={navItemUnderline()}>
              {`${intl.formatMessage({ id: 'header.allAbout' })} ${currentParent?.label}`}
            </Cta>
          )}
        </ul>
        <div className={bottomSection()}>
          <ul className={ribbonMenu()}>
            {ribonLinks?.map((item: NavItemType) => {
              if (item.label) {
                return (
                  <a
                    key={item.to}
                    className={ribbonItem()}
                    target={item?.externalUrl ? '_blank' : '_self'}
                    href={item?.externalUrl || Utils.getIntlSlug(item?.to)}
                    rel="noreferrer"
                  >
                    {item.label === FEEDEXPRESS && <CartIcon className={cartIcon()} />}
                    {item.label}
                  </a>
                );
              }

              return null;
            })}
          </ul>
          {activeLocales.length > 1 && (
            <div className={mobileLanguageSelect()}>
              <span className={selectText()}>{intl.formatMessage({ id: 'header.selectlanguage' })}</span>
              <LangSwitcherDefault activeLocales={activeLocales} defaultLocale={defaultLocale.id} isMobileMenu />
            </div>
          )}
          {gatedContentAvailable && (
            <ul className={ribbonMenu()}>
              <li>
                <SignInButton isMobileMenu />
              </li>
            </ul>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default withErrorBoundary(SecondaryNavigationMobile, { componentName: 'SecondaryNavigationMobileDefault' });
