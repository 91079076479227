import { IntlContext } from '@arvesta-websites/i18n';
import {
  EventListingModule,
  type EventListingModuleProps,
  PastEventsModule,
  type PastEventsModuleProps,
  UpcomingEventsModule,
  type UpcomingEventsModuleProps,
} from '@arvesta-websites/ui-babel';
import React from 'react';
import Helmet from 'react-helmet';

//
import Utils from '../../utils';
import metatagBuilder from '../../utils/LegacyMetatagBuilder';
import LegacyCookieBanner from '../LegacyCookieBanner';
import LegacyFooter from '../LegacyFooter';
import LegacyHeader from '../LegacyHeader';
import EventPage, { type EventPageProps } from '../templates/EventPage/EventPage';

import { EnabledLocalesContext } from './PageWrapper';
import { StyledLegacyContentWrapper, StyledRootContainer } from './Styled';

type PageProps = {
  eventsListingModuleProps?: EventListingModuleProps;
  upcommingEventsProps?: UpcomingEventsModuleProps;
  pastEventsModuleProps?: PastEventsModuleProps;
  eventDetailProps?: EventPageProps;
  loadCookieBanner?: boolean;

  siteMetadata?: any;
  siteUrl?: string;
  siteTitle?: string;
  siteDescription?: string;
  slug?: string;
  siteImage?: any;
  eventdate?: string;
  eventLocation?: string;
};

const EventsMicrositeLayout = ({
  eventsListingModuleProps,
  upcommingEventsProps,
  pastEventsModuleProps,
  eventDetailProps,
  loadCookieBanner = true,

  siteMetadata,
  siteUrl,
  siteTitle,
  siteDescription,
  siteImage,
  slug,
  eventdate,
  eventLocation,
}: PageProps) => {
  const locale: any = React.useContext(IntlContext);
  const locales: any = React.useContext(EnabledLocalesContext);
  const activeLocales = Object.keys(locales);
  let metaTags;
  let metaLinks;

  if (siteMetadata) {
    const metaData = metatagBuilder(
      siteMetadata,
      siteUrl as string,
      siteTitle as string,
      siteDescription as string,
      siteImage,
      locale,
      slug as string,
    );
    metaTags = metaData.metaTags;
    metaLinks = metaData.metaLinks;
  }

  return (
    <StyledRootContainer>
      <Helmet>
        <meta charSet="utf-8" name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <link href="//fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700&display=swap" rel="stylesheet" />
        <link href="//fonts.googleapis.com/css?family=Encode+Sans:400,400i,500,600,700&display=swap" rel="stylesheet" />
        <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
        <link rel="icon" type="image/x-icon" href="images/legacy-favicon.ico" />
        {metaTags && metaTags.map((tag: any) => <meta key={tag.name || tag.property} {...tag} />)}
        {metaLinks && metaLinks.map((tag: any) => <link key={tag.rel} {...tag} />)}
        <script type="text/javascript">{Utils.getBrowserPolyfills()}</script>
        {eventdate && <meta name="date" data-type="string" content={eventdate} />}
        {eventLocation && <meta name="location" data-type="string" content={eventLocation} />}
      </Helmet>
      {loadCookieBanner && <LegacyCookieBanner />}
      <LegacyHeader currentLocale={locale} activeLocales={activeLocales} />
      <StyledLegacyContentWrapper>
        {eventsListingModuleProps && <EventListingModule {...eventsListingModuleProps} />}
        {upcommingEventsProps && <UpcomingEventsModule {...upcommingEventsProps} />}
        {pastEventsModuleProps && <PastEventsModule {...pastEventsModuleProps} />}
        {eventDetailProps && <EventPage {...eventDetailProps} />}
      </StyledLegacyContentWrapper>
      <LegacyFooter currentLocale={locale} activeLocales={activeLocales} />
    </StyledRootContainer>
  );
};

export default EventsMicrositeLayout;
