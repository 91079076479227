const documentTypeMap = {
  'application/msword': 'doc',
  'application/pdf': 'pdf',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.ms-powerpoint': 'ppt',
  'image/jpeg': 'jpg',
  'image/png': 'png',
};

const extractFileInforamtion = ({ title, asset }: any) => {
  if (asset) {
    const fileSize =
      asset.details && asset.details.size ? (asset.details.size / 2 ** 20).toString().substring(0, 4) : null;

    const contentType = asset.contentType as keyof typeof documentTypeMap;

    return {
      documentTitle: title,
      documentType: documentTypeMap[contentType] || null,
      downloadLink: asset.url,
      fileSize: fileSize ? `${fileSize}MB` : null,
      showDocumentInfo: true,
    };
  }

  return null;
};

export default extractFileInforamtion;
