import { TrashCan } from '@arvesta-websites/icons';
import React, { SetStateAction, Suspense, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';
import 'intl-tel-input/styles';

import type { FormDataTypeOptions } from '../../../../types';
import { useAuth } from '../../../shared/auth/authContext';
import { buildPasswordChangeLink, handleLogout } from '../../../shared/auth/authUtils';
import { getUserPreferences } from '../../../shared/preferences/preferencesHelper';
import Button from '../../Button';
import ConfirmPopin from '../../ConfirmPopin/ConfirmPopin';
import { withErrorBoundary } from '../../ErrorBoundary';
import Heading from '../../Heading';
import LoaderButton, { renderLoaderButton } from '../../LoaderButton/LoaderButton';
import RichText from '../../RichText';
import { StyledContainer, StyledForm } from '../ContactsFormDefault/Styled';
import InputField from '../InputField';
import LightSwitch from '../LightSwitch';

export type FormDataType = {
  email: { error: string; name: FormDataTypeOptions; value: string };
  givenName: { error: string; name: FormDataTypeOptions; value: string };
  name: { error: string; name: FormDataTypeOptions; value: string };
  telephone: { error: string; name: FormDataTypeOptions; value: string };
};

const defaultFormDataType: FormDataType = {
  email: { error: '', name: 'email', value: '' },
  givenName: { error: '', name: 'givenName' as FormDataTypeOptions, value: '' },
  name: { error: '', name: 'name', value: '' },
  telephone: { error: '', name: 'telephone', value: '' },
};

const Loader = () => (
  <div id="loader-container-prefs">
    <LoaderButton buttonId="loader-container-prefs" />
  </div>
);

const styling = tv({
  slots: {
    bottomSpacing: 'mb-[32px]',
    formRow: 'mt-4 flex flex-col gap-4',
    linkWrapper: 'flex gap-2 items-center text-primary font-bold underline',
    phoneInput: 'border border-dark h-12 text-base w-full',
    requiredLabel: 'after:content-["*"] after:ml-0.5 after:text-red-500',
    topSpacing: 'mt-[42px]',
    trashIcon: 'w-4 h-4 fill-current text-primary',
    url: 'text-footer-newsletter underline cursor-pointer whitespace-nowrap',
  },
});

const groupChannels = (channels: any[]) => {
  channels.sort((a, b) =>
    a.ChannelType.TypeDescription === 'Child Channel' && b.ChannelType.TypeDescription !== 'Child Channel'
      ? 1
      : a.ChannelType.TypeDescription !== 'Child Channel' && b.ChannelType.TypeDescription === 'Child Channel'
        ? -1
        : 0,
  );

  return channels.reduce((acc: { [key: string]: any }, channel: any) => {
    const { ChannelCategory, ChannelDescription, ChannelType } = channel;
    const hasWebsiteMetaData = channel.MetaData.some((meta: any) => meta.Value === 'website');

    if (hasWebsiteMetaData) {
      if (ChannelType.TypeDescription === 'Parent Channel') {
        if (!acc[ChannelCategory]) {
          acc[ChannelCategory] = {
            channels: [],
            description: ChannelDescription,
          };
        }
        acc[ChannelCategory].channels.push({
          ...channel,
          childChannels: [],
        });
      } else if (ChannelType.TypeDescription === 'Child Channel') {
        const parentChannel = acc[ChannelCategory].channels.find(
          (parent: any) => parent.ChannelID === ChannelType.ParentChannelID,
        );

        if (parentChannel) {
          parentChannel.childChannels.push(channel);
        }
      }
    }

    return acc;
  }, {});
};

type ProfileFormProps = {
  profilePageDescription: any; // Define the type of the prop
  contentfulSettings: any;
};

export const ProfileForm: React.FC<ProfileFormProps> = ({ profilePageDescription, contentfulSettings }) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { formRow, topSpacing, bottomSpacing, linkWrapper, trashIcon, phoneInput, requiredLabel } = styling();
  const intl = useIntl();
  const [formData, setFormData] = useState(defaultFormDataType);
  const [checkedChannels, setCheckedChannels] = useState<{ [key: number]: boolean }>({});
  const [allSelected, setAllSelected] = useState(false);
  const [groupedChannels, setGroupedChannels] = useState<{ [key: string]: any }>({});
  const [whatsappChannel, setWhatsappChannel] = useState<{ [key: string]: any }>({});
  const [checkedChannelsData, setCheckedChannelsData] = useState<{ [key: string]: any }>({});
  const [phone, setPhone] = useState('');
  const [phoneState, setPhoneState] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const userState = useAuth();
  const currentLocale = intl.locale;

  // @ts-expect-error problem with importing the reactWithUtils component on the conventional way, this works
  const LazyIntlTel = React.lazy(() => import('intl-tel-input/reactWithUtils'));

  useEffect(() => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let url = '/.netlify/functions/getAllPreferences';

    if (process.env.NODE_ENV === 'development') {
      url = 'http://localhost:9999/.netlify/functions/getAllPreferences';
    }

    fetch(url, {
      headers,
      method: 'GET',
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        setWhatsappChannel(data.channels.find((channel: any) => channel.ChannelName === 'WhatsApp'));
        setGroupedChannels(groupChannels(data.channels));
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  useEffect(() => {
    if (userState?.state.userState) {
      setFormData(prevFormData => ({
        ...prevFormData,
        email: {
          ...prevFormData.email,
          value: userState?.state.userState.emails[0] ?? '',
        },
        givenName: {
          ...prevFormData.givenName,
          value: userState?.state.userState.given_name ?? '',
        },
        name: {
          ...prevFormData.name,
          value: userState?.state.userState.family_name ?? '',
        },
      }));
    }
  }, [userState?.state.userState]);

  useEffect(() => {
    if (Object.keys(groupedChannels).length === 0) return;

    const localPreferences = localStorage.getItem('localPreferences');
    const parsedPreferences = localPreferences ? JSON.parse(localPreferences) : null;
    if (parsedPreferences) {
      buildCheckChannelsData(parsedPreferences);
    } else {
      getUserPreferences(userState?.state.accessToken, userState?.state.userState)
        .then(data => {
          buildCheckChannelsData(data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }, [groupedChannels]);

  const buildCheckChannelsData = (data: any) => {
    const initialCheckedChannels: { [key: number]: boolean } = {};
    const initialCheckedChannelsData: {
      [key: string]: { channelID: number; preferenceValue: number; statementID: number; privacyID: string };
    } = {};

    Object.values(groupedChannels).forEach((category: any) => {
      category.channels.forEach((channel: any) => {
        if (!data.optedInChannelIds || Object.keys(data.optedInChannelIds).length === 0) {
          data.optedInChannelIds = {};
          data.optedInChannelIds[channel.ChannelID] = false;
        }
        initialCheckedChannels[channel.ChannelID] = data.optedInChannelIds[channel.ChannelID] ?? false;
        initialCheckedChannelsData[channel.ChannelID] = {
          channelID: channel.ChannelID,
          preferenceValue: data.optedInChannelIds[channel.ChannelID] ? 1 : 0,
          privacyID: '3',
          statementID: channel.Statements[0].StatementID,
        };
        channel.childChannels.forEach((childChannel: any) => {
          initialCheckedChannels[childChannel.ChannelID] = data.optedInChannelIds[childChannel.ChannelID] ?? false;
          initialCheckedChannelsData[childChannel.ChannelID] = {
            channelID: childChannel.ChannelID,
            preferenceValue: data.optedInChannelIds[childChannel.ChannelID] ? 1 : 0,
            privacyID: '3',
            statementID: childChannel.Statements[0].StatementID,
          };
        });
      });
    });

    setPhone(data.phone);
    setPhoneState(true);
    setCheckedChannels(initialCheckedChannels);
    setCheckedChannelsData(initialCheckedChannelsData);
  };

  useEffect(() => {
    const isCheckedChannelsEmpty = Object.keys(checkedChannels).length === 0;
    const allChecked = !isCheckedChannelsEmpty && Object.values(checkedChannels).every(value => value === true);
    setAllSelected(allChecked);
  }, [checkedChannels]);

  const handleParentChange = (parentChannelID: number, isChecked: boolean, SVID: number, children: Array<any>) => {
    setCheckedChannels(prevState => {
      const updatedState = { ...prevState, [parentChannelID]: isChecked };
      Object.values(groupedChannels).forEach((category: any) => {
        category.channels.forEach((channel: any) => {
          if (channel.ChannelID === parentChannelID) {
            channel.childChannels.forEach((childChannel: any) => {
              updatedState[childChannel.ChannelID] = isChecked;
            });
          }
        });
      });
      return updatedState;
    });

    setCheckedChannelsData(prevState => {
      const updatedData = { ...prevState };
      updatedData[parentChannelID] = {
        channelID: parentChannelID,
        preferenceValue: isChecked ? 1 : 0,
        privacyID: '3',
        statementID: SVID,
      };

      children.forEach(child => {
        updatedData[child.childID] = {
          channelID: child.childID,
          preferenceValue: isChecked ? 1 : 0,
          privacyID: '3',
          statementID: child.statementID,
        };
      });

      return updatedData;
    });
  };

  const handleChildChange = (
    childChannelID: number,
    isChecked: boolean,
    parentChannelID: number,
    statementId: number,
  ) => {
    setCheckedChannels(prevState => {
      const updatedState = {
        ...prevState,
        [childChannelID]: isChecked,
      };

      // Get all child checkboxes with the same parentChannelID
      const childCheckboxes = document.querySelectorAll(`input[data-parent-id="${parentChannelID}"]`);
      // Check if all child checkboxes are checked
      const allChildrenChecked = Array.from(childCheckboxes).every(
        checkbox => updatedState[parseInt((checkbox as HTMLInputElement).id.split('-')[1])] === true,
      );

      // Set the parent checkbox state
      updatedState[parentChannelID] = allChildrenChecked;
      return updatedState;
    });

    setCheckedChannelsData(prevState => {
      const updatedData = { ...prevState };

      // Get all child checkboxes with the same parentChannelID
      const childCheckboxes = document.querySelectorAll(`input[data-parent-id="${parentChannelID}"]`);
      // Check if all child checkboxes are checked
      const allChildrenChecked = Array.from(childCheckboxes).every(
        checkbox => (checkbox as HTMLInputElement).checked === true,
      );

      updatedData[childChannelID] = {
        channelID: childChannelID,
        preferenceValue: isChecked ? 1 : 0,
        privacyID: '3',
        statementID: statementId,
      };

      updatedData[parentChannelID].preferenceValue = allChildrenChecked ? 1 : 0;

      return updatedData;
    });
  };

  const handleAllChange = (isChecked: boolean) => {
    setAllSelected(isChecked);

    setCheckedChannels(prevState => {
      const updatedState = { ...prevState };

      Object.values(groupedChannels).forEach((category: any) => {
        category.channels.forEach((channel: any) => {
          updatedState[channel.ChannelID] = isChecked;
          channel.childChannels.forEach((childChannel: any) => {
            updatedState[childChannel.ChannelID] = isChecked;
          });
        });
      });

      return updatedState;
    });

    setCheckedChannelsData(prevState => {
      const updatedState = { ...prevState };

      Object.values(prevState).forEach((child: any) => {
        updatedState[child.channelID] = {
          channelID: child.channelID,
          preferenceValue: isChecked ? 1 : 0,
          privacyID: '3',
          statementID: child.statementID,
        };
      });

      return updatedState;
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Add the phone number to the form data
    checkedChannelsData[whatsappChannel.ChannelID].extendedPrefs = [
      {
        keyID: 1,
        value: phone,
      },
    ];

    let url = '/.netlify/functions/updateCustomerPreferences';

    if (process.env.NODE_ENV === 'development') {
      url = `http://localhost:9999${url}`;
    }

    // Save to localstorage.
    localStorage.setItem(
      'localPreferences',
      JSON.stringify({
        optedInChannelIds: checkedChannels,
        phone: phone,
      }),
    );

    updateCustomerPreferences(url, checkedChannelsData);

    const toastContainer = document.getElementById('toast-container');
    if (toastContainer) {
      toastContainer.innerText = intl.formatMessage({ id: 'forms.profile.preferneces.toast.success' });
      toastContainer.classList.remove('hidden');

      setTimeout(() => {
        toastContainer.classList.add('hidden');
      }, 3000);
    }
  };

  const updateCustomerPreferences = async (url: any, checkedChannelsData: any) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    // Show loader
    renderLoaderButton('confirm-popin-confirm-button', true);

    fetch(url, {
      body: JSON.stringify({
        userAccessToken: userState?.state.accessToken,
        userEmailAddress: userState?.state.userState.emails[0],
        userId: userState?.state.userState.oid,
        userPreferences: checkedChannelsData,
      }),
      headers,
      method: 'POST',
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.userDelete) {
          localStorage.setItem('delete', new Date().toISOString());
          handleLogout(intl);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const deleteAccount = () => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let url = '/.netlify/functions/deleteCustomer';

    if (process.env.NODE_ENV === 'development') {
      url = `http://localhost:9999${url}`;
    }
    updateCustomerPreferences(url, {});
  };

  const handleValidityChangePhone = useCallback(
    (e: SetStateAction<string>) => {
      if (phone) {
        setPhoneError(e);
      } else {
        setPhoneError('false');
      }
    },
    [phone, setPhoneError],
  );

  return (
    <StyledContainer>
      <StyledForm as="form" onSubmit={handleSubmit}>
        <div className={topSpacing()}>
          <div className={formRow()}>
            <InputField
              field={formData.name}
              label={intl.formatMessage({ id: 'forms.profile.name.label' })}
              placeholder={intl.formatMessage({ id: 'forms.profile.name.placeholder' })}
              disabled={true}
            />
            <InputField
              field={formData.givenName}
              label={intl.formatMessage({ id: 'forms.profile.givenName.label' })}
              placeholder={intl.formatMessage({ id: 'forms.profile.givenName.placeholder' })}
              disabled={true}
            />
          </div>
          <div className={formRow()}>
            <InputField
              field={formData.email}
              label={intl.formatMessage({ id: 'forms.contact.email.label' })}
              placeholder={intl.formatMessage({ id: 'forms.contact.email.placeholder' })}
              disabled={true}
            />
          </div>
        </div>
        <div>
          <div className={bottomSpacing()}>
            <a href={`${buildPasswordChangeLink(intl.locale)}`} className={linkWrapper()}>
              <span>{intl.formatMessage({ id: 'forms.profile.change.password' })}</span>
            </a>
          </div>
          <RichText {...profilePageDescription} />
          <div>
            <div className="flex flex-col md:max-w-[450px]">
              <LightSwitch
                label={contentfulSettings.profilePageSelectAllLabel}
                checked={allSelected}
                handleChange={e => handleAllChange(e.target.checked)}
                id="all"
              />

              <Suspense fallback={<Loader />}>
                {Object.keys(groupedChannels).length !== 0 ? (
                  Object.keys(groupedChannels).map(category => (
                    <div key={category} className={topSpacing()}>
                      {category === 'Sector' ? (
                        <Heading tag="h2" variant="h5">
                          {contentfulSettings.profilePageSectorLabel}
                        </Heading>
                      ) : category === 'News' ? (
                        <Heading tag="h2" variant="h5">
                          {contentfulSettings.profilePageNewsLabel}
                        </Heading>
                      ) : category === 'Communications' ? (
                        <Heading tag="h2" variant="h5">
                          {contentfulSettings.profilePageCommunicationsLabel}
                        </Heading>
                      ) : null}
                      <div className="mt-4">
                        {groupedChannels[category].channels.map((channel: any) => {
                          const parentStatement = channel.Statements[0].LiveStatements.find(
                            (statement: any) => statement.LanguageCode === currentLocale,
                          );

                          return (
                            <div key={channel.ChannelID}>
                              {channel.childChannels.length > 0 ? (
                                <details className="group">
                                  <summary className="cursor-pointer list-none items-center relative">
                                    <LightSwitch
                                      label={parentStatement.StatementText}
                                      checked={checkedChannels[channel.ChannelID] || false}
                                      handleChange={e => {
                                        const children = channel.childChannels.map((childChannel: any) => {
                                          const statementID = childChannel.Statements[0].StatementID;
                                          const childID = childChannel.ChannelID;
                                          return { childID, statementID };
                                        });
                                        handleParentChange(
                                          channel.ChannelID,
                                          e.target.checked,
                                          channel.Statements[0].StatementID,
                                          children,
                                        );
                                      }}
                                      id={channel.ChannelID}
                                    />
                                    <span
                                      className="origin-center absolute right-[5px] top-1/2 transform -translate-y-1/2 transition-transform group-open:rotate-0 -rotate-90"
                                      style={{ transformOrigin: '50% 50%' }}
                                    >
                                      <svg
                                        className={`h-5 w-5`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </span>
                                  </summary>
                                  {channel.childChannels.map((childChannel: any) => {
                                    const childStatement = childChannel.Statements[0].LiveStatements.find(
                                      (statement: any) => statement.LanguageCode === currentLocale,
                                    );

                                    return (
                                      <div key={childChannel.ChannelID}>
                                        <LightSwitch
                                          label={childStatement.StatementText}
                                          checked={checkedChannels[childChannel.ChannelID] || false}
                                          dataParentId={channel.ChannelID}
                                          handleChange={e => {
                                            handleChildChange(
                                              childChannel.ChannelID,
                                              e.target.checked,
                                              channel.ChannelID,
                                              childChannel.Statements[0].StatementID,
                                            );
                                          }}
                                          id={childChannel.ChannelID}
                                        />
                                      </div>
                                    );
                                  })}
                                </details>
                              ) : (
                                <LightSwitch
                                  label={parentStatement.StatementText}
                                  checked={checkedChannels[channel.ChannelID] || false}
                                  handleChange={e => {
                                    handleParentChange(
                                      channel.ChannelID,
                                      e.target.checked,
                                      channel.Statements[0].StatementID,
                                      [],
                                    );
                                  }}
                                  id={channel.ChannelID}
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))
                ) : (
                  <Loader />
                )}
              </Suspense>
              <div className={topSpacing()}>
                <Heading
                  tag="h2"
                  variant="h5"
                  className={checkedChannels[whatsappChannel.ChannelID] ? requiredLabel() : ''}
                >
                  {intl.formatMessage({ id: 'forms.profile.whatsapp' })}
                </Heading>
                <div className="mt-4">
                  {(phone || phoneState) && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <div>
                        <LazyIntlTel
                          onChangeNumber={setPhone}
                          isValidNumberPrecise={true}
                          onChangeValidity={handleValidityChangePhone}
                          initialValue={phone || ''}
                          inputProps={{
                            className: `${phoneInput()} ${checkedChannels[whatsappChannel.ChannelID] ? requiredLabel() : ''}`,
                            required: !!checkedChannels[whatsappChannel.ChannelID],
                          }}
                          initOptions={{
                            containerClass: 'w-full',
                            formatOnDisplay: phone ? true : false,
                            initialCountry: 'be',
                            strictMode: true,
                          }}
                        />
                      </div>
                    </Suspense>
                  )}

                  {!phoneError && (
                    <div style={{ color: 'red' }}>{intl.formatMessage({ id: 'forms.contact.errors.telephone' })}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={topSpacing()}>
            <Button variant="primary" disabled={!phoneError || (checkedChannels[whatsappChannel.ChannelID] && !phone)}>
              {intl.formatMessage({ id: 'forms.profile.submit' })}
            </Button>
          </div>
          <div className={topSpacing()}>
            <button type="button" onClick={() => setShowConfirmDelete(true)} className={linkWrapper()}>
              <TrashCan className={trashIcon()} />
              <span>{intl.formatMessage({ id: 'forms.profile.account.delete.account' })}</span>
            </button>
            {showConfirmDelete && (
              <ConfirmPopin
                onClose={() => {
                  setShowConfirmDelete(false);
                }}
                titleId={'forms.profile.account.delete.confirm.title'}
                confirmMessageId={'forms.profile.account.delete.confirm.button'}
                messageId={'forms.profile.account.delete.messsage'}
                onConfirm={deleteAccount()}
              />
            )}
          </div>
        </div>
        <div
          id="toast-container"
          className="fixed bottom-5 left-1/2 transform -translate-x-1/2 z-50 bg-white p-6 rounded shadow-lg hidden"
        ></div>
      </StyledForm>
    </StyledContainer>
  );
};

export default withErrorBoundary(ProfileForm, { componentName: 'ProfileForm' });
