import { Fragment } from 'react';
import { useIntl } from 'react-intl';

import { ArticleCard, withErrorBoundary } from '../../components';

import type { Article } from './ArticleListingModule';
import { StyledCardWrapper, StyledMoreButton } from './Styled';

type ArticleListProps = {
  articles: Article[];
  numberOfArticlesToShow: number;
  handleLoadMore: () => void;
};

const ArticlesList = ({ articles, numberOfArticlesToShow, handleLoadMore }: ArticleListProps) => {
  const intl = useIntl();
  const showMoreButton = articles.length > numberOfArticlesToShow;

  if (!articles.length) {
    return null;
  }

  return (
    <Fragment>
      <StyledCardWrapper>
        {articles.slice(0, numberOfArticlesToShow).map(({ link, title, description, image, id, gated }) => {
          return (
            <ArticleCard link={link} title={title} description={description} image={image} key={id} gated={gated} />
          );
        })}
      </StyledCardWrapper>
      {showMoreButton && (
        <StyledMoreButton onClick={handleLoadMore}>
          {intl.formatMessage({ id: 'articleListing.loadMore' })}
        </StyledMoreButton>
      )}
    </Fragment>
  );
};

export default withErrorBoundary(ArticlesList, { componentName: 'ArticlesList' });
