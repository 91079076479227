import { graphql } from 'gatsby';

import { ContentfulSettings } from '../__generated__/graphql-types';
import Layout from '../components/layout';
import { NotFoundPage } from '../ui-lib/components';

type NotFoundQueryType = {
  site: any;
  contentfulSettings: ContentfulSettings;
};

type PageContextType = {
  node_locale: string;
};

type NotFoundProps = {
  data: NotFoundQueryType;
  pageContext: PageContextType;
};

const getTranslatedSlugs = () => {
  //for the 404 page there are no separate contentpages, we assume that there should be a 404 page per enabled locale
  const enabledLocales = process.env.ENABLED_LOCALES.split(',');
  if (enabledLocales?.length === 1 && enabledLocales[0] === '') return [];

  const translatedSlugs = enabledLocales.map(enabledLocale => ({
    node_locale: enabledLocale,
    slug: '404',
  }));

  return translatedSlugs;
};

const NotFound = ({ data, pageContext }: NotFoundProps) => {
  const title = data.contentfulSettings?.notFoundTitle;
  const shortDescription = data.contentfulSettings?.notFoundDescription?.text;
  const homepageSlug = data.contentfulSettings?.homepage?.slug;

  const cta = {
    label: data.contentfulSettings?.notFoundCta?.title,
    to: data.contentfulSettings?.notFoundCta?.internalEntry?.slug,
  };

  if (cta.to === homepageSlug) {
    cta.to = '/';
  }

  const pageProps = {
    contentfulSettings: data.contentfulSettings,
    isHomepage: false,
    ogPageType: '',
    pageLocale: pageContext.node_locale,
    pageSlug: '',
    pageType: '',
    siteUrl: data.site.siteMetadata.siteUrl,
    updatedAt: '',
  };

  const subscribeForm = {
    showFooterSubscribeForm: data.contentfulSettings?.footerShowSubscribeForm || false,
    showFooterSubscribeFormCountryField: false,
    subscribeFormHeading: data.contentfulSettings?.footerSubscribeFormHeading?.footerSubscribeFormHeading,
  };

  return (
    <Layout pageProps={pageProps} subscribeForm={subscribeForm} translatedSlugs={getTranslatedSlugs()}>
      <NotFoundPage title={title || ''} shortDescription={shortDescription} cta={cta} />
    </Layout>
  );
};

export default NotFound;

export const pageQuery = graphql`
  query ($node_locale: String!, $brand_tag: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulSettings(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      ...QuerySiteSettings
    }
  }
`;
