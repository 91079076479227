import { JwtPayload, jwtDecode } from 'jwt-decode';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';

import ConfirmPopin from '../../components/ConfirmPopin/ConfirmPopin';
import { getUserPreferences } from '../preferences/preferencesHelper';

import { getTokensFromQuery, handleLogin, silentRefresh } from './authUtils';

export const AuthenticatedTemplate: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const authContext = useAuth();
  if (!authContext) {
    return null;
  }
  const { state } = authContext;

  if (state.status === 'loading') {
    return null; // Or a spinner
  }

  return state.isAuthenticated ? <>{children}</> : null;
};

export const UnauthenticatedTemplate: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const authContext = useAuth();
  if (!authContext) {
    return null;
  }
  const { state } = authContext;

  if (state.status === 'loading') {
    return null; // Or a spinner
  }

  return !state.isAuthenticated ? <>{children}</> : null;
};

interface AuthState {
  isAuthenticated: boolean | null;
  userState: {
    emails: string[];
    given_name: string;
    family_name: string;
    oid: string;
    name: string;
  };
  accessToken: string;
  status: 'idle' | 'loading' | 'success' | 'error';
  error?: string;
  showConfirmDelete: boolean;
}

interface ExtendedJwtPayload extends JwtPayload {
  emails: string[];
  given_name: string;
  family_name: string;
  oid: string;
  name: string;
}

type AuthAction =
  | { type: 'START_AUTH' }
  | { type: 'AUTH_SUCCESS'; accessToken: string; userState: AuthState['userState'] }
  | { type: 'AUTH_ERROR'; error: string }
  | { type: 'LOGOUT' }
  | { type: 'SHOW_CONFIRM_DELETE' }
  | { type: 'HIDE_CONFIRM_DELETE' };

const initialAuthState: AuthState = {
  accessToken: '',
  isAuthenticated: null,
  showConfirmDelete: false,
  status: 'idle',
  userState: {
    emails: [],
    family_name: '',
    given_name: '',
    name: '',
    oid: '',
  },
};

const AuthContext = createContext<{ state: AuthState; dispatch: React.Dispatch<AuthAction> } | null>(null);

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'START_AUTH':
      return { ...state, status: 'loading' };
    case 'AUTH_SUCCESS':
      return {
        ...state,
        accessToken: action.accessToken,
        isAuthenticated: true,
        status: 'success',
        userState: action.userState,
      };
    case 'AUTH_ERROR':
      return { ...state, error: action.error, isAuthenticated: false, status: 'error' };
    case 'LOGOUT':
      return { ...initialAuthState, isAuthenticated: false };
    case 'SHOW_CONFIRM_DELETE':
      return { ...state, showConfirmDelete: true };
    case 'HIDE_CONFIRM_DELETE':
      return { ...state, showConfirmDelete: false };
    default:
      return state;
  }
}

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const intl = useIntl();
  const [state, dispatch] = useReducer(authReducer, initialAuthState);

  useEffect(() => {
    if (localStorage.getItem('delete')) {
      dispatch({ type: 'SHOW_CONFIRM_DELETE' });
    }
  }, []);

  useEffect(() => {
    dispatch({ type: 'START_AUTH' });

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('iframe') === 'true') {
      window.parent.postMessage(window.location.hash, window.location.origin);
    }

    const tokens = getTokensFromQuery();
    const storedAccessToken = localStorage.getItem('accessToken');

    const processToken = async (token: string) => {
      try {
        const decodedToken = jwtDecode(token) as ExtendedJwtPayload;
        if (decodedToken.exp && decodedToken.exp * 1000 > Date.now()) {
          if (decodedToken.name === 'unknown') {
            decodedToken.name = decodedToken.given_name + ' ' + decodedToken.family_name;
          }
          localStorage.setItem('accessToken', token);
          dispatch({ accessToken: token, type: 'AUTH_SUCCESS', userState: decodedToken });

          // Fetch user preferences
          const preferences = await getUserPreferences(token, decodedToken);
          if (preferences.length > 0) {
            localStorage.setItem('localPreferences', JSON.stringify(preferences));
          }
        } else {
          dispatch({ error: 'Token expired', type: 'AUTH_ERROR' });
          handleLogin(intl.locale, 'none');
        }
      } catch (error) {
        dispatch({ error: 'Invalid token', type: 'AUTH_ERROR' });
      }
    };

    if (storedAccessToken && !tokens) {
      processToken(storedAccessToken);
    } else if (tokens?.access_token) {
      processToken(tokens.access_token);
    } else if (tokens?.error && queryParams.get('iframe') !== 'true') {
      dispatch({ error: tokens.error, type: 'AUTH_ERROR' });
      localStorage.removeItem('accessToken');
    } else {
      dispatch({ error: 'No tokens found', type: 'AUTH_ERROR' });
    }

    const intervalId = setInterval(() => {
      if (storedAccessToken) {
        const decodedToken = JSON.parse(atob(storedAccessToken.split('.')[1]));
        if (decodedToken.exp && decodedToken.exp * 1000 - Date.now() <= 10 * 60 * 1000) {
          silentRefresh()
            .then(newToken => {
              if (newToken !== 'error') {
                processToken(newToken as string);
              }
            })
            .catch(error => {
              dispatch({ error: 'Silent refresh failed', type: 'AUTH_ERROR' });
            });
        }
      }
    }, 600000); // 10 minutes

    return () => clearInterval(intervalId);
  }, []);

  return (
    <AuthContext.Provider value={{ dispatch, state }}>
      {state.showConfirmDelete && (
        <ConfirmPopin
          onClose={() => {
            dispatch({ type: 'HIDE_CONFIRM_DELETE' });
            localStorage.removeItem('delete');
          }}
          titleId={'forms.profile.account.delete.success.title'}
          messageId={'forms.profile.account.delete.success'}
        />
      )}
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
