import { useAuth } from '@arvesta-websites/ui-babel';
import { PageProps, graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

import { ContentfulArticleDetailPage } from '../__generated__/graphql-types';
import { ContentfulEvent, ContentfulEventConnection, ContentfulSettings, Site } from '../__generated__/graphql-types';
import Layout from '../components/layout';
import { getContentsSectionCollection } from '../transforms/PageItemsMap';
import { ArticleDetailPage } from '../ui-lib/components/templates';
import { getCategoryLinks, getSubcategoryLinks } from '../ui-lib/utils';
import * as Utils from '../utils';

type EventQueryType = {
  contentfulEvent: ContentfulEvent;
  site: Site;
  contentfulSettings: ContentfulSettings;
  translatedSlugs: ContentfulEventConnection;
  contentfulArticleDetailPage: Omit<ContentfulArticleDetailPage, 'heroImage' | 'categories' | 'gated'> & {
    heroImage?: {
      title: string;
      altText?: string;
      asset: { gatsbyImageData: IGatsbyImageData };
    };
    gated?: boolean;
    subcategories: ContentfulArticleDetailPage['categories'];
    mainCategories: ContentfulArticleDetailPage['categories'];
  };
};

type PageContextType = {
  id: number;
  node_locale: string;
};

const ArticleDetail = ({ data, pageContext, location }: PageProps<EventQueryType, PageContextType>) => {
  const {
    heroImage,
    title,
    date,
    mainCategories,
    subcategories,
    shortDescription,
    seoMetadata,
    slug,
    content,
    updatedAt,
    showDate,
    gated,
  } = data.contentfulArticleDetailPage;

  const gatedContentAvailable = process.env.GATED_CONTENT === 'true';
  const isLockedForNonAuthenticatedUsers = gatedContentAvailable && gated;
  const auth = useAuth();
  if (!auth) {
    return null;
  }
  const { state } = auth;

  const pageProps = {
    contentfulSettings: data.contentfulSettings,
    id: pageContext.id,
    isHomepage: false,
    ogPageType: 'article',
    pageDescription: seoMetadata?.description,
    pageImage: heroImage ?? undefined,
    pageLocale: pageContext.node_locale,
    pageSettings: seoMetadata,
    pageSlug: slug,
    pageTitle: title || (seoMetadata?.title as string),
    pageType: 'articleDetail',
    siteUrl: data.site.siteMetadata?.siteUrl ?? '',
    updatedAt,
  };

  const subscribeForm = {
    showFooterSubscribeForm: data.contentfulSettings.footerShowSubscribeForm ?? false,
    showFooterSubscribeFormCountryField: false,
    subscribeFormHeading: data.contentfulSettings.footerSubscribeFormHeading?.footerSubscribeFormHeading ?? '',
  };

  const modules = Utils.parseModules(content || [], data.contentfulSettings, location);
  return (
    <Layout
      pageProps={pageProps}
      subscribeForm={subscribeForm}
      translatedSlugs={data.translatedSlugs.nodes}
      heroReference={heroImage}
    >
      <ArticleDetailPage
        mainCategories={getCategoryLinks(mainCategories)}
        subcategories={getSubcategoryLinks(subcategories)}
        contentsSectionCollection={getContentsSectionCollection(modules, data, location, pageContext, title)}
        date={date}
        description={shortDescription?.shortDescription}
        title={title}
        isAuthenticated={state.isAuthenticated}
        isLockedForNonAuthenticatedUsers={isLockedForNonAuthenticatedUsers}
        showDate={showDate}
      />
      {/* 
      <AuthenticatedTemplate>
        <h1>Ingelogd</h1>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h1>Nie Ingelogd</h1>
      </UnauthenticatedTemplate>
      */}
    </Layout>
  );
};

export default ArticleDetail;

export const pageQuery = graphql`
  query ($id: String!, $node_locale: String!, $brand_tag: String!, $contentful_id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    contentfulSettings(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      ...QuerySiteSettings
    }

    translatedSlugs: allContentfulArticleDetailPage(filter: { contentful_id: { eq: $contentful_id } }) {
      nodes {
        contentful_id
        slug
        node_locale
      }
    }

    contentfulArticleDetailPage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      title
      slug
      updatedAt(formatString: "YYYYMMDDHHmmss")
      date
      showDate
      shortDescription {
        shortDescription
      }
      seoMetadata {
        id
        description
        keywords
        no_index
        no_follow
        canonicalUrlpath
        title
      }
      heroImage {
        title
        alt: altText
        imageWithFocalPoint(quality: 80)
        asset {
          gatsbyImageData
        }
      }
      mainCategories: categories {
        ... on ContentfulCategory {
          __typename
          contentful_id
          title
          slug
          contentfulchildren {
            __typename
            ... on ContentfulSubcategory {
              __typename
              contentful_id
              title
              slug
            }
          }
          article_listing_section {
            basic_page {
              slug
            }
          }
        }
      }
      subcategories: categories {
        ... on ContentfulSubcategory {
          __typename
          contentful_id
          title
          slug
          category {
            __typename
            contentful_id
            title
            slug
            article_listing_section {
              basic_page {
                slug
              }
            }
          }
        }
      }

      content {
        __typename
        ... on ContentfulHeroSection {
          ...QueryHeroSection
        }
        ... on ContentfulInfoSection {
          ...QueryInfoSection
        }
        ... on ContentfulUpcomingEvents {
          ...QueryUpcomingEvents
        }
        ... on ContentfulLatestNewsSection {
          ...QueryLatestNewsSection
        }
        ... on ContentfulProductsServicesSection {
          ...QueryProductsServices
        }
        ... on ContentfulCtaBanner {
          ...QueryCtaBanner
        }
        ... on ContentfulContactsSection {
          ...QueryContactsSection
        }
        ... on ContentfulProductCategoriesSection {
          ...QueryProductCategoriesSection
        }
        ... on ContentfulRichTextSection {
          ...QueryRichTextSection
        }
        ... on ContentfulContactsSection {
          ...QueryContactsSection
        }
        ... on ContentfulFindADealerSection {
          ...QueryFindADealerSection
        }
        ... on ContentfulAdviceListingSection {
          ...QueryAdviceListingSection
        }
        ... on ContentfulNewsListingSection {
          ...QueryNewsListing
        }
        ... on ContentfulEventsListingSection {
          ...QueryEventsListing
        }
        ... on ContentfulPastEventsSection {
          ...QueryPastEventsSection
        }
        ... on ContentfulCategoryListingSection {
          ...QueryCategoryListingSection
        }
        # TODO check if this may be removed
        ... on ContentfulImageAndText {
          ...QueryImageAndText
        }
        ... on ContentfulNewsletterSubscription {
          ...QueryNewsletterSubscription
        }
        ... on ContentfulSubcategoryListingSection {
          ...QuerySubcategoryListingSection
        }
        ... on ContentfulMediaBlock {
          ...QueryMediaBlock
        }
        ... on ContentfulTable {
          ...QueryTable
        }
        ... on ContentfulCustomBlocksSection {
          ...QueryCustomBlocksSection
        }
        ... on ContentfulAdvicesSection {
          ...QueryAdvicesSection
        }
        ... on ContentfulListingManual {
          ...QueryListingManual
        }
        ... on ContentfulCtaGroup {
          ...QueryCtaGroup
        }
        ... on ContentfulFeaturesSection {
          ...QueryFeaturesSection
        }
        ... on ContentfulHeroBanner {
          ...QueryHeroBanner
        }
        ... on ContentfulColumns {
          ...QueryColumns
        }
        ... on ContentfulVideo {
          ...QueryVideo
        }
        ... on ContentfulArticleListingSection {
          ...QueryArticleListingSection
        }
        ... on ContentfulImage {
          ...QueryImage
        }
      }
      gated
    }
  }
`;
