import { ContentfulMosaicSection, ContentfulProductCollectionLister } from '../../__generated__/graphql-types';
import { ContentfulImageAndText } from '../../__generated__/graphql-types';

import transformImageAndText from './ImageAndText';
import transformMosaicSection from './MosaicSection';

const findMosaicSection = (elem: any): elem is ContentfulMosaicSection => elem.__typename === 'ContentfulMosaicSection';
const filterImageAndText = (elem: any): elem is ContentfulImageAndText => elem.__typename === 'ContentfulImageAndText';

const transformProductCollectionLister = (item: ContentfulProductCollectionLister, locale: string) => {
  const mosaicSectionData = item.content?.find(findMosaicSection);
  return {
    ...item,
    __typename: 'ProductCollectionLister',
    imageAndTextCollection: item.content?.filter(filterImageAndText).map(elem => transformImageAndText(elem, locale)),
    key: item.id,
    mosaicSection: mosaicSectionData && transformMosaicSection(mosaicSectionData),
  };
};

export default transformProductCollectionLister;
