/* eslint-disable no-underscore-dangle */
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { getSrc } from 'gatsby-plugin-image';

import {
  ContentfulEventConnection,
  ContentfulEventEdge,
  ContentfulLocationContentfulMagentoStoreContentfulRichTextSectionUnion,
} from '../__generated__/graphql-types';

import extractCta from './Cta';
import extractShortDescription from './ShortDescription';

const getEventLocation = (location: ContentfulLocationContentfulMagentoStoreContentfulRichTextSectionUnion) => {
  if (location.__typename === 'ContentfulLocation') {
    return `${location.title} ${location.address?.address}`;
  }

  if (location.__typename === 'ContentfulRichTextSection') {
    return location?.richText?.raw ? documentToPlainTextString(JSON.parse(location.richText.raw)) : '';
  }

  return null;
};

const transformEvents = (edges: Array<ContentfulEventEdge>) => {
  return edges
    .map((edge: ContentfulEventEdge): any => {
      const eventItem = edge.node;

      if (eventItem) {
        return {
          date: eventItem.displayDate,
          id: eventItem.id,
          image: getSrc(eventItem.hero?.image?.imageWithFocalPoint),
          link: eventItem.slug,
          location: eventItem.location ? getEventLocation(eventItem.location) : '',
          shortDescription: extractShortDescription(eventItem),
          startDate: eventItem.startDate,
          title: eventItem.hero?.title,
        };
      }

      return null;
    })
    .filter(Boolean)
    .filter((item: any) => {
      return item.startDate > new Date().toISOString();
    })
    .slice(0, 4);
};

const transformUpcomingEventsSection = (item: any, events: ContentfulEventConnection) => {
  if (!item) {
    return null;
  }

  return {
    __typename: 'UpcomingEvents',
    cta: extractCta(item),
    key: item.id,
    shortDescription: item.shortDescription && item.shortDescription.text ? item.shortDescription.text : '',
    title: item.title,
    titleTag: item.titleTag,
    upcomingEvents: transformEvents(events.edges),
  };
};

export default transformUpcomingEventsSection;
