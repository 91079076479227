import { Box } from 'rebass/styled-components';
import styled, { keyframes } from 'styled-components';
//

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    top: 0px;
  }

  to {
    top: -80px;
  }
`;

export const StyledCookieWrapper = styled(Box)`
  display: block;
  font-family:
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  font-size: 15px;
  font-weight: 200;
  line-height: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999999999;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  background: #f3f3f6 !important;
  color: #222 !important;
  position: relative !important;
`;

export const StyledCloseLink = styled.a`
  display: block;
  box-sizing: border-box;
  padding: 10px;
  position: absolute;
  top: 10px;
  right: 27px;

  @media only screen and (max-width: 600px) {
    top: 0px;
    right: 10px;
  }
`;

export const StyledCloseIcon = styled.svg`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: -2px;

  > svg {
    fill: #84bd00 !important;
  }

  @media only screen and (max-width: 600px) {
    width: 20px;
    height: 20px;
    right: 10px;
  }
`;

export const StyledContentContainer = styled.div`
  padding: 20px 60px 20px 20px;
  font-size: 18px;
  line-height: 24px;
  max-width: 600px;
  position: relative;
  margin: auto;

  @media only screen and (max-width: 600px) {
    padding: 10px 60px 10px 10px;
    font-size: 15px;
  }

  &.is-visible {
    visibility: visible;
    animation: 0.8s ease-out 0s 1 ${slideIn};
  }

  &.is-hidden {
    visibility: hidden;
    animation: ${slideOut} 0.25s ease-in;
    transition: 0.25s linear;
  }
`;

export const StyledText = styled.div`
  color: #222 !important;
  font-weight: 400;
  top: 0;
  letter-spacing: 0.5008px;
  @media only screen and (max-width: 600px) {
    line-height: 20px;
  }
`;

export const StyledLink = styled.a`
  color: #84bd00 !important;
  transition: color 0.2s;
  text-decoration: underline;
  &:hover {
    color: #000;
  }
`;
