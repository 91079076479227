import { Close } from '@arvesta-websites/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';

import Button from '../Button';

interface ConfirmPopinProps {
  onClose: () => void;
  titleId?: string | null;
  messageId: string;
  confirmMessageId?: string | null;
  cancelMessageId?: string | null;
  onConfirm?: (e: any) => void;
  onCancel?: (e: any) => void;
}

const styling = tv({
  slots: {
    closeButton: 'absolute top-3 right-3 text-gray-500 hover:text-gray-700',
    closeIcon: 'w-6 h-6',
    confirmButtonWrapper: 'mt-6 flex justify-center',
    container: 'fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50',
    containerInner: 'bg-white p-10 shadow-lg relative text-center w-11/12 max-w-md',
    title: 'text-3xl font-bold mb-6',
  },
});

const ConfirmPopin: React.FC<ConfirmPopinProps> = ({
  onClose,
  titleId,
  messageId,
  confirmMessageId,
  cancelMessageId,
  onConfirm,
  onCancel,
}) => {
  const { container, containerInner, closeButton, closeIcon, title, confirmButtonWrapper } = styling();
  const intl = useIntl();

  return (
    <div
      className={container()}
      onClick={() => {
        onClose();
      }}
    >
      <div className={containerInner()} onClick={e => e.stopPropagation()}>
        {!cancelMessageId && (
          <button
            className={closeButton()}
            onClick={() => {
              onClose();
            }}
          >
            <Close className={closeIcon()} />
          </button>
        )}
        {titleId && <h2 className={title()}>{intl.formatMessage({ id: titleId })}</h2>}
        <p>{intl.formatMessage({ id: messageId })}</p>
        {(cancelMessageId || confirmMessageId) && (
          <div className={confirmButtonWrapper()}>
            <div>
              {cancelMessageId && (
                <Button variant="secondary" onClick={onCancel || onClose}>
                  {intl.formatMessage({ id: cancelMessageId })}
                </Button>
              )}
              {confirmMessageId && (
                <Button id="confirm-popin-confirm-button" variant="primary" onClick={onConfirm ? onConfirm : undefined}>
                  {intl.formatMessage({ id: confirmMessageId })}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmPopin;
