import Img from 'gatsby-image';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';
import { Box } from 'rebass';
import { tv } from 'tailwind-variants';

import { useAuth } from '../../../shared/auth/authContext';
import Button from '../../Button';
import Cta, { CtaProps } from '../../Cta';
import { fileTypeIconMap } from '../../DocumentDownload';
import { withErrorBoundary } from '../../ErrorBoundary';
import Lock from '../../Lock/Lock';

import { ProductCardProps, ctaProps } from './ProductCard';
import {
  StyledCommerceWrapperHorizontal,
  StyledCommerceWrapperHorizontalNoCta,
  StyledContentBox,
  StyledCta,
  StyledCtaWrapper,
  StyledDescription,
  StyledImageBoxLinkHorizontal,
  StyledImageBoxLinkWrapper,
  StyledImageBoxWrapper,
  StyledProductInfo,
  StyledTitle,
} from './Styled';

const styling = tv({
  slots: {
    imageWrapper: 'relative',
  },
});

const renderEnquiryBox = (
  price: string | undefined,
  enquiryCta: ctaProps | undefined,
  variant: 'vertical' | 'horizontalLeft' | 'horizontalRight' | undefined,
) => (
  <>
    {price && !enquiryCta ? (
      <StyledCommerceWrapperHorizontal className={variant}>
        {price ? <p>{price}</p> : null}
      </StyledCommerceWrapperHorizontal>
    ) : null}

    {price && enquiryCta ? (
      <StyledCommerceWrapperHorizontal className={variant}>
        {price ? <p>{price}</p> : null}

        {enquiryCta ? (
          <Cta to={enquiryCta.link}>
            <Button>{enquiryCta.label}</Button>
          </Cta>
        ) : null}
      </StyledCommerceWrapperHorizontal>
    ) : null}

    {enquiryCta && !price ? (
      <StyledCommerceWrapperHorizontalNoCta className={variant}>
        <Cta to={enquiryCta.link}>
          <Button>{enquiryCta.label}</Button>
        </Cta>
      </StyledCommerceWrapperHorizontalNoCta>
    ) : null}
  </>
);

const HorizontalProductCard = ({
  title,
  shortDescription,
  image,
  link,
  showLink,
  price,
  enquiryCta,
  variant,
  showCommerceSection,
  cardCTAText,
  linkToDetailPage,
  linkToCustomPage,
  documents = [],
  gated,
}: ProductCardProps) => {
  const intl = useIntl();
  const imageBoxProps: Pick<CtaProps, 'to'> = {};
  if (linkToDetailPage) {
    imageBoxProps.to = link;
  }

  if (linkToCustomPage) {
    imageBoxProps.to = linkToCustomPage.internalEntry
      ? linkToCustomPage.internalEntry.slug
      : linkToCustomPage.externalUrl;
  }

  const gatedContentAvailable = process.env.GATED_CONTENT && process.env.GATED_CONTENT === 'true';
  const contentfulGated = gated;

  const isLockedForNonAuthenticatedUsers = gatedContentAvailable && contentfulGated;
  const auth = useAuth();
  if (!auth) {
    return null;
  }
  const { state } = auth;
  const { imageWrapper } = styling();

  return (
    <>
      {variant === 'horizontalLeft' ? (
        <StyledImageBoxWrapper>
          <StyledImageBoxLinkWrapper className={imageWrapper()}>
            <StyledImageBoxLinkHorizontal {...imageBoxProps}>
              {image && image.asset && (
                <GatsbyImage
                  alt={image.alt ? image.alt : image.title}
                  image={getImage(image.asset) as IGatsbyImageData}
                  objectFit="contain"
                  style={{ margin: '0 auto', maxHeight: '80%', position: 'inherit', width: '80%' }}
                ></GatsbyImage>
              )}
            </StyledImageBoxLinkHorizontal>
            {isLockedForNonAuthenticatedUsers && !state.isAuthenticated && <Lock variant="centered" />}
          </StyledImageBoxLinkWrapper>
        </StyledImageBoxWrapper>
      ) : null}

      <StyledContentBox className={price || enquiryCta ? 'incl-commerce' : ''}>
        <StyledProductInfo>
          <Box>
            <StyledTitle>{linkToDetailPage ? <Cta to={link}>{title}</Cta> : title}</StyledTitle>
            <StyledDescription>{shortDescription}</StyledDescription>
          </Box>
          <Box>
            {showLink && linkToDetailPage && !linkToCustomPage && (
              <StyledCtaWrapper>
                <StyledCta to={link}>
                  <span>{cardCTAText || intl.formatMessage({ id: 'cards.product.link' })}</span>
                </StyledCta>
              </StyledCtaWrapper>
            )}

            {linkToCustomPage && (linkToCustomPage.internalEntry || linkToCustomPage.externalUrl) && (
              <StyledCtaWrapper>
                <StyledCta
                  to={
                    linkToCustomPage.internalEntry ? linkToCustomPage.internalEntry.slug : linkToCustomPage.externalUrl
                  }
                >
                  <span>{linkToCustomPage.title}</span>
                </StyledCta>
              </StyledCtaWrapper>
            )}

            {!linkToDetailPage && !linkToCustomPage && !!documents.length && (
              <StyledCtaWrapper horizontal>
                {documents.map(({ documentTitle, documentType, downloadLink }) => (
                  <StyledCta horizontal key={documentTitle} target="_blank" to={downloadLink}>
                    {documentType && fileTypeIconMap[documentType]}
                    <span>{cardCTAText || documentTitle}</span>
                  </StyledCta>
                ))}
              </StyledCtaWrapper>
            )}
          </Box>
        </StyledProductInfo>

        {showCommerceSection && !price && !enquiryCta ? (
          <StyledCommerceWrapperHorizontal className={variant} />
        ) : (
          renderEnquiryBox(price, enquiryCta, variant)
        )}
      </StyledContentBox>

      {variant === 'horizontalRight' ? (
        <StyledImageBoxWrapper>
          <StyledImageBoxLinkWrapper className={imageWrapper()}>
            <StyledImageBoxLinkHorizontal to={link}>
              {/* TODO Fix to GatsbyImage */}
              {image && image.asset && (
                <Img
                  alt={image.alt}
                  fluid={image.asset.fluid}
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ margin: '0 auto', maxHeight: '80%', position: 'inherit', width: '80%' }}
                />
              )}
            </StyledImageBoxLinkHorizontal>
            {isLockedForNonAuthenticatedUsers && !state.isAuthenticated && <Lock variant="centered" />}
          </StyledImageBoxLinkWrapper>
        </StyledImageBoxWrapper>
      ) : null}
    </>
  );
};

export default withErrorBoundary(HorizontalProductCard, { componentName: 'HorizontalProductCard' });
