import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';

export const StyledSecondaryNavigationMobile = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-height: min-content;
`;

export const StyledNavItem = styled.li`
  height: 72px;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  list-style: none;
  padding-left: 24px;

  a {
    display: inline-block;
    white-space: nowrap;
    text-transform: uppercase;
    text-decoration: none;
    font-size: ${props => props.theme.fontSizes[2]};
    font-weight: ${props => props.theme.fontWeights.bold};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 2px;
    line-height: 72px;
    width: 100%;
    height: 100%;
    vertical-align: middle;

    color: ${props => props.theme.colors.darkGray};
    &:hover {
      color: ${props => props.theme.colors.black};
    }
  }
`;

export const StyledNavItemWithChildren = styled.li`
  height: 72px;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  list-style: none;
  padding-left: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.colors.darkGray};

  a {
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    font-size: ${props => props.theme.fontSizes[2]};
    font-weight: ${props => props.theme.fontWeights.heading};
    text-transform: ${props => props.theme.navItems.textTransform};
    letter-spacing: ${props => props.theme.navItems.letterSpacing};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    line-height: 72px;
    width: 100%;
    height: 100%;
    vertical-align: middle;

    color: ${props => props.theme.colors.darkGray};
    &:hover {
      color: ${props => props.theme.colors.black};
    }
  }
`;

export const StyledCloseButton = styled(Box)`
  min-height: 78px;
  padding-left: 24px;
  margin-bottom: 60px;
  display: flex;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  display: flex;
  align-items: center;

  @media screen and (max-height: 700px) {
    margin-bottom: 10px;
  }
`;

export const StyledDownArrow = styled.span`
  display: flex;
  align-items: center;
  color: red;
  margin-right: 30px;
`;

export const StyledSubMenu = styled.ul`
  background-color: ${props => props.theme.colors.superLightGrey};
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  margin: 0;
  list-style: none;
  padding-left: 40px;
`;

export const StyledMobileLanguageSelect = styled(Box)`
  min-height: 56px;
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  ${Media({
    width: ['320px', null, '70%', '320px'],
  })}

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 50px;

  @media screen and (min-height: 750px) {
    position: fixed;
    bottom: 0;
    right: 0;
  }
`;

export const StyledSelectText = styled.span`
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.heading};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-transform: uppercase;
  color: ${props => props.theme.colors.darkGray};
`;
