import React, { useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';

interface LoaderButtonProps {
  buttonId: string;
  invert?: boolean;
}

const LoaderButton: React.FC<LoaderButtonProps> = ({ buttonId, invert = false }) => {
  useEffect(() => {
    const confirmButton = document.getElementById(buttonId);
    if (confirmButton) {
      const buttonWidth = confirmButton.offsetWidth;
      confirmButton.innerHTML = `<div class="flex justify-center items-center w-full h-full"><div class="loader w-5 p-1 aspect-square rounded-full ${invert ? 'bg-white' : 'bg-primary'}"></div></div>`;
      confirmButton.style.width = `${buttonWidth}px`;
      confirmButton.style.pointerEvents = 'none'; // Disable hover state
      const style = document.createElement('style');
      style.innerHTML = `
      .loader {
        --_m: 
          conic-gradient(#0000 10%, #000),
          linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
        mask: var(--_m);
        -webkit-mask-composite: source-out;
        mask-composite: subtract;
        animation: spin 1s infinite linear;
      }
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;
      document.head.appendChild(style);
    }
  }, [buttonId]);

  return null;
};

export const renderLoaderButton = (buttonId: string, invert = false) => {
  const container = document.getElementById(buttonId);
  if (container) {
    hydrateRoot(container, <LoaderButton buttonId={buttonId} invert={invert} />);
  } else {
    console.error('Container element not found');
  }
};

export default LoaderButton;
