import { LockIcon } from '@arvesta-websites/icons';
import React from 'react';
import { tv } from 'tailwind-variants';

export interface LockProps {
  variant?: string;
}

const styling = tv({
  slots: {
    icon: 'w-4 h-4 fill-current text-white',
    lockContainer: 'w-10 h-10 flex items-center justify-center bg-primary rounded-button absolute top-0 right-0',
    lockContainerCentered:
      'w-10 h-10 flex items-center justify-center bg-primary rounded-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
  },
});

const Lock = ({ variant }: LockProps) => {
  const { lockContainer, lockContainerCentered, icon } = styling();

  if (variant === 'centered') {
    return (
      <div className={lockContainerCentered()}>
        <LockIcon className={icon()} />
      </div>
    );
  } else {
    return (
      <div className={lockContainer()}>
        <LockIcon className={icon()} />
      </div>
    );
  }
};

export default Lock;
