import { PageProps, graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import {
  ContentfulBasicPage,
  ContentfulDealerV2Connection,
  ContentfulOwner,
  ContentfulOwnerConnection,
  ContentfulSettings,
  Site,
} from '../__generated__/graphql-types';
import Layout from '../components/layout';
import { getContentsSectionCollection } from '../transforms/PageItemsMap';
import { OwnerDetailPage } from '../ui-lib/components/templates';
import * as Utils from '../utils';

type OwnerQueryType = {
  site: Site;
  contentfulSettings: ContentfulSettings;
  translatedSlugs: ContentfulOwnerConnection;
  contentfulOwner: Omit<ContentfulOwner, 'heroImage'> & {
    heroImage?: {
      title: string;
      altText?: string;
      asset: { gatsbyImageData: IGatsbyImageData };
    };
  };
  allContentfulDealerV2: ContentfulDealerV2Connection;
};

type PageContextType = {
  id: number;
  node_locale: string;
};

const OwnerDetail = ({ data, pageContext, location }: PageProps<OwnerQueryType, PageContextType>) => {
  const { id, heroImage, title, description, seoMetadata, slug, content } = data.contentfulOwner;

  const dealers = data.allContentfulDealerV2.edges.filter(
    dealer => dealer?.node?.owner && dealer?.node?.owner.id === id,
  );

  const pageProps = {
    contentfulSettings: data.contentfulSettings,
    id: pageContext.id,
    isHomepage: false,
    ogPageType: 'owner',
    pageDescription: seoMetadata?.description,
    pageImage: heroImage ?? undefined,
    pageLocale: pageContext.node_locale,
    pageSettings: seoMetadata,
    pageSlug: slug,
    pageTitle: title || (seoMetadata?.title as string),
    pageType: 'OwnerDetail',
    siteUrl: data.site.siteMetadata?.siteUrl ?? '',
  };

  const subscribeForm = {
    showFooterSubscribeForm: data.contentfulSettings.footerShowSubscribeForm ?? false,
    showFooterSubscribeFormCountryField: false,
    subscribeFormHeading: data.contentfulSettings.footerSubscribeFormHeading?.footerSubscribeFormHeading ?? '',
  };

  const modules = Utils.parseModules(content || [], data.contentfulSettings, location);

  let storeLocatorCrumb;
  if (data.contentfulSettings.storeLocatorPage) {
    const { slug, title } = data.contentfulSettings.storeLocatorPage as ContentfulBasicPage;
    storeLocatorCrumb = { label: title, to: slug };
  }

  return (
    <Layout
      pageProps={pageProps}
      subscribeForm={subscribeForm}
      translatedSlugs={data.translatedSlugs.nodes}
      heroReference={heroImage}
      extraBreadCrumbs={[storeLocatorCrumb]}
    >
      <OwnerDetailPage
        content={getContentsSectionCollection(modules, data, location, pageContext, title)}
        description={description?.description}
        title={title}
        extraImage={data.contentfulOwner?.extraImage}
        stores={dealers}
      />
    </Layout>
  );
};

export default OwnerDetail;

export const pageQuery = graphql`
  query ($id: String!, $node_locale: String!, $brand_tag: String!, $contentful_id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    contentfulSettings(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      ...QuerySiteSettings
    }

    translatedSlugs: allContentfulOwner(filter: { contentful_id: { eq: $contentful_id } }) {
      nodes {
        contentful_id
        slug
        node_locale
      }
    }

    allContentfulDealerV2(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
    ) {
      edges {
        node {
          address {
            street
            number
            countryCode
            cityTown
            location {
              lat
              lon
            }
            postalCode
          }
          telephone
          name
          email
          openingHours {
            raw
          }
          services
          teamMembers {
            name
            jobTitle
            telephone
          }
          owner {
            slug
            id
          }
        }
      }
    }

    contentfulOwner(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      title
      slug
      description {
        description
      }
      seoMetadata {
        id
        description
        keywords
        no_index
        no_follow
        canonicalUrlpath
        title
      }
      heroImage {
        title
        alt: altText
        imageWithFocalPoint(quality: 80)
        asset {
          gatsbyImageData
        }
      }
      extraImage {
        title
        alt: altText
        imageWithFocalPoint(layout: CONSTRAINED, quality: 80)
        asset {
          gatsbyImageData(layout: CONSTRAINED, quality: 85)
        }
      }
      content {
        __typename
        ... on ContentfulAdviceListingSection {
          ...QueryAdviceListingSection
        }
        ... on ContentfulAdvicesSection {
          ...QueryAdvicesSection
        }
        ... on ContentfulArticleListingSection {
          ...QueryArticleListingSection
        }
        ... on ContentfulCategoryListingSection {
          ...QueryCategoryListingSection
        }
        ... on ContentfulColumns {
          ...QueryColumns
        }
        ... on ContentfulContactsSection {
          ...QueryContactsSection
        }
        ... on ContentfulContactsSection {
          ...QueryContactsSection
        }
        ... on ContentfulCtaBanner {
          ...QueryCtaBanner
        }
        ... on ContentfulCtaGroup {
          ...QueryCtaGroup
        }
        ... on ContentfulCustomBlocksSection {
          ...QueryCustomBlocksSection
        }
        ... on ContentfulEmbeddedContent {
          ...QueryEmbeddedContent
        }
        ... on ContentfulEventsListingSection {
          ...QueryEventsListing
        }
        ... on ContentfulFindADealerSection {
          ...QueryFindADealerSection
        }
        ... on ContentfulHeroBanner {
          ...QueryHeroBanner
        }
        ... on ContentfulHeroSection {
          ...QueryHeroSection
        }
        ... on ContentfulImage {
          ...QueryImage
        }
        ... on ContentfulImageAndText {
          ...QueryImageAndText
        }
        ... on ContentfulInfoBlock {
          ...QueryInfoBlock
        }
        ... on ContentfulInfoSection {
          ...QueryInfoSection
        }
        ... on ContentfulLatestNewsSection {
          ...QueryLatestNewsSection
        }
        ... on ContentfulListingManual {
          ...QueryListingManual
        }
        ... on ContentfulMosaicSection {
          ...QueryMosaicSection
        }
        ... on ContentfulNewsListingSection {
          ...QueryNewsListing
        }
        ... on ContentfulNewsletterSubscription {
          ...QueryNewsletterSubscription
        }
        ... on ContentfulPastEventsSection {
          ...QueryPastEventsSection
        }
        ... on ContentfulProductCategoriesSection {
          ...QueryProductCategoriesSection
        }
        ... on ContentfulProductCollectionLister {
          ...QueryProductCollectionLister
        }
        ... on ContentfulProductListingSection {
          ...QueryProductListingSection
        }
        ... on ContentfulProductsListingTableSection {
          ...QueryProductListingTable
        }
        ... on ContentfulProductsServicesSection {
          ...QueryProductsServices
        }
        ... on ContentfulRichTextSection {
          ...QueryRichTextSection
        }
        ... on ContentfulShortBanner {
          ...QueryShortBanner
        }
        ... on ContentfulSubcategoryListingSection {
          ...QuerySubcategoryListingSection
        }
        ... on ContentfulTable {
          ...QueryTable
        }
        ... on ContentfulCustomBlocksSection {
          ...QueryCustomBlocksSection
        }
        ... on ContentfulAdvicesSection {
          ...QueryAdvicesSection
        }
        ... on ContentfulEmbeddedContent {
          ...QueryEmbeddedContent
        }
      }
    }
  }
`;
