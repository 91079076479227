import { ContentfulNewsEdge } from '../__generated__/graphql-types';

import extractShortDescription from './ShortDescription';

const transformNewsItems = (newsItems: Array<ContentfulNewsEdge>) => {
  return newsItems
    .map((edge: any) => {
      const newsItem = edge.node;

      if (newsItem) {
        return {
          date: newsItem.date,
          gated: newsItem.gated,
          id: newsItem.id,
          image: { ...newsItem.image, asset: { gatsbyImageData: newsItem.image?.imageWithFocalPoint } },
          link: newsItem.slug,
          shortDescription: extractShortDescription(newsItem),
          title: newsItem.title,
        };
      }
      return null;
    })
    .filter(Boolean)
    .slice(0);
};

export default transformNewsItems;
