import {
  AdviceCardModule,
  AdviceListingModule,
  ArticleListingModule,
  BrandListingModule,
  Carousel,
  CategoriesModule,
  CategoryListingModule,
  CategorySectionModule,
  CenteredRichText,
  ColumnComponent,
  CtaBannerModule,
  CtaGroupModule,
  CustomBlocksModule,
  DealerOverviewModule,
  DocumentDownloadModule,
  EmbeddedContentModule,
  EventListingModule,
  FeaturesSectionModule,
  HeroBanner,
  HeroDuoBanner,
  HeroSectionModule,
  Image,
  ImageAndTextModule,
  InfoBlockModule,
  InfoSectionModule,
  LatestNewsModule,
  MediaModule,
  MosaicSectionModule,
  NewsCard,
  NewsListingModule,
  NewsletterContainer,
  PastEventsModule,
  ProductCardModule,
  ProductCollectionLister,
  ProductListingModule,
  ProductListingTableModule,
  SearchResultsModule,
  ShortBannerModule,
  TableModule,
  UpcomingEventsModule,
  Video,
  VideoModule,
} from '@arvesta-websites/ui-babel';

// TODO - move to storybook
import ContactsModule from '../ContactsModule';

const componentMap: Record<string, any> = {
  AdviceListingSection: AdviceListingModule,
  AdvicesSection: AdviceCardModule,
  ArticleListingSection: ArticleListingModule,
  BrandListing: BrandListingModule,
  CategoryListingSection: CategoryListingModule,
  CategorySection: CategorySectionModule,
  ColumnsComponent: ColumnComponent,
  ContactsSection: ContactsModule,
  CtaGroup: CtaGroupModule,
  CtaSection: CtaBannerModule,
  CustomBlock: NewsCard,
  CustomBlocksSection: CustomBlocksModule,
  DocumentDownloadSection: DocumentDownloadModule,
  EmbeddedContent: EmbeddedContentModule,
  EventsListingSection: EventListingModule,
  FeaturesSection: FeaturesSectionModule,
  FindADealerSection: DealerOverviewModule,
  HeroBanner: HeroBanner,
  HeroDuoBanner: HeroDuoBanner,
  HeroSection: HeroSectionModule,
  Image: Image,
  ImageAndText: ImageAndTextModule,
  ImageCard: NewsCard,
  InfoBlock: InfoBlockModule,
  InfoSection: InfoSectionModule,
  LatestNewsSection: LatestNewsModule,
  ListingManual: Carousel,
  Media: MediaModule,
  MosaicSection: MosaicSectionModule,
  NewsListingSection: NewsListingModule,
  NewsletterSubscription: NewsletterContainer,
  PastEventsSection: PastEventsModule,
  ProductCategoriesSection: CategoriesModule,
  ProductCollectionLister: ProductCollectionLister,
  ProductListingSection: ProductListingModule,
  ProductsListingTableSection: ProductListingTableModule,
  ProductsServicesSection: ProductCardModule,
  SearchResultsSection: SearchResultsModule,
  ShortBanner: ShortBannerModule,
  Table: TableModule,
  Text: CenteredRichText,
  UpcomingEvents: UpcomingEventsModule,
  Video: VideoModule,
};

export default componentMap;
