import richTextToJson from '../../utils/contentful-rich-text-raw-to-json';

const transformCustomBlock = (item: any, locale: string) => {
  return {
    __typename: 'CustomBlock',
    description: item.shortDescription?.text,
    displayCtaCopy: item.displayCtaCopy,
    key: item.id,
    title: item.title,
  };
};

export default transformCustomBlock;
