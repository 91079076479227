import { ContentfulCtaBanner } from '../../__generated__/graphql-types';
import extractCta from '../Cta';

/**
 * @Todo Remove or adjust the restructuring that puts imageWithFocalPoint into an image.asset object
 *
 * The CtaBAnner query no longer fetches and asset data, imageWithFocalPoint is the relevant part now
 * But the components likely still use image.asset.<...> accessors
 *
 * */

const transformCtaSection = (item: ContentfulCtaBanner & { ctaType?: string }) => {
  const type = item.ctaType ?? item.type;
  return {
    __typename: 'CtaSection',
    cta: extractCta(item),
    ctaOneliner: item.ctaOneliner,
    id: item.id,
    image: { ...item.image, asset: { gatsbyImageData: item.image?.imageWithFocalPoint } },
    key: item.id,
    orientation: item?.orientation?.toLowerCase(),
    sectionCategory: null as null,
    shortDescription: item?.description?.raw ? { json: JSON.parse(item.description.raw) } : '',
    title: item.title,
    type,
  };
};

export default transformCtaSection;
