import { type Config, useConfiguration } from '@arvesta-websites/configuration';
import type { BreadcrumbProps, NewsLetterProps } from '@arvesta-websites/ui-babel';
import { IGatsbyImageData, getSrc } from 'gatsby-plugin-image';
import * as React from 'react';

import {
  ContentfulAdvice,
  ContentfulBasicPage,
  ContentfulEvent,
  ContentfulHeroBanner,
  ContentfulImage,
  ContentfulListingManual,
  ContentfulMenuItem,
  ContentfulNews,
  ContentfulProduct,
  ContentfulRichTextSection,
  ContentfulSettings,
  ContentfulSettingsSiteDescriptionTextNode,
  Maybe,
} from '../../__generated__/graphql-types';
import { MainLayout } from '../../ui-lib/components';
import { HEADER_VARIANTS } from '../../ui-lib/components/TopLevelHeader/Header';
import { getIntlSlug } from '../../ui-lib/utils/common';
import richTextRawToJson from '../../utils/contentful-rich-text-raw-to-json';
import handleNewsletterFormSubmission from '../../utils/handleNewsletterFormSubmission';

const dontPrefixPaths = process.env.DONT_PREFIX_PATHS_LOCALE === 'true';

type NavItemType = {
  to: string;
  label: string;
  children?: Array<NavItemType>;
  childrenDivided?: Array<NavItemType>;
  id: string;
  parentId: string;
  pageId: string;
  externalUrl: string;
  createdAt: string;
};

type PageProps = {
  id: number;
  pageTitle?: string;
  pageDescription?: string;
  pageLocale: string;
  pageType: string;
  siteUrl: string;
  pageSlug: string;
  contentfulSettings: ContentfulSettings;
  isHomepage: boolean;
  ogPageType: string;
  pageSettings?: any;
  pageImage?: ContentfulImage;
  swiftTypeDate?: string;
  swiftTypeLocation?: string;
  updatedAt: string;
};

type SubscribeFormProps = {
  showFooterSubscribeForm: boolean;
  showFooterSubscribeFormCountryField: boolean;
  subscribeFormHeading?: any | null;
};

type ImageProps = {
  title?: string;
  altText?: string;
  asset: { gatsbyImageData: IGatsbyImageData };
};

type LayoutProps = {
  pageProps: PageProps;
  children: React.ReactNode;
  subscribeForm: SubscribeFormProps;
  translatedSlugs: Array<ContentfulBasicPage | ContentfulEvent | ContentfulNews | ContentfulAdvice | ContentfulProduct>;
  newsletter: NewsLetterProps;
  heroReference: ContentfulListingManual | ContentfulHeroBanner | ImageProps;
  pageRelatedMenuItems?: string[];
  extraBreadCrumbs?: BreadcrumbProps['crumbs'];
};

const buildCanonicalLink = (extractedPageSettings: any, siteUrl: string, locale: string, slug: string) => {
  const pagePath = dontPrefixPaths ? slug : `${locale}/${slug}`;

  return extractedPageSettings && extractedPageSettings.canonicalUrlpath
    ? extractedPageSettings.canonicalUrlpath
    : `${siteUrl}/${pagePath}`;
};

const buildMenuItem = (item: any, headerVariant: keyof typeof HEADER_VARIANTS, parentId?: string) => {
  const menuItem: NavItemType = {
    children: [],
    childrenDivided: [],
    createdAt: '',
    externalUrl: '',
    id: '',
    label: '',
    pageId: '',
    parentId: '',
    to: '',
  };

  if (item?.title) {
    menuItem.label = item?.title || '';
    menuItem.createdAt = item?.createdAt || '';
    if (item?.externalUrl) {
      menuItem.externalUrl = item.externalUrl;
      if (item.navigationElement?.slug) {
        const { slug } = item.navigationElement;
        menuItem.to = slug === 'homepage' ? '/' : slug;
      }
    } else if (item.navigationElement?.slug) {
      const { slug, id } = item.navigationElement;
      menuItem.to = slug === 'homepage' ? '/' : slug;
      menuItem.pageId = id;
    }
  }

  if (item.id) menuItem.id = item.id;
  if (parentId) menuItem.parentId = parentId;

  return menuItem;
};

const buildMenuNavigation = (
  contentfulNav: Maybe<ContentfulMenuItem>[] | null | undefined,
  headerVariant: keyof typeof HEADER_VARIANTS,
  secondary = false,
  brandx = false,
) => {
  const navigation: Array<NavItemType> =
    contentfulNav?.map(item => {
      const menuItem: NavItemType = buildMenuItem(item, headerVariant);

      if (item?.childrens && secondary) {
        item.childrens.forEach(children => {
          const child: NavItemType = buildMenuItem(children, headerVariant, item.id);
          if (child && menuItem?.children) {
            if (brandx && children?.childrens) {
              children.childrens.forEach(grandChild => {
                const grandChildItem: NavItemType = buildMenuItem(grandChild, headerVariant, children.id);
                if (grandChildItem && child.children) {
                  if (grandChild?.childrens) {
                    grandChild.childrens.forEach(greatGrandChild => {
                      const greatGrandChildItem: NavItemType = buildMenuItem(
                        greatGrandChild,
                        headerVariant,
                        grandChild.id,
                      );
                      if (greatGrandChildItem && grandChildItem.children) {
                        grandChildItem.children.push(greatGrandChildItem);
                      }
                    });
                  }
                  child.children.push(grandChildItem);
                }
              });
            }
            menuItem.children.push(child);
          }
        });
      }

      if (item?.childrenDivided && secondary) {
        item.childrenDivided.forEach(children => {
          const child: NavItemType = buildMenuItem(children, headerVariant, item.id);
          if (child && menuItem?.children) {
            if (brandx && children?.childrens) {
              children.childrens.forEach(grandChild => {
                const grandChildItem: NavItemType = buildMenuItem(grandChild, headerVariant, children.id);
                if (grandChildItem && child.children) {
                  if (grandChild?.childrens) {
                    grandChild.childrens.forEach(greatGrandChild => {
                      const greatGrandChildItem: NavItemType = buildMenuItem(
                        greatGrandChild,
                        headerVariant,
                        grandChild.id,
                      );
                      if (greatGrandChildItem && grandChildItem.children) {
                        grandChildItem.children.push(greatGrandChildItem);
                      }
                    });
                  }
                  child.children.push(grandChildItem);
                }
              });
            }
            menuItem.childrenDivided.push(child);
          }
        });
      }

      return menuItem;
    }) || [];

  return navigation;
};

function isRichText(item: ContentfulMenuItem[] | ContentfulRichTextSection): item is ContentfulRichTextSection {
  return (item as ContentfulRichTextSection)?.richText !== undefined;
}

const buildFooterNavigation = (
  contentfulNav: ContentfulMenuItem[] | ContentfulMenuItem | ContentfulRichTextSection | undefined,
  pageLocale: string,
) => {
  if (!contentfulNav) {
    return undefined;
  }

  if (isRichText(contentfulNav)) {
    return { ...contentfulNav, richText: richTextRawToJson(contentfulNav.richText, pageLocale) };
  }

  const buildFooterNavigationItem = (item: ContentfulMenuItem[]) => {
    return item.map(menuItem => {
      const obj = {
        label: menuItem.title,
        to: '',
      };

      if (menuItem?.externalUrl) {
        obj.to = menuItem.externalUrl;
      } else if (menuItem.navigationElement?.slug) {
        const { slug } = menuItem.navigationElement;
        obj.to = slug === 'homepage' ? '/' : slug;
      }

      return obj;
    });
  };

  if (!Array.isArray(contentfulNav)) {
    return {
      children: buildFooterNavigationItem(contentfulNav.childrens ?? []),
      title: contentfulNav.title,
    };
  }

  return {
    children: buildFooterNavigationItem(contentfulNav[0].childrens ?? []),
    title: contentfulNav[0].title,
  };
};

const extractSiteDescription = (item: any) => (item.text ? item.text : item);

const getPageSettings = (pageSettings: any) => {
  if (!pageSettings) {
    return {};
  }

  const { title, description, nofollow, noindex, canonicalUrlpath, keywords } = pageSettings;

  return {
    canonicalUrlpath,
    description,
    keywords,
    nofollow,
    noindex,
    title,
  };
};

const extractImage = (image: any) => {
  if (!image) {
    return null;
  }

  // if (image && image.socialImage && image.socialImage.fixed && image.socialImage.fixed.src) {
  //   return image.socialImage.fixed.src;
  // }
  if (image?.socialImage?.gatsbyImageData) {
    return getSrc(image?.socialImage?.gatsbyImageData);
  }

  // if (image && image.asset && image.asset.fixed && image.asset.fixed.src) {
  //   return image.asset.fixed.src;
  // }
  if (image?.asset?.gatsbyImageData) {
    return getSrc(image?.asset?.gatsbyImageData);
  }

  return null;
};

const getPageDescription = (
  pageDescription: string | undefined,
  extractedSettings: any,
  siteDescription: ContentfulSettingsSiteDescriptionTextNode | undefined | null,
) => {
  const settingsDescription = extractedSettings?.description || extractedSettings?.legacyDescription || '';

  return (
    pageDescription ||
    (extractedSettings && settingsDescription ? settingsDescription : extractSiteDescription(siteDescription))
  );
};

const getBreadcrumbs = (
  pageRelatedMenuitems: string[],
  headerNavigation: Array<NavItemType>,
  extraBreadCrumbs?: BreadcrumbProps['crumbs'],
): BreadcrumbProps['crumbs'] => {
  const filterNavigationContainsMenuItemId = (navigation: NavItemType, menuItemId: string): boolean =>
    navigation.id === menuItemId ||
    (navigation.children && navigation.children.some(child => filterNavigationContainsMenuItemId(child, menuItemId))) ||
    navigation.childrenDivided?.some(child => filterNavigationContainsMenuItemId(child, menuItemId));

  const iterateNavigation = (navigation: NavItemType, menuItemId: string): NavItemType => {
    if (!filterNavigationContainsMenuItemId(navigation, menuItemId)) {
      return null;
    }
    return {
      ...navigation,
      children: navigation.children?.map(child => iterateNavigation(child, menuItemId)).filter(child => child),
      childrenDivided: navigation.childrenDivided?.map(child => iterateNavigation(child, menuItemId)),
    };
  };

  const menuItemsWithUseInNavigation = pageRelatedMenuitems
    .map(menuItemId =>
      headerNavigation.map(navigation => iterateNavigation(navigation, menuItemId)).filter(navigation => navigation),
    )
    .filter(navigationRelatedToMenuItem => navigationRelatedToMenuItem.length);

  if (menuItemsWithUseInNavigation.length) {
    const getOldestItem = <TItems extends { createdAt?: string }[]>(items: TItems): TItems[number] =>
      items.reduce((oldest, current) => {
        if (!oldest?.createdAt) {
          return current;
        } else if (!current?.createdAt) {
          return oldest ?? {};
        }
        return Date.parse(oldest.createdAt) < Date.parse(current.createdAt) ? oldest : current;
      }, {});

    const constructBreadcrumbOrder = <TCrumbs extends BreadcrumbProps['crumbs']>(
      items: Array<NavItemType>,
      crumbs: TCrumbs = [] as TCrumbs,
    ): TCrumbs => {
      items = items.filter(item => !!item); // filter out any undefineds
      if (!items.length) {
        return crumbs;
      }
      const crumb = getOldestItem(items);
      const itemsToPass = Object.keys(crumb).length ? [...crumb.children, ...crumb.childrenDivided] : [];
      return constructBreadcrumbOrder([...crumb.children, ...crumb.childrenDivided], [
        ...crumbs,
        { label: crumb?.label, to: crumb?.to },
      ] as TCrumbs);
    };

    const oldestNavigationMenuItem = getOldestItem(menuItemsWithUseInNavigation.map(getOldestItem));

    if (Object.keys(oldestNavigationMenuItem).length === 0) {
      return extraBreadCrumbs?.length ? extraBreadCrumbs : [];
    }
    return constructBreadcrumbOrder([oldestNavigationMenuItem], extraBreadCrumbs);
  }

  return extraBreadCrumbs?.length ? extraBreadCrumbs : [];
};

const getSiteData = (
  pageProps: PageProps,
  pageRelatedMenuitems: string[],
  config: Config,
  extraBreadCrumbs: BreadcrumbProps['crumbs'],
) => {
  const {
    id,
    pageTitle,
    pageDescription,
    pageLocale,
    pageType,
    siteUrl,
    pageSlug,
    contentfulSettings,
    isHomepage,
    ogPageType,
    pageSettings,
    pageImage,
    swiftTypeDate,
    swiftTypeLocation,
    updatedAt,
  } = pageProps;
  const { siteDescription, siteImage, headerVariant, siteTitle, navigation, footer } = contentfulSettings;

  const extractedPageSettings = getPageSettings(pageSettings);
  const headerNavigation = buildMenuNavigation(
    navigation.primaryLinks,
    headerVariant as keyof typeof HEADER_VARIANTS,
    true,
    true,
  );

  const socialLinks = footer?.socialLinks?.map(link => {
    return {
      label: link?.title,
      to: link?.url,
    };
  });

  const breadcrumbs =
    config.breadcrumb.display && !pageProps.isHomepage && pageRelatedMenuitems
      ? getBreadcrumbs(pageRelatedMenuitems, headerNavigation, extraBreadCrumbs)
      : extraBreadCrumbs?.length
        ? extraBreadCrumbs
        : [];

  const pageCrumb = { label: pageProps.pageTitle, to: pageProps.pageSlug };
  const sliceEndStop = breadcrumbs?.length === 1 ? 1 : breadcrumbs.length ? -1 : undefined;
  const breadcrumb =
    {
      crumbs: breadcrumbs.length
        ? [...breadcrumbs.slice(0, sliceEndStop), pageCrumb]
        : pageProps.isHomepage
          ? []
          : [pageCrumb],
    } || {};

  const siteData = {
    footer: {
      banner: footer?.banner,
      contacts: (footer?.officeInformation && richTextRawToJson(footer.officeInformation, pageLocale)) || '',
      legalLinks: buildMenuNavigation(footer.secondaryLinks, headerVariant as keyof typeof HEADER_VARIANTS),
      navigation:
        config.footer.version === 'V1'
          ? buildMenuNavigation(footer.primaryLinks, headerVariant as keyof typeof HEADER_VARIANTS)
          : {
              firstColumn: buildFooterNavigation(footer.primaryLinks, pageLocale),
              fourthColumn: buildFooterNavigation(footer?.fourthColumn, pageLocale),
              secondColumn: buildFooterNavigation(footer?.primaryLinksSecondColumn, pageLocale),
              thirdColumn: buildFooterNavigation(footer?.thirdColumn, pageLocale),
            },
      siteLogo: footer?.siteLogo,
      socialLinks,
    },
    header: {
      breadcrumb,
      navigation: headerNavigation,
      ribbonLinks: buildMenuNavigation(navigation.secondaryLinks, headerVariant as keyof typeof HEADER_VARIANTS, false),
    },
    metaData: {
      canonical: isHomepage
        ? `${siteUrl}/${pageLocale}`
        : buildCanonicalLink(extractedPageSettings, siteUrl, pageLocale, pageSlug),
      description: getPageDescription(pageDescription, extractedPageSettings, siteDescription),
      image: pageImage ? extractImage(pageImage) : extractImage(siteImage),
      nofollow: extractedPageSettings && extractedPageSettings.nofollow ? extractedPageSettings.nofollow : undefined,
      noindex: extractedPageSettings && extractedPageSettings.noindex ? extractedPageSettings.noindex : undefined,
      ogPageType,
      pageLocale,
      pageType,
      searchTags: extractedPageSettings?.keywords,
      siteName: siteTitle,
      swiftTypeDate,
      swiftTypeLocation,
      title: pageTitle || (extractedPageSettings && extractedPageSettings.title ? extractedPageSettings.title : null),
      updatedAt,
    },
    pageId: id,
  };
  return siteData;
};

const Layout = ({
  pageProps,
  children,
  subscribeForm,
  translatedSlugs,
  newsletter,
  heroReference,
  pageRelatedMenuItems,
  extraBreadCrumbs,
}: LayoutProps) => {
  const { isHomepage, pageImage, pageLocale } = pageProps;

  const config = useConfiguration();

  const siteData = getSiteData(pageProps, pageRelatedMenuItems, config, extraBreadCrumbs);
  const headerVariant = pageProps.contentfulSettings.headerVariant || '';

  const searchSlug = getIntlSlug(pageProps.contentfulSettings.searchPage?.slug || '');

  const handleSearch = (q: string) => {
    // eslint-disable-next-line no-undef
    if (window) {
      // eslint-disable-next-line no-undef
      window.location.href = `${searchSlug}?q=${q}`;
    }
  };

  let translatedSlugsCopy: Array<
    ContentfulBasicPage | ContentfulEvent | ContentfulNews | ContentfulAdvice | ContentfulProduct
  > = [];

  if (translatedSlugs) {
    translatedSlugsCopy = [...translatedSlugs];

    if (isHomepage) {
      translatedSlugsCopy = translatedSlugsCopy.map(translation => {
        // eslint-disable-next-line no-param-reassign
        translation.slug = '';
        return translation;
      });
    }
  }

  return (
    <MainLayout
      headerSearchFunction={handleSearch}
      headerVariant={headerVariant.toLowerCase()}
      isHomepage={isHomepage}
      newsletter={newsletter}
      onSubscribeFormSubmit={handleNewsletterFormSubmission}
      pageLocale={pageLocale}
      searchPreviewImage={pageImage}
      siteData={siteData}
      subscribeForm={subscribeForm}
      translatedSlugs={translatedSlugsCopy}
      heroReference={heroReference}
    >
      {children}
    </MainLayout>
  );
};

export default Layout;
