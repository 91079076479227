import { ContactsFormDefault as ContactsForm } from '@arvesta-websites/ui-babel';

import {
  StyledContainer,
  StyledFormContainer,
  StyledHeaderWrapper,
  StyledModuleDescription,
  StyledModuleHeading,
} from './Styled';

export type ContactsModuleProps = {
  contactsShortDescription: string;
  contactsTitle: string;
  handleFormSubmit: (name: string, formData: { [key: string]: string }) => void;
  shortDescription: string;
  showContactForm?: boolean;
  showContactsFormSubscribeOptions: boolean;
  title: string;
  basicPageTitle?: string;
  optionalFields?: any[];
  NETLIFY_FORM_NAME?: string;
};

const ContactsModule = ({
  contactsShortDescription,
  contactsTitle,
  handleFormSubmit,
  shortDescription,
  showContactForm = true,
  showContactsFormSubscribeOptions = true,
  title,
  basicPageTitle,
  optionalFields,
  NETLIFY_FORM_NAME,
}: ContactsModuleProps) => (
  <StyledContainer>
    <StyledHeaderWrapper>
      <StyledModuleHeading>{title}</StyledModuleHeading>
      <StyledModuleDescription>{shortDescription}</StyledModuleDescription>
    </StyledHeaderWrapper>

    {showContactForm && (
      <StyledFormContainer>
        <ContactsForm
          handleFormSubmit={handleFormSubmit}
          shortDescription={contactsShortDescription}
          showSubscribeOptions={showContactsFormSubscribeOptions}
          title={contactsTitle}
          basicPageTitle={basicPageTitle}
          NETLIFY_FORM_NAME={NETLIFY_FORM_NAME}
          optionalFields={optionalFields}
        />
      </StyledFormContainer>
    )}
  </StyledContainer>
);

export default ContactsModule;
