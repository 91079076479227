import { User } from '@arvesta-websites/icons';
import { useIntl } from 'react-intl';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useAuth } from '../../../shared/auth/authContext';
import { handleLogin, handleLogout } from '../../../shared/auth/authUtils';
import Dropdown from '../../Dropdown';

interface SignInButtonProps {
  isMobileMenu?: boolean;
}

const SignInButton: React.FC<SignInButtonProps> = ({ isMobileMenu }) => {
  const intl = useIntl();
  const auth = useAuth();

  if (!auth) {
    return null;
  }

  const { state } = auth;

  return (
    <div>
      <AuthenticatedTemplate>
        <Dropdown
          isMobileMenu={isMobileMenu}
          label={state.userState.name || ''}
          items={[
            {
              label: intl.formatMessage({ id: 'profile.label' }),
              to: `/${intl.formatMessage({ id: 'profile.slug' })}`,
            },
            {
              label: intl.formatMessage({ id: 'logout.label' }),
              to: () => handleLogout(intl),
            },
          ]}
        />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div
          onClick={() => handleLogin(intl.locale)}
          className={`cursor-pointer h-8 ${!isMobileMenu ? 'text-white hover:text-header-ribbon-hover hover:underline' : 'mobile-menu-item'} ribonItem no-underline underline-offset-base flex items-center whitespace-nowrap opacity-100`}
        >
          <User className="mr-[5px] h-[17px] w-[17px] fill-current" />
          {intl.formatMessage({ id: 'lockcta.buttonLabel' })}
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default SignInButton;
