import {
  ContentfulDealerV2Connection,
  ContentfulDealerV2Edge,
  ContentfulFindADealerSection,
} from '../../__generated__/graphql-types';
import extractShortDescription from '../ShortDescription';

const transformFindADealerSection = (
  item: ContentfulFindADealerSection,
  dealersConnection: ContentfulDealerV2Connection,
) => {
  return {
    __typename: 'FindADealerSection',
    dealers: dealersConnection.edges.map((edge: ContentfulDealerV2Edge) => edge.node),
    //leave this! that's the CF name for the component
    key: item.id,
    shortDescription: extractShortDescription(item),
    title: item.title,
    titleTag: item.titleTag,
  };
};

export default transformFindADealerSection;
