/* eslint-disable react/require-default-props */

import { LockCta } from '@arvesta-websites/ui-babel';
import {
  CtaBannerModule,
  type CtaBannerModuleProps,
  DocumentDownload,
  type DocumentDownloadProps,
  type ICertification,
  ProductCardModule,
  type ProductCardModuleProps,
  ProductCertificationList,
  RichText,
  type RichTextProps,
  renderCertificationLink,
  renderCertificationListItem,
} from '@arvesta-websites/ui-babel';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

import {
  DocumentDownloadContainer,
  DocumentDownloadContainerMobile,
  StyledAttributes,
  StyledAttributesMobile,
  StyledContainer,
  StyledDescriptionContainer,
  StyledDescriptionText,
  StyledFeaturedCertification,
  StyledFeaturedCertificationMobile,
  StyledHeaderInfo,
  StyledHeading,
  StyledHeadingText,
  StyledImageWrapper,
  StyledLeftColumn,
  StyledMobileHeading,
  StyledRightColumn,
  StyledTextWrapper,
} from './Styled';

export type collection = {
  __typename: string;
  title: string;
  shortDescription?: string;
  cta?: any;
};

interface ProductPageProps {
  title: string;
  image: {
    title: string;
    alt?: string;
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  shortDescription: string;
  contentsSection: RichTextProps;
  attributes?: RichTextProps;
  document?: DocumentDownloadProps;
  additionalDocuments?: DocumentDownloadProps[];
  certification: Array<ICertification>;
  relatedProducts?: ProductCardModuleProps;
  ctaModule?: CtaBannerModuleProps;
  isAuthenticated: boolean;
  isLockedForNonAuthenticatedUsers?: boolean;
}

const renderFirstCertificationItem = (certification: Array<ICertification>) =>
  certification &&
  certification[0] && (
    <StyledFeaturedCertification>
      {certification[0].to
        ? renderCertificationLink(certification[0].imageUrl, certification[0].title, certification[0].to, false)
        : renderCertificationListItem(certification[0].imageUrl, certification[0].title, false)}
    </StyledFeaturedCertification>
  );

const renderFeaturedCertification = (certification: Array<ICertification>) =>
  certification &&
  certification[0] && (
    <StyledFeaturedCertification>{renderFirstCertificationItem(certification)}</StyledFeaturedCertification>
  );

const renderFeaturedCertificationMobile = (certification: Array<ICertification>) =>
  certification &&
  certification[0] && (
    <StyledFeaturedCertificationMobile>{renderFirstCertificationItem(certification)}</StyledFeaturedCertificationMobile>
  );

const ProductPage = ({
  title,
  image,
  shortDescription,
  contentsSection,
  attributes,
  document,
  additionalDocuments,
  certification,
  relatedProducts,
  ctaModule,
  isAuthenticated,
  isLockedForNonAuthenticatedUsers,
}: ProductPageProps) => {
  return (
    <>
      {renderFeaturedCertificationMobile(certification)}
      <StyledContainer id="product-cotnainer">
        <StyledLeftColumn>
          <StyledImageWrapper>
            {image?.asset && (
              // <Img
              //   fluid={image.asset.fluid}
              //   alt={image.alt}
              //   style={{ maxHeight: '500px' }}
              //   imgStyle={{ objectFit: 'contain' }}
              // />
              <GatsbyImage image={image.asset.gatsbyImageData} alt={image.alt ? image.alt : image.title} />
            )}
          </StyledImageWrapper>

          {isAuthenticated || !isLockedForNonAuthenticatedUsers ? (
            <>
              <StyledMobileHeading>
                <StyledHeadingText>{title}</StyledHeadingText>
                <StyledDescriptionText>{shortDescription}</StyledDescriptionText>
              </StyledMobileHeading>
              {attributes && (
                <StyledAttributes>
                  <RichText {...attributes} />
                </StyledAttributes>
              )}
            </>
          ) : null}
        </StyledLeftColumn>
        <StyledRightColumn>
          <StyledDescriptionContainer>
            <StyledHeading>
              <StyledHeaderInfo>
                <StyledHeadingText>{title}</StyledHeadingText>
                <StyledDescriptionText>{shortDescription}</StyledDescriptionText>
              </StyledHeaderInfo>
              {renderFeaturedCertification(certification)}
            </StyledHeading>
            {isAuthenticated || !isLockedForNonAuthenticatedUsers ? (
              <>
                <StyledTextWrapper>
                  <RichText {...contentsSection} />
                </StyledTextWrapper>
                {attributes && (
                  <StyledAttributesMobile>
                    <RichText {...attributes} />
                  </StyledAttributesMobile>
                )}
                {document && (
                  <>
                    <DocumentDownloadContainer>
                      <DocumentDownload {...document} />
                    </DocumentDownloadContainer>
                    <DocumentDownloadContainerMobile>
                      <DocumentDownload {...document} renderMobileVariant />
                    </DocumentDownloadContainerMobile>
                  </>
                )}
                {additionalDocuments &&
                  Array.isArray(additionalDocuments) &&
                  additionalDocuments.map(doc => (
                    <div key={doc.id}>
                      <DocumentDownloadContainer>
                        <DocumentDownload {...doc} />
                      </DocumentDownloadContainer>
                      <DocumentDownloadContainerMobile>
                        <DocumentDownload {...doc} renderMobileVariant />
                      </DocumentDownloadContainerMobile>
                    </div>
                  ))}
                {certification && !!certification.length && <ProductCertificationList certifications={certification} />}
              </>
            ) : null}
          </StyledDescriptionContainer>
        </StyledRightColumn>
      </StyledContainer>
      {isAuthenticated || !isLockedForNonAuthenticatedUsers ? null : <LockCta></LockCta>}
      {relatedProducts && relatedProducts.products && relatedProducts.products.length > 0 ? (
        <ProductCardModule {...relatedProducts} greyVariant />
      ) : null}
      {ctaModule ? <CtaBannerModule {...ctaModule} /> : null}
    </>
  );
};

export default ProductPage;
