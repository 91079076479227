import { BoxProps, CardProps } from 'rebass';

import { Image } from '../../../../types';
import { withErrorBoundary } from '../../ErrorBoundary';

import HorizontalProductCard from './HorizontalProductCard';
import { StyledCard } from './Styled';
import VerticalProductCard from './VerticalProductCard';

export type ctaProps = {
  label?: string;
  link: string;
};

export interface ProductCardProps extends CardProps {
  /** product id */
  id: string;
  /** card title */
  title: string;
  /** optional card description */
  shortDescription?: string | null;
  /** card image */
  image?: Image | any;
  /** card cta */
  link: string;
  /** display link text */
  showLink?: boolean;
  /** Optional product price */
  price?: string;
  /** Optional enquiry CTA */
  enquiryCta?: ctaProps;
  /** Card variant, vertical or horizontal left/right */
  variant?: 'vertical' | 'horizontalLeft' | 'horizontalRight';
  /** Drives logic to align prices on product cards is listing */
  showCommerceSection?: boolean;
  cardCTAText?: string;
  linkToDetailPage?: boolean;
  linkToCustomPage?: {
    title: string;
    internalEntry?: any;
    externalUrl?: string;
  };
  documents?: {
    documentTitle?: string;
    documentType: string | null;
    downloadLink?: string;
  }[];
}

const ProductCard = ({
  id,
  title,
  shortDescription,
  image,
  link,
  showLink = true,
  variant = 'vertical',
  price,
  enquiryCta,
  showCommerceSection,
  cardCTAText,
  linkToDetailPage = true,
  linkToCustomPage,
  documents,
  gated,
  ...rest
}: ProductCardProps) => {
  const cardProps = {
    cardCTAText,
    documents,
    enquiryCta,
    gated,
    id,
    image,
    link,
    linkToCustomPage,
    linkToDetailPage,
    price,
    shortDescription,
    showCommerceSection,
    showLink,
    title,
    variant,
  };

  return (
    <StyledCard {...(rest as BoxProps)} className={variant}>
      {variant === 'vertical' ? <VerticalProductCard {...cardProps} /> : <HorizontalProductCard {...cardProps} />}
    </StyledCard>
  );
};

export default withErrorBoundary(ProductCard, { componentName: 'ProductCard' });
