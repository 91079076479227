import { format as dateFnsFormat } from 'date-fns';
import { de, enGB as en, fr, nl } from 'date-fns/locale';
import { useIntl } from 'react-intl';

const useDate = (dateFormat = 'dd/MM/yyyy') => {
  const intl = useIntl();
  const dateLocales = { de, en, fr, nl };
  const dateLocale = dateLocales[intl.locale] || dateLocales.en;

  const format = (date: Date) => dateFnsFormat(date, dateFormat, { locale: dateLocale });

  return { format };
};

export default useDate;
