/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ContentfulAdvice,
  ContentfulCategory,
  ContentfulImage,
  ContentfulProductCategoriesSection,
  Maybe,
  Scalars,
} from '../__generated__/graphql-types';

import extractCta from './Cta';
import extractShortDescription from './ShortDescription';

export const extractCategories = (categories: Maybe<Array<Maybe<ContentfulCategory>>>, cardLink: any) => {
  if (!categories) {
    return [];
  }
  return categories
    ?.map((item: Maybe<ContentfulCategory>) => {
      if (!item) {
        return null;
      }

      return {
        gated: item.cta?.internalEntry?.gated,
        id: item.id,
        image: item.image,
        link:
          (item?.cta?.internalEntry as ContentfulAdvice)?.slug ||
          item?.cta?.externalUrl ||
          cardLink?.internalEntry?.slug ||
          cardLink?.externalUrl ||
          null,
        shortDescription: extractShortDescription(item),
        title: item?.title,
      };
    })
    .filter(Boolean);
};

type ProductCategoriesSection = ContentfulProductCategoriesSection & {
  image?: Maybe<ContentfulImage> & {
    image1?: Maybe<Scalars['JSON']>;
    image2?: Maybe<Scalars['JSON']>;
  };
};

const transformProductCategoriesSection = (item: ProductCategoriesSection) => {
  const data = {
    __typename: 'ProductCategoriesSection',
    categories:
      item?.categories && Array.isArray(item.categories) ? extractCategories(item.categories, item.cardlink) : [],
    cta: extractCta(item),
    image: {
      alt: item.image?.altText || item.title,
      asset: {
        gatsbyImageData1: item.image?.image1,
        gatsbyImageData2: item.image?.image2,
      },
      title: item.image?.title,
    },
    key: item.id,
    shortDescription: extractShortDescription(item),
    title: item.title,
    variant: item.variant ? item.variant : 'primary',
  };

  if (item?.variant === 'tertiary') {
    const image1 = item?.image?.image1 || null;
    const image2 = item?.image?.image2 || null;
    const imageSources = {
      alt: item.image?.altText || item.title,
      sources: [
        null,
        // Add alt explicitely to use with withArtDirection function
        { ...image1, alt: item.image?.altText || item.image?.title || '' },
        { ...image2, alt: item.image?.altText || item.image?.title || '' },
      ],
    };

    // @ts-ignore
    data.image = imageSources;
  }

  return data;
};

export default transformProductCategoriesSection;
