import { config } from './authConfig';

export const getTokensFromQuery = () => {
  const hash = window.location.hash.substring(1);
  if (!hash) return null;

  const params = new URLSearchParams(hash);
  const tokens = {
    access_token: params.get('access_token'),
    error: params.get('error'),
    state: params.get('state'),
  };

  if (tokens.state) {
    const localState = localStorage.getItem('state');
    window.history.replaceState({}, document.title, window.location.pathname);
    localStorage.removeItem('state');

    if (localState !== tokens.state) {
      tokens.error = 'Invalid state';
    }
  }

  if (tokens.access_token || tokens.error) {
    return tokens;
  }

  return null;
};

// Handle login by redirecting to the authorization server
export const handleLogin = (locale: string, prompt = 'login') => {
  const redirectUrl = buildLoginUrl(prompt, locale);
  window.location.href = redirectUrl;
};

export const handleLogout = (intl: any) => {
  // Remove the access token from the browser's local storage.
  localStorage.removeItem('accessToken');
  localStorage.removeItem('localPreferences');

  const redirectUrl = buildLogoutUrl(intl);
  window.location.href = redirectUrl;
};

// Build the authorization URL
const buildLoginUrl = (prompt = 'login', locale: string, iframe = false) => {
  const state = generateRandomString();
  localStorage.setItem('state', state);

  // Construct the authorization URL
  let url =
    `${config.authorization_base_endpoint}` +
    `${config.login_endpoint}` +
    `/${config.login_endpoint}` +
    `?client_id=${encodeURIComponent(config.client_id)}` +
    `&response_type=id_token+token` +
    `&response_mode=fragment` +
    `&scope=${encodeURIComponent(config.requested_scopes)}` +
    `&state=${encodeURIComponent(state)}` +
    `&nonce=default` +
    `&prompt=${prompt}`;

  if (iframe) {
    url += `&redirect_uri=${encodeURIComponent(window.location.href + '?iframe=true')}`;
  } else {
    url += `&redirect_uri=${encodeURIComponent(window.location.href)}`;
  }

  if (locale) {
    url += `&ui_locale=${locale}`;
  }

  return url;
};

export const buildPasswordChangeLink = (locale: string) => {
  let url = buildLoginUrl('none', locale);
  url += `&flow=password`;
  return url;
};

const buildLogoutUrl = (intl: any) => {
  // Generate and store a random "state" value for CSRF protection
  const state = generateRandomString();

  localStorage.setItem('state', state);

  const hasMultipleLocale = process.env.ENABLED_LOCALES && process.env.ENABLED_LOCALES.split(',').length > 1;
  let logoutRedirectUrl = window.location.href;

  if (window.location.href.includes(`/${intl.formatMessage({ id: 'profile.slug' })}`)) {
    logoutRedirectUrl = window.location.origin;
    if (hasMultipleLocale) {
      logoutRedirectUrl += `/${intl.locale}`;
    }
  }

  // Construct the authorization URL
  const url =
    `${config.authorization_base_endpoint}` +
    `${config.login_endpoint}` +
    `/logout` +
    `?post_logout_redirect_uri=${encodeURIComponent(logoutRedirectUrl)}` +
    `&state=${encodeURIComponent(state)}`;

  return url;
};

// Generate a secure random string using the browser crypto functions
const generateRandomString = () => {
  const array = new Uint32Array(28);
  window.crypto.getRandomValues(array);

  // Convert to a hex string
  return Array.from(array, dec => ('0' + dec.toString(16)).slice(-2)).join('');
};

export const silentRefresh = () => {
  return new Promise((resolve, reject) => {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = buildLoginUrl('none', '', true); // Authorization URL with `prompt=none`

    const messageHandler = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      const tokenData = new URLSearchParams(event.data.substring(1));
      if (tokenData.get('access_token')) {
        resolve(tokenData.get('access_token'));
      } else {
        resolve('error');
      }

      window.removeEventListener('message', messageHandler);
      document.body.removeChild(iframe);
    };

    window.addEventListener('message', messageHandler);

    iframe.onerror = () => {
      resolve('error');
      window.removeEventListener('message', messageHandler);
      document.body.removeChild(iframe);
    };

    // Append the iframe to the document
    document.body.appendChild(iframe);
  });
};
