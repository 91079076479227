/* eslint-disable react/no-unused-prop-types */

import { LockCta } from '@arvesta-websites/ui-babel';
import { NewsLetterProps } from '@arvesta-websites/ui-babel';
import React from 'react';

import PageItems from '../../Modules/PageItems';

export type collection = {
  __typename: string;
  id?: string;
  title: string;
  shortDescription?: string;
  cta?: any;
};

interface BasicPageProps {
  title?: string;
  slug?: string;
  contentsSectionCollection: {
    items: Array<any>;
  };
  image?: any;
  newsletter?: NewsLetterProps;
  isAuthenticated: boolean;
  isLockedForNonAuthenticatedUsers?: boolean;
}

const BasicPage = ({
  contentsSectionCollection,
  isAuthenticated,
  isLockedForNonAuthenticatedUsers,
}: BasicPageProps) => {
  return isAuthenticated || !isLockedForNonAuthenticatedUsers ? (
    <PageItems content={contentsSectionCollection} />
  ) : (
    <LockCta></LockCta>
  );
};

export default BasicPage;
