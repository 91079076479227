import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';

export const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${Media({
    margin: ['2rem 0rem', null, '2rem 10%', '4rem auto'],
    maxWidth: [null, null, null, '660px'],
  })}
`;

export const StyledFormContainer = styled(Flex)`
  width: 100%;
`;

export const StyledHeaderWrapper = styled(Flex)`
  flex-direction: column;
  gap: 18px;
`;

export const StyledModuleDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.earthyGreen};
  font-weight: ${props => props.theme.contacts.titleFontWeight || 'normal'};
  text-align: center;

  ${Media({
    fontSize: ['1rem', null, null, '18px'],
    lineHeight: ['24px', null, null, '26px'],
  })}
`;

export const StyledModuleHeading = styled(Text)`
  word-break: break-word;
  font-family: ${props => props.theme.fonts.heading};
  font-weight: ${props => props.theme.contacts.titleFontWeight || 'normal'};
  color: ${({ theme }) => theme.colors.earthyGreen};
  text-align: center;

  ${Media({
    fontSize: ['21px', null, '32px', '40px'],
    lineHeight: ['29px', null, '40px', '48px'],
  })}
`;
