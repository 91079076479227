import { getSrc } from 'gatsby-plugin-image';

import { ContentfulAdvice, ContentfulAdvicesSection } from '../../__generated__/graphql-types';
import extractShortDescription, { ItemType } from '../ShortDescription';

const extractAdvice = (advices: Array<ContentfulAdvice>) => {
  return advices.map((advice: ContentfulAdvice) => {
    return {
      id: advice.id,
      image: getSrc(advice.image?.imageWithFocalPoint),
      link: advice.slug,
      shortDescription: extractShortDescription(advice as ItemType),
      slug: advice.slug,
      title: advice.title,
    };
  });
};

export const transformAdvicesSection = (item: ContentfulAdvicesSection) => {
  return {
    __typename: 'AdvicesSection',
    advices: extractAdvice((item?.advices || []) as ContentfulAdvice[]),
    cta: item.cta
      ? {
          label: item.cta?.title,
          to: item.cta?.internalEntry ? item.cta.internalEntry.slug : item.cta.externalUrl,
        }
      : null,
    key: item.id,
    shortDescription: extractShortDescription(item),
    title: item.title,
  };
};

export default transformAdvicesSection;
