interface Props {
  className?: string;
}
const UserShield = ({ className }: Props) => (
  <svg viewBox="0 0 59 48" className={className}>
    <path d="M57.0977 25.8438C58.1074 26.2109 58.75 27.1289 58.75 28.0469C58.75 39.5215 51.2227 45.8555 46.541 47.5996C46.082 47.7832 45.3477 47.9668 44.4297 47.5996C38.5547 45.3047 32.3125 38.2363 32.3125 28.0469C32.3125 27.1289 32.9551 26.2109 33.873 25.8438L44.4297 21.7129C45.3477 21.3457 46.1738 21.5293 46.541 21.7129L57.0977 25.8438ZM45.5312 43.377C48.6523 41.8164 53.7012 37.4102 54.252 29.4238L45.5312 26.0273V43.377ZM20.5625 24.375C14.0449 24.375 8.8125 19.1426 8.8125 12.625C8.8125 6.19922 14.0449 0.875 20.5625 0.875C26.9883 0.875 32.3125 6.19922 32.3125 12.625C32.3125 19.1426 26.9883 24.375 20.5625 24.375ZM20.5625 5.28125C16.4316 5.28125 13.2188 8.58594 13.2188 12.625C13.2188 16.7559 16.4316 19.9688 20.5625 19.9688C24.6016 19.9688 27.9062 16.7559 27.9062 12.625C27.9062 8.58594 24.6016 5.28125 20.5625 5.28125ZM38.7383 47.7832C37.2695 46.5898 35.8008 45.2129 34.5156 43.4688H4.49805C5.14062 37.6855 10.0977 33.1875 16.1562 33.1875H24.9688C26.8047 33.1875 28.5488 33.7383 30.1094 34.4727C29.7422 32.9121 29.4668 31.2598 29.375 29.4238C27.998 29.0566 26.4375 28.7812 24.9688 28.7812H16.1562C7.16016 28.7812 0 36.0332 0 44.9375C0 46.5898 1.28516 47.875 2.9375 47.875H38.1875C38.3711 47.875 38.5547 47.875 38.7383 47.7832Z" />
  </svg>
);

export default UserShield;
