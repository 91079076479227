import { ContentfulNewsEdge } from '../../__generated__/graphql-types';
import extractCta from '../Cta';

const transformCtaGroup = (data: Array<ContentfulNewsEdge>) => {
  return {
    __typename: 'CtaGroup',
    items: data?.callToAction
      .map((edge: any) => extractCta({ cta: edge }))
      .filter(Boolean)
      .slice(0),
    title: data?.title,
  };
};

export default transformCtaGroup;
