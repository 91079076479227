import { UserShield } from '@arvesta-websites/icons';
import {
  AuthenticatedTemplate,
  Button,
  Heading,
  ProfileForm,
  RichText,
  handleLogin,
  useAuth,
} from '@arvesta-websites/ui-babel';
import { graphql } from 'gatsby';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';

import Layout from '../components/layout';
import richTextRawToJson from '../utils/contentful-rich-text-raw-to-json';

const styling = tv({
  slots: {
    checkboxCheck: 'absolute ml-[2px] hidden pointer-events-none newsletter-checkbox-check',
    formContainer: 'w-full',
    infoPanel: 'w-full md:max-w-[450px] bg-tertiary p-[40px] flex flex-col gap-[40px]',
    infoPanelHeading: 'text-primary',
    pageContainer: 'flex flex-col items-start md:flex-row gap-[150px] mt-[64px]',
    userShieldIcon: 'w-[50px] h-[50px] fill-current text-primary',
  },
});

const getTranslatedSlugs = () => {
  //for the 404 page there are no separate contentpages, we assume that there should be a 404 page per enabled locale
  const localeToSlug = {
    de: 'profil',
    en: 'profile',
    es: 'perfil',
    fr: 'profil',
    nl: 'profiel',
    pl: 'profil',
    pt: 'perfil',
    // Add other locales and their respective slugs here
  };

  const enabledLocales = process.env.ENABLED_LOCALES.split(',');
  if (enabledLocales?.length === 1 && enabledLocales[0] === '') return [];

  const translatedSlugs = enabledLocales.map(enabledLocale => ({
    node_locale: enabledLocale,
    slug: localeToSlug[enabledLocale] || 'profile',
  }));

  return translatedSlugs;
};

const Profile = ({ data, pageContext }) => {
  const { pageContainer, infoPanel, formContainer, userShieldIcon, infoPanelHeading } = styling();
  const intl = useIntl();
  const auth = useAuth();

  const { state } = auth || {};

  useEffect(() => {
    if (!auth) {
      return;
    }
    // Ensure authentication before rendering the profile page
    if (state.status !== 'loading' && state.isAuthenticated === false) {
      handleLogin(intl.locale);
    }
  }, [state, intl.locale]);

  if (!auth) {
    return null;
  }

  const pageProps = {
    contentfulSettings: data.contentfulSettings,
    isHomepage: false,
    pageLocale: pageContext.node_locale,
    pageSlug: 'profile',
    pageTitle: intl.formatMessage({ defaultMessage: 'profile', id: 'profile.label' }),
    pageType: '',
    siteUrl: data.site.siteMetadata.siteUrl,
    updatedAt: '',
  };

  const profilePageDescription = {
    data: pageProps.contentfulSettings.profilePageDescription
      ? richTextRawToJson(pageProps.contentfulSettings.profilePageDescription, pageContext.node_locale)?.json
      : null,
  };

  const profilePageInfoPanelDescription = {
    data: pageProps.contentfulSettings.profilePageInfoPanelDescription
      ? richTextRawToJson(pageProps.contentfulSettings.profilePageInfoPanelDescription, pageContext.node_locale)?.json
      : null,
  };

  console.log(pageProps.contentfulSettings);

  return (
    <Layout pageProps={pageProps} translatedSlugs={getTranslatedSlugs()}>
      <div className={pageContainer()}>
        <div className={formContainer()}>
          <Heading className="m-0" tag="h1" variant="h2">
            {pageProps.contentfulSettings.profilePageTitle}
          </Heading>
          <AuthenticatedTemplate>
            <ProfileForm
              contentfulSettings={pageProps.contentfulSettings}
              profilePageDescription={profilePageDescription}
            />
          </AuthenticatedTemplate>
        </div>
        <div className={infoPanel()}>
          <UserShield className={userShieldIcon()} />
          {pageProps.contentfulSettings.profilePageInfoPanelTitle && (
            <Heading tag="h2" variant="h3" className={infoPanelHeading()}>
              {pageProps.contentfulSettings.profilePageInfoPanelTitle}
            </Heading>
          )}
          <RichText {...profilePageInfoPanelDescription} />
        </div>
      </div>
    </Layout>
  );
};

export default Profile;

export const pageQuery = graphql`
  query ($node_locale: String!, $brand_tag: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulSettings(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      ...QuerySiteSettings
    }
  }
`;
