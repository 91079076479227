import { LockCta } from '@arvesta-websites/ui-babel';
import React from 'react';

import { useDate } from '../../../hooks';
import { ReturnCategory, createTagLinksPairs } from '../../../utils/categories';
import PageItems from '../../Modules/PageItems';

import {
  ArticleDetailWrapper,
  CategoriesWrapper,
  Description,
  IntroductionWrapper,
  StyledCta,
  SubtitleSection,
  Title,
} from './Styled';

type CategoryLink = {
  id: string;
  to: string;
  label: string;
};

export interface ArticleDetailPageProps {
  title: string;
  description: string;
  date?: string;
  mainCategories: CategoryLink[];
  contentsSectionCollection: {
    items: Array<any>;
  };
  isAuthenticated: boolean;
  isLockedForNonAuthenticatedUsers?: boolean;
  showDate?: boolean;
  subcategories: CategoryLink[];
}

const TagLinks = ({ tagLinksPairs }: { tagLinksPairs: ReturnCategory[][] }) => {
  return (
    <>
      {tagLinksPairs.map((tagLinkPair, index) => (
        <span className="bg-gray-200 px-2 py-1" key={`${tagLinkPair[0].id}-${index}`}>
          <span className="text-sm font-bold">{tagLinkPair[0].label}</span>
          {tagLinkPair[1] && (
            <>
              {' > '}
              <span className="text-sm">{tagLinkPair[1].label}</span>
            </>
          )}
        </span>
      ))}
    </>
  );
};

const ArticleDetailPage = ({
  date,
  description,
  title,
  mainCategories,
  subcategories,
  contentsSectionCollection,
  isAuthenticated,
  isLockedForNonAuthenticatedUsers,
  showDate,
}: ArticleDetailPageProps) => {
  const { format } = useDate();

  const tagLinks = createTagLinksPairs(mainCategories, subcategories);

  return (
    <ArticleDetailWrapper>
      <IntroductionWrapper>
        <Title>{title}</Title>
        <SubtitleSection>
          {showDate && <span>{format(new Date(date))}</span>}
          {!!tagLinks?.length && <TagLinks tagLinksPairs={tagLinks} />}
        </SubtitleSection>
        <Description>{description}</Description>
      </IntroductionWrapper>
      {isAuthenticated || !isLockedForNonAuthenticatedUsers ? (
        <PageItems content={contentsSectionCollection} />
      ) : (
        <LockCta></LockCta>
      )}
    </ArticleDetailWrapper>
  );
};

export default ArticleDetailPage;
