import { type LocaleType } from '@arvesta-websites/i18n';
import { Hamburger as HamburgerIcon, SearchIcon } from '@arvesta-websites/icons';
import { withErrorBoundary } from '@arvesta-websites/ui-babel';
import * as React from 'react';
import { FlexProps } from 'rebass';

import { useScrollDirection } from '../../utils';
import { LocationContext } from '../Layout/Location';

import { LangSwitcher } from './LangSwitcher';
import Logo from './Logo';
import matchUrl from './matchUrl';
import { MobileSearch } from './MobileSearch';
import PrimaryNavigation from './PrimaryNavigation';
import { Search } from './Search';
import SecondaryNavigation from './SecondaryNavigation';
import SecondaryNavigationMobile from './SecondaryNavigationMobile';
import {
  StyledHamburgerMenu,
  StyledHeader,
  StyledHeaderBottom,
  StyledHeaderInnerContainer,
  StyledHeaderTop,
  StyledHeaderTopSecondaryVariant,
  StyledHeaderTopSecondaryVariantInner,
  StyledMobileIcons,
  StyledMobileNavigation,
  StyledRightSection,
  StyledSearchMenu,
  StyledSeparator,
} from './Styled';
import { NavItemType } from './types';

interface Props extends FlexProps {
  siteName: string;
  navigation: Array<NavItemType>;
  defaultLocale: LocaleType;
  activeLocales: Array<string>;
  searchFunction: (searchTerm: string) => void;
  variant?: string;
  isHomepage: boolean;
  pageId: string;
}

export const HEADER_VARIANTS = {
  default: 'default',
  secondary: 'display search & language selector in separate bar',
  topLevel: 'display top-level navigation',
};

const renderTopLevelSecondaryNav = (navigation: NavItemType[], currentPageUrl: string, pageId: string) => {
  let currentPage = navigation.find(item => {
    return item.pageId === pageId;
  });

  if (!currentPage) {
    currentPage = navigation
      .map(item => item.children)
      .flat()
      .find(item => item.pageId === pageId);
  }

  let topLevelNavItem = navigation.find(item => item.children.includes(currentPage));

  if (!topLevelNavItem) {
    topLevelNavItem = navigation.find(item => {
      return item.pageId === pageId;
    });
  }

  if (topLevelNavItem) {
    return (
      <StyledHeaderBottom>
        <SecondaryNavigation items={topLevelNavItem.children} activeMenu={currentPageUrl} />
      </StyledHeaderBottom>
    );
  }

  return null;
};

// Could maybe tidy this up
const renderSecondaryMobileNavigation = (
  isVisible: boolean,
  menuItems: Array<NavItemType>,
  activeMenuUrl: string,
  closeNav: () => void,
  defaultLocale: LocaleType,
  activeLocales: Array<string>,
) => {
  if (isVisible) {
    return (
      <StyledMobileNavigation>
        <SecondaryNavigationMobile
          items={menuItems}
          activeMenuUrl={activeMenuUrl}
          onClose={() => {
            closeNav();
          }}
          defaultLocale={defaultLocale}
          activeLocales={activeLocales}
        />
      </StyledMobileNavigation>
    );
  }

  return null;
};

const Header = ({
  siteName,
  navigation,
  defaultLocale,
  activeLocales,
  searchFunction,
  variant,
  isHomepage,
  pageId,
}: Props) => {
  const currentLocation = React.useContext(LocationContext);
  const [currentPageUrl] = React.useState(currentLocation.pathname);
  const [visible, setVisible] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);
  const [currentHoverItem, setCurrentHoverItem] = React.useState({} as NavItemType);
  const [, setPreviousHoverItem] = React.useState({} as NavItemType);
  const [mobileSecondaryMenuVisible, setMobileSecondaryMenuVisible] = React.useState(false);
  const [mobileSearchVisible, setMobileSearchVisible] = React.useState(false);
  const direction = useScrollDirection({ initialDirection: 'up', off: false, thresholdPixels: 50 });

  React.useEffect(() => {
    if (loaded) {
      setVisible(direction === 'up');
    }
    setLoaded(true);
  }, [direction]);

  const activeMenuItem = navigation.find(navItem => {
    if (!currentPageUrl) {
      return false;
    }
    return matchUrl(currentPageUrl, navItem);
  });

  React.useEffect(() => {
    if (document?.getElementById('main-wrapper')) {
      if (!isHomepage && renderTopLevelSecondaryNav(navigation, currentPageUrl, pageId) && window.document) {
        const mainElement: HTMLElement | null = document.getElementById('main-wrapper') || null;
        mainElement && mainElement.classList.add('active-secondary-menu'); // eslint-disable-line no-unused-expressions
      }
    }
  }, [currentPageUrl, isHomepage, navigation, pageId]);

  React.useEffect(() => {
    setPreviousHoverItem(currentHoverItem);
  }, [setPreviousHoverItem, currentHoverItem]);

  const secondary = (activeMenuItem && activeMenuItem.children) || null;
  const classes = [];

  const handleNavItemHover = (navItem: NavItemType) => {
    if (navItem.children && navItem.children.length > 0) {
      setCurrentHoverItem(navItem);
    }

    if (!navItem.children) {
      setCurrentHoverItem({} as NavItemType);
    }
  };

  if (secondary) {
    classes.push('has-secondary');
  }

  if (loaded && !visible) {
    classes.push('is-hidden');
  }

  return (
    <>
      <StyledHeader className={classes.join(' ')}>
        {variant === HEADER_VARIANTS.secondary && (
          <StyledHeaderTopSecondaryVariant>
            <StyledHeaderTopSecondaryVariantInner>
              <Search
                searchFunction={searchTerm => {
                  searchFunction(searchTerm);
                }}
                variant={variant}
              />
              <LangSwitcher defaultLocale={defaultLocale.id} activeLocales={activeLocales} variant={variant} />
            </StyledHeaderTopSecondaryVariantInner>
          </StyledHeaderTopSecondaryVariant>
        )}
        <StyledHeaderInnerContainer>
          <StyledHeaderTop>
            {mobileSearchVisible ? (
              <MobileSearch
                searchFunction={searchTerm => {
                  searchFunction(searchTerm);
                }}
                onClose={() => setMobileSearchVisible(false)}
              />
            ) : (
              <>
                <Logo defaultLocale={defaultLocale} siteName={siteName} variant={variant} />
                <PrimaryNavigation
                  currentHoverItem={currentHoverItem}
                  currentPageUrl={currentPageUrl}
                  items={navigation}
                  activeMenuItem={activeMenuItem || null}
                  onMouseEnter={handleNavItemHover}
                  variant={variant}
                  isHomepage={isHomepage}
                  pageId={pageId}
                />
                {variant !== HEADER_VARIANTS.secondary && (
                  <StyledRightSection>
                    <Search
                      searchFunction={searchTerm => {
                        searchFunction(searchTerm);
                      }}
                    />
                    <LangSwitcher defaultLocale={defaultLocale.id} activeLocales={activeLocales} />
                  </StyledRightSection>
                )}
                <StyledMobileIcons>
                  <StyledSearchMenu>
                    <span
                      onClick={() => setMobileSearchVisible(!mobileSecondaryMenuVisible)}
                      onKeyPress={() => setMobileSearchVisible(!mobileSecondaryMenuVisible)}
                      role="button"
                      tabIndex={0}
                    >
                      <SearchIcon />
                    </span>
                  </StyledSearchMenu>
                  <StyledHamburgerMenu>
                    <span
                      onClick={() => setMobileSecondaryMenuVisible(!mobileSecondaryMenuVisible)}
                      onKeyPress={() => setMobileSecondaryMenuVisible(!mobileSecondaryMenuVisible)}
                      role="button"
                      tabIndex={0}
                    >
                      <HamburgerIcon />
                    </span>
                  </StyledHamburgerMenu>
                </StyledMobileIcons>
              </>
            )}
          </StyledHeaderTop>
        </StyledHeaderInnerContainer>
        <StyledSeparator />
        {!isHomepage && renderTopLevelSecondaryNav(navigation, currentPageUrl, pageId)}
      </StyledHeader>
      {renderSecondaryMobileNavigation(
        mobileSecondaryMenuVisible,
        navigation,
        currentPageUrl,
        setMobileSecondaryMenuVisible as () => void,
        defaultLocale,
        activeLocales,
      )}
    </>
  );
};

export default withErrorBoundary(Header, { componentName: 'TopLevelHeader' });
