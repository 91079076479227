import { Cta } from '@arvesta-websites/ui-babel';
import styled from 'styled-components';

import Media from '../../../utils/Media';

export const ArticleDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 40px;

  ${Media({
    paddingTop: ['32px', null, '30px', '40px'],
  })}
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

export const Description = styled.span`
  font-weight: bold;
`;

export const IntroductionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  * {
    color: ${({ theme }) => theme.articleDetail.introduction};
  }

  ${Media({
    marginBottom: ['0', '0', '-30px', '-30px'],
  })}
`;

export const StyledCta = styled(Cta)`
  ${({ theme }) => theme.articleDetail.tags};
  text-decoration: none;
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  font-size: 12px;
`;

export const SubtitleSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

export const Title = styled.h1`
  font-weight: 700;
  color: ${({ theme }) => theme.articleDetail.title};
  font-family: ${props => props.theme.fonts.heading};
  overflow: hidden;
  margin: 0;

  ${Media({
    fontSize: ['1.75rem', '1.75rem', '2rem', '2.5rem'],
    lineHeight: ['2.625rem', '2.625rem', '3rem', '3.75rem'],
  })}
`;
