import richTextToJson from '../../utils/contentful-rich-text-raw-to-json';
import extractCta from '../Cta';

const transformInfoBlock = (item: any, locale: string) => {
  return {
    __typename: 'InfoBlock',
    cta: extractCta(item),
    description: item?.richText ? richTextToJson(item?.richText, locale).json : null,
    title: item.title,
    titleTag: item.titleTag,
  };
};

export default transformInfoBlock;
