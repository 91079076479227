import extractCta from '../Cta';

const transformHeroDuoBanner = (item: any) => {
  return {
    __typename: 'HeroDuoBanner',
    bannerContent: item.bannerContent,
    desktopImage: { ...item.desktopImage, asset: { gatsbyImageData: item.desktopImage?.imageWithFocalPoint } },
    key: item.id,
  };
};

export default transformHeroDuoBanner;
