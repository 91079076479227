import { GatsbyImage } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';

import { useAuth } from '../../../shared/auth/authContext';
import Button from '../../Button';
import Cta, { CtaProps } from '../../Cta';
import { fileTypeIconMap } from '../../DocumentDownload/DocumentDownload';
import { withErrorBoundary } from '../../ErrorBoundary';
import Lock from '../../Lock/Lock';

import { ProductCardProps } from './ProductCard';
import {
  StyledCommercePrice,
  StyledCommerceWrapper,
  StyledContentBox,
  StyledContentWrapper,
  StyledCta,
  StyledCtaWrapper,
  StyledDescription,
  StyledImageBoxLink,
  StyledImageBoxLinkWrapper,
  StyledTitle,
} from './Styled';

const styling = tv({
  slots: {
    imageWrapper: 'relative',
  },
});

const VerticalProductCard = ({
  title,
  shortDescription,
  image,
  link,
  showLink,
  price,
  enquiryCta,
  showCommerceSection,
  cardCTAText,
  linkToDetailPage,
  linkToCustomPage,
  documents = [],
  gated,
}: ProductCardProps) => {
  const intl = useIntl();
  const showCta = (showLink && linkToDetailPage) || linkToCustomPage;
  const showDocuments = !linkToDetailPage && !linkToCustomPage && !!documents.length;
  const imageBoxProps: Pick<CtaProps, 'to'> = {};
  if (linkToDetailPage) {
    imageBoxProps.to = link;
  }
  let linkTo = link;

  if (linkToCustomPage) {
    imageBoxProps.to = linkToCustomPage.internalEntry
      ? linkToCustomPage.internalEntry.slug
      : linkToCustomPage.externalUrl;

    linkTo = linkToCustomPage.internalEntry ? linkToCustomPage.internalEntry.slug : linkToCustomPage.externalUrl;
  }

  const gatedContentAvailable = process.env.GATED_CONTENT && process.env.GATED_CONTENT === 'true';
  const contentfulGated = gated;

  const isLockedForNonAuthenticatedUsers = gatedContentAvailable && contentfulGated;
  const auth = useAuth();
  if (!auth) {
    return null;
  }
  const { state } = auth;
  const { imageWrapper } = styling();

  return (
    <StyledContentWrapper>
      <StyledImageBoxLinkWrapper>
        <StyledImageBoxLink {...imageBoxProps} className={imageWrapper()}>
          {image && image.asset && (
            // <Img
            //   fluid={image.asset.fluid}
            //   alt={image.alt}
            //   style={{
            //     width: '80%',
            //     maxHeight: '80%',
            //     position: 'inherit',
            //     margin: '0 auto',
            //   }}
            //   imgStyle={{ objectFit: 'contain' }}
            //           />
            // TODO double check rendering
            <GatsbyImage
              alt={image.alt ? image.alt : image.title}
              image={image.asset.gatsbyImageData}
              objectFit="contain"
              style={{
                margin: '0 auto',
                maxHeight: '80%',
                position: 'inherit',
                width: '80%',
              }}
            />
          )}
          {isLockedForNonAuthenticatedUsers && !state.isAuthenticated && <Lock variant="centered" />}
        </StyledImageBoxLink>
      </StyledImageBoxLinkWrapper>

      <StyledContentBox>
        <StyledTitle>{linkToDetailPage ? <Cta to={linkTo}>{title}</Cta> : title}</StyledTitle>
        <StyledDescription>{shortDescription}</StyledDescription>
        {showCta && (
          <StyledCtaWrapper>
            <StyledCta to={linkTo}>
              <span>{cardCTAText || intl.formatMessage({ id: 'cards.product.link' })}</span>
            </StyledCta>
          </StyledCtaWrapper>
        )}

        {showDocuments && (
          <StyledCtaWrapper>
            {documents.map(({ documentTitle, documentType, downloadLink }) => (
              <StyledCta key={documentTitle} target="_blank" to={downloadLink}>
                {documentType && fileTypeIconMap[documentType]}
                <span>{cardCTAText || documentTitle}</span>
              </StyledCta>
            ))}
          </StyledCtaWrapper>
        )}

        {!showCta && !showDocuments && <StyledCtaWrapper />}

        {price || enquiryCta || showCommerceSection ? (
          <StyledCommerceWrapper>
            {price ? <StyledCommercePrice>{price}</StyledCommercePrice> : null}

            {enquiryCta ? (
              <Cta to={enquiryCta.link}>
                <Button>{enquiryCta.label}</Button>
              </Cta>
            ) : null}
          </StyledCommerceWrapper>
        ) : null}
      </StyledContentBox>
    </StyledContentWrapper>
  );
};

export default withErrorBoundary(VerticalProductCard, { componentName: 'VerticalProductCard' });
