const transformFeaturesSection = (item: any) => {
  return {
    ...item,
    __typename: item?.sectionType === 'Features' ? 'FeaturesSection' : 'CategorySection',
    description: item?.description?.text,
    key: item.id,
  };
};

export default transformFeaturesSection;
