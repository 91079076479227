import { Fragment } from 'react';
import { BoxProps } from 'rebass/styled-components';

import { Button, type CategoryCardProps, Cta, withErrorBoundary } from '../../components';
import { StyledModuleContainer } from '../../shared';

import {
  StyledBlueBox,
  StyledCardContainer,
  StyledCategoryCard,
  StyledCenteredModuleTitle,
  StyledModuleCtaContainer,
  StyledModuleDescription,
  StyledModuleHeading,
  StyledModuleHeadingNoDescription,
} from './Styled';
import { type ImageProps, TertiaryCategories } from './TertiaryCategories';

type CtaProps = {
  to: string;
  label: string;
};

export type CategoriesModuleProps = BoxProps & {
  /** title */
  title: string;
  /** optional card short description */
  shortDescription?: string | null;
  /** categories */
  categories: CategoryCardProps[];
  /** card cta */
  cta?: CtaProps | null;
  /** optional variant (primary/secondary/tertiary) */
  variant?: 'primary' | 'secondary' | 'tertiary';
  /** optional image used for tertiary variant */
  image?: ImageProps;
};

const Heading = ({
  title,
  shortDescription,
  variant,
}: Pick<CategoriesModuleProps, 'title' | 'shortDescription' | 'variant'>) => {
  const Component = variant === 'primary' ? StyledCenteredModuleTitle : Fragment;

  return (
    <Component>
      {!shortDescription ? (
        <StyledModuleHeadingNoDescription>{title}</StyledModuleHeadingNoDescription>
      ) : (
        <StyledModuleHeading>{title}</StyledModuleHeading>
      )}

      {shortDescription && <StyledModuleDescription variant={variant}>{shortDescription}</StyledModuleDescription>}
    </Component>
  );
};

export const CategoriesModule = ({
  title,
  shortDescription,
  cta,
  categories,
  image,
  variant = 'primary',
  ...rest
}: CategoriesModuleProps) => {
  return (
    <StyledModuleContainer {...rest} className={variant}>
      <Heading title={title} shortDescription={shortDescription} variant={variant} />
      {variant === 'tertiary' ? (
        <TertiaryCategories categories={categories} image={image as ImageProps} />
      ) : (
        <StyledCardContainer className={`cards-${categories.length}`}>
          {categories.map((card: CategoryCardProps) => (
            <StyledCategoryCard
              id={card.id}
              key={`category-${card.id}`}
              title={card.title}
              shortDescription={card.shortDescription || null}
              link={card.link}
              image={card.image}
              gated={card.gated}
              className={`cards-${categories.length}`}
            />
          ))}

          {variant === 'secondary' ? <StyledBlueBox /> : null}
        </StyledCardContainer>
      )}
      {cta && (
        <StyledModuleCtaContainer>
          <Cta to={cta.to}>
            <Button>{cta.label}</Button>
          </Cta>
        </StyledModuleCtaContainer>
      )}
    </StyledModuleContainer>
  );
};

export default withErrorBoundary(CategoriesModule, { componentName: 'CategoriesModule' });
