import { useApiIsLoaded, useMapsLibrary } from '@vis.gl/react-google-maps';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MapBoundsValue } from '../InteractiveMap/InteractiveMap';
import { SearchValue } from '../Search/Search';

interface GeocodeResponse {
  results: google.maps.GeocoderResult[] | null;
  status: google.maps.GeocoderStatus;
}

const constructPlacesQuery = (
  input: string,
  country: string[],
  locationRestriction: MapBoundsValue,
  sessionToken: google.maps.places.AutocompleteSessionToken | null,
  language?: string,
) => {
  let query: any = {
    input,
    types: ['locality', 'postal_code', 'sublocality'],
  };
  if (country?.length) query.componentRestrictions = { country };
  if (language) query = { ...query, language };
  if (locationRestriction) query = { ...query, locationRestriction };
  if (sessionToken) query = { ...query, sessionToken };
  return query;
};

export const useGoogleAutocomplete = () => {
  const serviceLoaded = useApiIsLoaded();
  const PlacesLibrary = useMapsLibrary('places');
  const Autocomplete: MutableRefObject<google.maps.places.AutocompleteService | null> = useRef(null);
  const sessionToken: MutableRefObject<google.maps.places.AutocompleteSessionToken | null> = useRef(null);

  useEffect(() => {
    if (!PlacesLibrary || Autocomplete.current) return;
    Autocomplete.current = new PlacesLibrary.AutocompleteService();
    sessionToken.current = new PlacesLibrary.AutocompleteSessionToken();
  }, [PlacesLibrary]);

  const fetchSuggestions = useCallback(
    async (input: string, mapBounds: MapBoundsValue, countryCodes: string[] = [], language?: string) => {
      if (!PlacesLibrary) throw new Error("PlacesLibrary isn't loaded yet!");
      if (!window?.navigator?.onLine) return [];
      const query = constructPlacesQuery(input, countryCodes, mapBounds, sessionToken.current, language);
      try {
        const response = await Autocomplete.current?.getPlacePredictions(query);
        return response?.predictions ?? [];
      } catch (err) {
        console.error('Something went wrong with places api', err);
        return [];
      }
    },
    [PlacesLibrary, Autocomplete.current, sessionToken.current],
  );

  return { fetchSuggestions, serviceLoaded };
};

export const useGeoCoder = () => {
  const libraryLoaded = useApiIsLoaded();
  const GeocodingLibrary = useMapsLibrary('geocoding');
  const geocoder = useMemo(() => GeocodingLibrary && new GeocodingLibrary.Geocoder(), [GeocodingLibrary]);

  const geocode = useCallback(
    async (input: SearchValue, bounds: MapBoundsValue) => {
      if (!geocoder) throw new Error("Geocoder api isn't available yet");
      if (!window?.navigator?.onLine) return Promise.resolve({ results: [], status: 'OFFLINE' });

      let request: any = { fulfillOnZeroResults: true };
      if (bounds) request = { ...request, bounds };
      if (typeof input === 'string') request.address = input;
      else request.placeId = input.place_id;
      return new Promise<GeocodeResponse>(resolve =>
        geocoder.geocode(request, (results, status) =>
          resolve({
            results,
            status,
          }),
        ),
      );
    },
    [geocoder],
  );

  return { geocode, libraryLoaded };
};
