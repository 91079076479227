const transformImage = (item: any) => {
  return {
    __typename: 'Image',
    altText: item?.altText ? item.altText : item.title,
    asset: { gatsbyImageData: item.imageWithFocalPoint },
    key: item.id,
    position: item?.position ?? null,
    size: item?.size ?? null,
  };
};

export default transformImage;
