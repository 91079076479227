import { useConfiguration } from '@arvesta-websites/configuration';
import { IntlContext, TranslatedSlugProvider } from '@arvesta-websites/i18n';
import {
  FooterDefault,
  FooterLight,
  HeaderDefault as Header,
  HeroBanner,
  type NewsLetterProps,
  withErrorBoundary,
} from '@arvesta-websites/ui-babel';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import Helmet from 'react-helmet';
import { withTheme } from 'styled-components';

import {
  ContentfulAdvice,
  ContentfulBasicPage,
  ContentfulEvent,
  ContentfulNews,
  ContentfulProduct,
} from '../../../__generated__/graphql-types';
import componentMap from '../Modules/PageItems/ComponentMap';
import TopLevelHeader from '../TopLevelHeader';
import { HEADER_VARIANTS } from '../TopLevelHeader/Header';

import { FONTMAP } from './fontMap';
import Metatags from './Metatags';
import { EnabledLocalesContext } from './PageWrapper';
import { StyledContainer, StyledFooter, StyledHeader, StyledMain, StyledRootContainer } from './Styled';

type SubscribeFormProps = {
  showFooterSubscribeForm: boolean;
  showFooterSubscribeFormCountryField: boolean;
  subscribeFormHeading?: any | null;
};

type PageProps = {
  /** Sitedata including navigation elements */
  siteData: any;
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
  /** controlls if swifttype can index this pages contents */
  headerSearchFunction: (serchTerm: string) => void;
  subscribeForm: SubscribeFormProps;
  searchPreviewImage: any;
  theme: any;
  headerVariant: string;
  pageLocale: string;
  onSubscribeFormSubmit: (email: string, country: string, locale: string) => void;
  translatedSlugs: Array<ContentfulBasicPage | ContentfulEvent | ContentfulNews | ContentfulAdvice | ContentfulProduct>;
  isHomepage: boolean;
  newsletter: NewsLetterProps;
  heroReference: any;
};

type ImageProps = {
  title?: string;
  altText?: string;
  asset: { gatsbyImageData: IGatsbyImageData };
};

const isImageType = (hero: ImageProps | any): hero is ImageProps => {
  return hero?.asset?.gatsbyImageData !== undefined;
};

const Hero = ({ heroReference }: { heroReference: ImageProps | any }) => {
  if (isImageType(heroReference)) {
    return <HeroBanner image={heroReference} />;
  }

  const MappedComponent = heroReference && componentMap[heroReference.__typename];

  return MappedComponent ? <MappedComponent {...heroReference} /> : null;
};

const MainLayout = ({
  siteData,
  children,
  headerSearchFunction,
  subscribeForm,
  searchPreviewImage,
  headerVariant,
  pageLocale,
  translatedSlugs,
  onSubscribeFormSubmit,
  isHomepage,
  newsletter,
  heroReference,
  ...rest
}: PageProps) => {
  const locale: any = React.useContext(IntlContext);
  const locales: any = React.useContext(EnabledLocalesContext);
  const activeLocales = Object.keys(locales);
  const { header, footer } = siteData;

  const config = useConfiguration();

  const Footer = config.footer.version === 'V1' ? FooterLight : FooterDefault;

  return (
    <StyledRootContainer>
      <Helmet>
        {FONTMAP[rest.theme.name.toLowerCase()].map((font: string) => (
          <link href={font} key={font} rel="stylesheet" />
        ))}
      </Helmet>
      <Metatags
        canonical={siteData.metaData.canonical ? siteData.metaData.canonical : null}
        description={siteData.metaData.description ? siteData.metaData.description : ''}
        image={siteData.metaData.image ? siteData.metaData.image : null}
        locale={siteData.metaData.pageLocale}
        nofollow={siteData.metaData.nofollow ? siteData.metaData.nofollow : null}
        noindex={siteData.metaData.noindex ? siteData.metaData.noindex : null}
        ogPageType={siteData.metaData.ogPageType}
        pageLocale={siteData.metaData.pageLocale ? siteData.metaData.pageLocale : locale.id}
        pageType={siteData.metaData.pageType ? siteData.metaData.pageType : ''}
        searchPreviewImage={searchPreviewImage}
        searchTags={siteData.metaData.searchTags}
        siteName={siteData.metaData.siteName ? siteData.metaData.siteName : ''}
        swiftTypeDate={siteData.metaData.swiftTypeDate ? siteData.metaData.swiftTypeDate : null}
        swiftTypeLocation={siteData.metaData.swiftTypeLocation ? siteData.metaData.swiftTypeLocation : null}
        title={siteData.metaData.title ? siteData.metaData.title : ''}
        updatedAt={siteData.metaData.updatedAt ? siteData.metaData.updatedAt : ''}
      />
      <StyledContainer>
        <StyledHeader data-appsearch-ignore="true">
          <TranslatedSlugProvider value={translatedSlugs}>
            {headerVariant === HEADER_VARIANTS.topLevel ? (
              <TopLevelHeader
                activeLocales={activeLocales}
                defaultLocale={locale}
                isHomepage={isHomepage}
                navigation={header.navigation}
                pageId={siteData.pageId}
                searchFunction={headerSearchFunction}
                siteName={siteData.metaData.siteName}
                variant={headerVariant}
              />
            ) : (
              <Header
                activeLocales={activeLocales}
                defaultLocale={locale}
                isHomepage={isHomepage}
                navigation={header.navigation}
                ribonLinks={header.ribbonLinks}
                searchFunction={headerSearchFunction}
                siteName={siteData.metaData.siteName}
                variant={headerVariant}
                breadcrumb={header.breadcrumb}
              />
            )}
          </TranslatedSlugProvider>
        </StyledHeader>

        <StyledMain
          headerVariant={headerVariant}
          headerIncludesBreadcrumb={useConfiguration().breadcrumb.display}
          isHomePage={isHomepage}
          id="main-wrapper"
        >
          <Hero heroReference={heroReference} />
          <div id="content-wrapper">{children}</div>
        </StyledMain>
      </StyledContainer>
      <StyledFooter hasNewsletter={newsletter}>
        <Footer
          brandType={footer.brandType}
          banner={footer.banner}
          contacts={footer.contacts}
          navigation={footer.navigation}
          legalLinks={footer.legalLinks}
          newsletter={newsletter}
          handleNewsletterFormSubmission={onSubscribeFormSubmit}
          pageLocale={pageLocale}
          siteLogo={footer.siteLogo}
          socialLinks={footer.socialLinks}
          subscribeForm={subscribeForm}
          siteName={siteData.metaData.siteName}
          defaultLocale={locale}
        />
      </StyledFooter>
    </StyledRootContainer>
  );
};

export default withErrorBoundary(withTheme(MainLayout), { componentName: 'MainLayout' });
