import { Location, Mail, PhoneNumber } from '@arvesta-websites/icons';
import { Address, Button, Cta, documentToReactComponents } from '@arvesta-websites/ui-babel';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';

import { useCountryCode } from '../../../hooks/countryCode';
import PageItems from '../../Modules/PageItems';

export interface OwnerPageProps {
  title: string;
  description?: string;
  extraImage?: {
    alt?: string;
    description?: string;
    title?: string;
    asset?: {
      gatsbyImageData?: IGatsbyImageData;
    };
  };
  stores: any;
  content: {
    items: Array<any>;
  };
}

const ownerPage = tv({
  slots: {
    button: 'w-full sm:w-auto text-nowrap',
    buttonContainer: 'flex flex-col lg:flex-row gap-2.5 lg:gap-5 pt-4 flex-wrap',
    employeeContainer: 'pt-5',
    employeeItem: 'leading-[24px] pb-5 last:pb-0',
    employeeJobTitle: 'font-black leading-[23px] text-[16px] text-owner',
    employeeName: 'font-black leading-[23px] text-[16px] text-owner',
    employeeTelephone: '',
    imagePlaceholder: 'sm:!hidden lg:!flex lg:!flex-[0_0_calc(50%-1rem)]',
    introductionDescription: 'text-center',
    introductionTitle: 'ownerIntroduction overflow-hidden m-0 text-center',
    introductionWrapper: 'flex flex-col w-full gap-4 mb-[15px] lg:mb-[-30px]',
    openingHoursContainer: 'flex flex-col gap-[10px] pt-5',
    openingHoursTitle: 'text-left font-black leading-[26px] text-[16px] text-owner',
    ownerWrapper: 'w-full justify-between flex flex-wrap gap-6 my-5 lg:my-[36px] xl:my-[57px] mx-0',
    serviceContainer: 'pt-5',
    serviceItem: 'pb-2 pl-[5px] last:pb-0',
    serviceList: 'list-["-"] pl-[7px]',
    serviceTitle: 'pb-[11px] text-left font-black leading-[26px] text-[16px] text-owner',
    storeCard:
      'flex flex-col gap-4 bg-owner-store-background flex-[0_0_100%] md:min-w-[400px] md:flex-[1_1_calc(50%-1rem)] lg:flex-[1_0_calc(50%-1rem)] xl:min-w-[600px] xl:flex-[1_1_calc(50%-1rem)]  px-[15px] py-5 md:p-5 lg:p-6 xl:p-[36px] ',
    storeInfo: 'flex align-center gap-5',
    storeList: 'flex flex-wrap w-full gap-6 md:py-[15px] lg:py-[45px]',
    storeTitle: 'pb-4 text-left font-black leading-[26px] text-[24px]',
    svg: 'min-w-5 lg:min-w-6 h-6',
  },
  variants: {
    number: {
      2: {
        storeCard:
          'md:min-w-[400px] md:flex-[1_1_calc(50%-1rem)] lg:flex-[0_0_calc(50%-1rem)] xl:flex-[0_0_calc(50%-1rem)] xl:min-w-[600px]',
      },
      3: {
        buttonContainer: 'xl:flex-col lg:gap-2.5',
        storeCard:
          'md:min-w-[400px] md:flex-[1_1_calc(50%-1rem)] lg:min-w-[400px] xl:min-w-[400px] lg:flex-[1_1_calc(33%-1.1rem)] xl:flex-[0_1_calc(33%-1.1rem)]',
      },
      5: {
        buttonContainer: 'xl:flex-col lg:gap-2.5',
        storeCard:
          'md:min-w-[400px] md:flex-[1_1_calc(50%-1rem)] lg:min-w-[400px] xl:min-w-[400px] lg:flex-[1_1_calc(33%-1.1rem)] xl:flex-[1_1_calc(33%-1.1rem)]',
      },
    },
  },
});

export const formatAddress = (toCountryName: (x: string) => string) => (address?: Address) => {
  if (!address) return '';
  const { street, number, cityTown, countryCode } = address;
  const streetPart = street && number ? `${street} ${number}` : street;
  return [streetPart, cityTown, toCountryName(countryCode)].filter(el => !!el).join(', ');
};

const OwnerDetailPage = ({ description, extraImage, title, stores, content }: OwnerPageProps) => {
  const intl = useIntl();
  const toCountryName = useCountryCode();

  const {
    ownerWrapper,
    introductionWrapper,
    introductionTitle,
    introductionDescription,
    storeList,
    storeCard,
    storeTitle,
    storeInfo,
    svg,
    employeeItem,
    employeeContainer,
    employeeName,
    employeeJobTitle,
    employeeTelephone,
    openingHoursContainer,
    openingHoursTitle,
    serviceContainer,
    serviceItem,
    serviceTitle,
    serviceList,
    imagePlaceholder,
    buttonContainer,
    button,
  } = ownerPage();

  const addressFormatter = formatAddress(toCountryName);

  return (
    <div className={ownerWrapper()}>
      <div className={introductionWrapper()}>
        <h1 className={introductionTitle()}>{title}</h1>
        {description && <div className={introductionDescription()}>{description}</div>}
      </div>
      {stores?.length > 0 && (
        <div className={storeList()}>
          {stores.map((store: any) => (
            <div key={store.id} className={storeCard({ number: stores.length })}>
              <div className={storeTitle()}>{store.node?.name}</div>
              {store.node?.address && (
                <div className={storeInfo()}>
                  <Location className={svg()} />
                  {addressFormatter(store.node.address)}
                </div>
              )}
              {store.node?.email &&
                store.node?.email.map((email: string, index: number): any => (
                  <div className={storeInfo()} key={index}>
                    <Mail className={svg()} />
                    <a className="break-all" href={`mailto:${email}`}>
                      {email}
                    </a>
                  </div>
                ))}
              {store.node?.telephone &&
                store.node?.telephone.map((phone: string, index: number): any => (
                  <div className={storeInfo()} key={index}>
                    <PhoneNumber className={svg()} />
                    <a href={`tel:${phone}`}>{phone}</a>
                  </div>
                ))}
              {/*<ButtonContainer items={stores.length}>*/}
              <div className={buttonContainer({ number: stores.length })}>
                <Cta to={`mailto:${store.node.email}`}>
                  <Button variant="primary" className={button()}>
                    {intl.formatMessage({ id: 'owner.storecard.contact' })}
                  </Button>
                </Cta>
                <Cta
                  to={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(`${store.node.address?.location.lat},${store.node.address?.location.lon}`)}`}
                >
                  <Button variant="secondary" className={button()}>
                    {intl.formatMessage({ id: 'owner.storecard.route' })}
                  </Button>
                </Cta>
              </div>
              {/*</ButtonContainer>*/}
              {store.node?.teamMembers && (
                <ul className={employeeContainer()}>
                  {store.node?.teamMembers.map((employee: any) => (
                    <li key={employee.id} className={employeeItem()}>
                      <div className={employeeName()}>{employee.name}</div>
                      {employee?.jobTitle && <div className={employeeJobTitle()}>{employee.jobTitle}</div>}
                      {employee?.telephone && <div className={employeeTelephone()}>{employee.telephone}</div>}
                    </li>
                  ))}
                </ul>
              )}
              {store.node?.openingHours && (
                <div className={openingHoursContainer()}>
                  <div className={openingHoursTitle()}>
                    {intl.formatMessage({ id: 'owner.storecard.openinghours' })}
                  </div>
                  {documentToReactComponents(JSON.parse(store.node.openingHours.raw))}
                </div>
              )}
              {store.node?.services?.length > 0 && (
                <div className={serviceContainer()}>
                  <div className={serviceTitle()}>{intl.formatMessage({ id: 'owner.storecard.services' })}</div>
                  <ul className={serviceList()}>
                    {store.node.services.map((service: any) => (
                      <li key={service.id} className={serviceItem()}>
                        {service}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
          {extraImage && stores.length === 1 && (
            <GatsbyImage
              alt={extraImage?.description ? extraImage?.description : extraImage?.title}
              image={extraImage?.asset?.gatsbyImageData}
              className={imagePlaceholder()}
              loading="eager"
              style={{ width: '100%' }}
              objectFit="cover"
            />
          )}
        </div>
      )}
      <PageItems content={content} />
    </div>
  );
};

export default OwnerDetailPage;
