import {
  ContentfulProduct,
  ContentfulProductConnection,
  ContentfulProductEdge,
  ContentfulProductListingSection,
} from '../../__generated__/graphql-types';
import extractFileInforamtion from '../../ui-lib/utils/file';
import extractShortDescription from '../ShortDescription';

import brandListing from './BrandListing';

const extractProducts = (products: Array<ContentfulProductEdge>) => {
  return products
    .filter(productEdge => !productEdge.node.isBrand)
    .map((productEdge: ContentfulProductEdge) => {
      const productItem: ContentfulProduct = productEdge.node;
      const { document, additionalDocuments } = productItem;
      const documentTitle = document?.title;
      const asset = document?.asset?.file;
      const fileInformation = extractFileInforamtion({ asset, title: documentTitle });
      const documents = [];

      console.log(productEdge.node);

      if (fileInformation) {
        documents.push(fileInformation);
      }

      if (additionalDocuments) {
        additionalDocuments.forEach(additionalDocument => {
          const additionalDocumentTitle = additionalDocument?.title;
          const additionalAsset = additionalDocument?.asset?.file;
          const additionalFileInformation = extractFileInforamtion({
            asset: additionalAsset,
            title: additionalDocumentTitle,
          });
          documents.push(additionalFileInformation);
        });
      }

      return {
        cardCTAText: productItem.cardCTAText,
        category: productItem.category?.slug,
        documents,
        enquiryCta:
          productItem && productItem.enquiryCta
            ? {
                label: productItem.enquiryCta.title,
                link: productItem.enquiryCta.internalEntry
                  ? productItem.enquiryCta.internalEntry.slug
                  : productItem.enquiryCta.externalUrl,
              }
            : null,
        gated: productItem.gated,
        id: productItem.id,
        image: { ...productItem.image, asset: { gatsbyImageData: productItem.image?.imageWithFocalPoint } },
        link: productItem.slug,
        linkToCustomPage: productItem.linkToCustomPage,
        linkToDetailPage: productItem.linkToDetailPage !== false,
        price: productItem.price,
        shortDescription: extractShortDescription(productItem),
        subcategory: productItem.subcategory?.slug,
        title: productItem.title,
      };
    });
};

const sortProducts = ({ filteredProducts, productOrder }) => {
  const sortedProducts = [];

  productOrder.forEach(({ id }) => {
    const productIndex = filteredProducts.findIndex(product => product.id === id);

    if (productIndex !== -1) {
      const [product] = filteredProducts.splice(productIndex, 1);
      sortedProducts.push(product);
    }
  });

  sortedProducts.push(...filteredProducts);

  return sortedProducts;
};

const transformProducts = ({ queryString, products, productOrder, productsSelected }) => {
  const extractedProducts = extractProducts(products.edges);

  if (Array.isArray(productsSelected) && productsSelected.length > 0) {
    const arrayOfIdsOfSelectedProducts = productsSelected.map(product => product.id);
    const filteredProducts = extractedProducts.filter(product => arrayOfIdsOfSelectedProducts.includes(product.id));

    return sortProducts({ filteredProducts, productOrder });
  }

  if (queryString.category) {
    const filteredProducts = extractedProducts.filter(product => product.category === queryString.category);

    return sortProducts({ filteredProducts, productOrder });
  }

  if (queryString.subcategory) {
    const filteredProducts = extractedProducts.filter(product => product.subcategory === queryString.subcategory);

    return sortProducts({ filteredProducts, productOrder });
  }

  return sortProducts({ filteredProducts: extractedProducts, productOrder });
};

const transformProductListingSection = (
  item: ContentfulProductListingSection,
  products: ContentfulProductConnection,
  queryString: any,
) => {
  if (item.isBrandListing) {
    return brandListing(item, products);
  }

  const productOrder = item.productOrder || [];
  const productsSelected = item.products || [];
  const transformedProducts = transformProducts({ productOrder, products, productsSelected, queryString });

  return {
    __typename: 'ProductListingSection',
    categorySearchTerm: queryString.category,
    incrementAmount: 6,
    initialAmount: 9,
    key: item.id,
    products: transformedProducts,
    shortDescription: extractShortDescription(item),
    title: item.title,
    titleTag: item.titleTag,
    variant: item.layout === 'Default' ? 'vertical' : 'horizontalLeft',
  };
};

export default transformProductListingSection;
