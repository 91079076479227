/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import componentMap from './ComponentMap';

export type collection = {
  __typename: string;
  id?: string;
  title: string;
  shortDescription?: string;
  cta?: any;
};

interface BasicPageProps {
  title?: string;
  slug?: string;
  content: {
    items: Array<any>;
  };
  image?: any;
}

const PageItems = ({ content }: BasicPageProps) => {
  return (
    <>
      {content.items.map((item: collection, idx: number): any => {
        let element: React.ReactElement | null;
        try {
          // eslint-disable-next-line no-underscore-dangle
          element = React.createElement(componentMap[item.__typename], { ...item }, null);
        } catch (e) {
          element = null;
        }
        const key = element
          ? `comp-${element.key || item.id || item.title || ''}-${idx}`
          : // eslint-disable-next-line no-underscore-dangle
            `comp-${item.__typename}-${idx}`;
        return <React.Fragment key={key}>{element}</React.Fragment>;
      })}
    </>
  );
};

export default PageItems;
