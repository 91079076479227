import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';

//
import Media from '../../../utils/Media';

export const StyledContainer = styled(Flex)`
  display: flex;
  max-width: ${props => props.theme.sizes.xl};

  ${Media({
    flexWrap: ['wrap', 'wrap', 'nowrap', 'nowrap'],
  })}
`;

export const StyledContentContainer = styled(Flex)`
  display: flex;

  ${Media({
    flexDirection: ['column-reverse', 'column-reverse', 'row', 'row'],
    flexWrap: ['wrap', 'wrap', 'nowrap', 'nowrap'],
    marginTop: ['48px', '48px', '56px', '56px'],
    paddingLeft: ['1rem', '1rem', '32px', '58px'],
    paddingRight: ['1rem', '1rem', '32px', '0px'],
  })}
`;

export const StyledHeading = styled.div`
  font-size: 3.5rem;
  color: ${props => props.theme.colors.text};
  margin-bottom: 1.5rem;
  marginleft: 1.25rem;
  marginright: 1.25rem;
`;

export const StyledDescription = styled.div`
  font-size: 1.25rem;
  color: ${props => props.theme.colors.text};
  margin-bottom: 80px;
`;

export const StyledHeaderContainer = styled.div`
  margin: 0 auto;
`;

export const StyledCenteredText = styled.div`
  ${Media({
    margin: ['48px auto auto 20px', '48px auto auto 20px', '48px auto auto 20px', '57px auto'],
    width: ['auto', 'auto', 'auto', '645px'],
  })}
  blockquote {
    ${Media({
      width: ['auto', 'auto', 'auto', '1042px'],
    })}
  }
`;

export const StyledModuleContainer = styled.div`
  ${Media({
    flexWrap: ['wrap', 'wrap', 'nowrap', 'nowrap'],
    marginLeft: ['1rem', '1rem', '2rem', '2rem'],
    marginRight: ['1rem', '1rem', '2rem', '2rem'],
  })}
`;

export const StyledLeftColumn = styled.div`
  ${Media({
    marginRight: ['1rem', '1rem', '50px', '145px'],
    width: ['100%', '100%', '45%', '35%'],
  })}
`;

export const StyledRightColumn = styled.div`
  ${Media({
    marginBaottom: ['88px', '0px', '0px', '0px'],
    width: ['100%', '100%', '50%', '60%'],
  })}

  color: ${props => `${props.theme.colors.text}`};

  *:first-child {
    margin-top: 0px;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    ${Media({
      marginRight: ['0px', '0px', '0px', '170px'],
    })}
  }

  .media {
    margin: 0;
  }
`;

export const StyledEventInfoWrapper = styled.div`
  margin-bottom: 72px;
  ${Media({
    display: ['none', 'none', 'block', 'block'],
    marginBottom: ['40px', '40px', '40px', '70px'],
    marginTop: ['0px', '0px', '25px', '0px'],
  })}
`;

export const StyledEventInfoMobileWrapper = styled.div`
  margin-bottom: 40px;
  margin: 0 auto;
  width: 300px;
  margin-bottom: 40px;

  ${Media({
    display: ['block', 'block', 'none', 'none'],
  })}
`;

export const StyledEventInfo = styled(Flex)`
  display: flex;
  color: ${props => props.theme.colors.text};
  margin-bottom: 1rem;
  font-size: 14px;
  ${Media({
    fontSize: ['14px', null, '11px', '14px'],
  })}

  svg {
    margin-right: 1rem;

    > * {
      fill: ${props => `${props.theme.colors.primary}`};
    }
  }
`;

export const StyledContactsFormContainer = styled.div`
  ${Media({
    marginTop: ['88px', '88px', '0px', '0px'],
  })}
`;

export const DocumentDownloadContainer = styled.div`
  margin-top: 64px;
  max-width: 420px;
  ${Media({
    display: ['none', 'none', 'none', 'block'],
    marginRight: ['2rem', '2rem'],
  })}
`;

export const DocumentDownloadContainerMobile = styled.div`
  margin-top: 64px;
  ${Media({
    display: ['block', 'block', 'block', 'none'],
  })}
`;
