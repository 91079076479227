import React from 'react';
import { useIntl } from 'react-intl';
import { BoxProps } from 'rebass/styled-components';

import { Button, Cta, HeadingTag, NewsCard, NewsCardProps, withErrorBoundary } from '../../components';
import { StyledModuleCtaContainer } from '../../shared';

import {
  StyledCardsWrapper,
  StyledContainer,
  StyledHeadingAndDescriptionWrapper,
  StyledLoadMoreWrapper,
  StyledModuleDescription,
  StyledModuleHeading,
  StyledNewsCardWrapper,
} from './Styled';

export interface NewsListingModuleProps extends BoxProps {
  title: string;
  titleTag: HeadingTag;
  shortDescription?: string | null;
  news: NewsCardProps[];
  initialAmount?: number;
  incrementAmount?: number;
  variant?: string;
  gated?: boolean;
}

const NewsListingModule = ({
  title,
  titleTag,
  shortDescription,
  news,
  initialAmount = 6,
  incrementAmount = 8,
  // FIXME variant is not needed. Does not exist in Contentful and is default passed as 'primary'
  variant = 'primary',
  ...rest
}: NewsListingModuleProps) => {
  const intl = useIntl();
  const [amountOfNewsItemsToDisplay, setDisplayLimit] = React.useState(initialAmount);

  return (
    <StyledContainer {...rest} data-appsearch-ignore="true">
      <StyledHeadingAndDescriptionWrapper>
        <StyledModuleHeading variant="h1" tag={titleTag}>
          {title}
        </StyledModuleHeading>
        {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
      </StyledHeadingAndDescriptionWrapper>
      <StyledCardsWrapper>
        {news
          .map(({ id, ...newsRest }: NewsCardProps) => (
            <StyledNewsCardWrapper key={id}>
              <NewsCard id={id} variant="horizontal" {...newsRest} />
            </StyledNewsCardWrapper>
          ))
          .slice(0, amountOfNewsItemsToDisplay)}
      </StyledCardsWrapper>
      {news.length > amountOfNewsItemsToDisplay ? (
        <StyledLoadMoreWrapper>
          <StyledModuleCtaContainer>
            <Cta
              onClick={() => {
                setDisplayLimit(amountOfNewsItemsToDisplay + incrementAmount);
              }}
            >
              <Button
                onClick={() => {
                  setDisplayLimit(amountOfNewsItemsToDisplay + incrementAmount);
                }}
              >
                {intl.formatMessage({ id: 'sections.news.loadmore' })}
              </Button>
            </Cta>
          </StyledModuleCtaContainer>
        </StyledLoadMoreWrapper>
      ) : null}
    </StyledContainer>
  );
};

export default withErrorBoundary(NewsListingModule, { componentName: 'NewsListingModule' });
