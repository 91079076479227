export default {
  'articleListing.applyFilters': 'Appliquer des filtres',
  'articleListing.filters': 'Filters',
  'articleListing.filtersClear': 'Effacer les filtres',
  'articleListing.loadMore': 'Laad meer',
  'brandListing.all': 'Tout',
  'brandListing.byCategories': 'Par catégorie',
  'brandListing.clear': 'Décocher',
  'brandListing.productsAndServices': 'Produits et services',
  'brandListing.seeProductsFor': 'Voir les produits pour',
  'brandListing.similarBrands': 'Marques avec produits et services similaires',
  'brandListing.visitWebsite': 'Visiter le site Web',
  'cards.advice.link': "lire l'article",
  'cards.category.link': 'lire plus',
  'cards.event.link': "voir l'événement",
  'cards.news.link': 'continuer à lire',
  'cards.product.link': 'Voir plus',
  'footer.copyright': '© {year} Arvesta. Tous les droits sont réservés.',
  'footer.follow_us': 'Suivez-nous',
  'footer.newsletter.error': 'l y a eu une erreur avec les informations que vous avez saisies, veuillez réessayer',
  'footer.newsletter.heading':
    'Souhaitez-vous recevoir des nouvelles et des conseils qui correspondent à ce qui vous intéresse? Nous sommes heureux de le faire pour vous!',
  'footer.newsletter.placeholder': 'Entrez votre adresse e-mail',
  'footer.newsletter.subscribe': 'souscrire',
  'footer.newsletter.success': 'Vous êtes abonné, merci. Vous aurez bientôt de nos nouvelles',
  'footer.subscribe.enteremail': 'Entrez votre adresse e-mail',
  'footer.subscribe.failure': 'l y a eu une erreur avec les informations que vous avez saisies, veuillez réessayer',
  'footer.subscribe.invalid_email': 'Pouvez-vous saisir votre adresse e-mail ?',
  'footer.subscribe.subscribe': 'souscrire',
  'footer.subscribe.subscribing': 'Veuillez patienter pendant que nous essayons de nous connecter au serveur',
  'footer.subscribe.success': 'Vous êtes abonné, merci. Vous aurez bientôt de nos nouvelles.',
  'forms.contact.description': 'Remplissez le formulaire ci-dessous et notre équipe vous contactera',
  'forms.contact.email.label': 'Adresse e-mail',
  'forms.contact.email.placeholder': 'Entrez votre adresse e-mail',
  'forms.contact.errors.email': 'Pouvez-vous saisir votre adresse e-mail ?',
  'forms.contact.errors.required': 'Veuillez remplir ce champ obligatoire',
  'forms.contact.errors.telephone': `Veuillez n'entrer que des chiffres et le symbole '+'.`,
  'forms.contact.failure': 'l y a eu une erreur avec les informations que vous avez saisies, veuillez réessayer',
  'forms.contact.fullname.label': 'Nom complet',
  'forms.contact.fullname.placeholder': 'Introduisez votre nom complet',
  'forms.contact.message.label': 'Message',
  'forms.contact.message.placeholder': 'Communiquez-nous votre message',
  'forms.contact.newsletter.title':
    'Souhaitez-vous recevoir des nouvelles et des conseils qui correspondent à ce qui vous intéresse? Nous sommes heureux de le faire pour vous!',
  'forms.contact.other': 'Autre',
  'forms.contact.pageTitle': 'Titre de la page',
  'forms.contact.submit': 'Envoyez',
  'forms.contact.success': 'Merci, nous étudions déjà votre message',
  'forms.contact.telephone.label': 'Numéro de téléphone',
  'forms.contact.telephone.placeholder': 'Entrez votre numéro de téléphone',
  'forms.contact.title': 'Prenez contact avec nous',
  'forms.profile.account.delete.account': 'Supprimer le compte',
  'forms.profile.account.delete.cancel': 'Annuler',
  'forms.profile.account.delete.confirm': 'Confirmer',
  'forms.profile.account.delete.confirm.button': 'Supprimer mon compte',
  'forms.profile.account.delete.confirm.title': 'Supprimer le compte',
  'forms.profile.account.delete.confirmation': 'Voulez-vous vraiment supprimer votre compte?',
  'forms.profile.account.delete.messsage':
    'Vous êtes sur le point de supprimer votre compte sur ce site web. Cela signifie que nous supprimerons immédiatement toutes vos préférences et autorisations pour ce site web.',
  'forms.profile.account.delete.success':
    'Dommage que vous ayez choisi de supprimer votre compte sur ce site. On se reverra peut-être ?',
  'forms.profile.account.delete.success.title': 'Compte supprimé',
  'forms.profile.change.password': 'Changer le mot de passe',
  'forms.profile.givenName.label': 'Prénom',
  'forms.profile.name.label': 'Nom',
  'forms.profile.preferneces.toast.success': 'Vos préférences ont été enregistrées avec succès',
  'forms.profile.submit': 'Enregistrer',
  'forms.profile.whatsapp': 'Numéro de téléphone pour les notifications WhatsApp',
  'globals.download': 'Download',
  'globals.featuredProducts': 'Produits populaires',
  'globals.no': 'Non',
  'globals.yes': 'Oui',
  'header.allAbout': 'Tout savoir sur',
  'header.search.placeholder': 'Chercher',
  'header.selectlanguage': 'Choisir la langue',
  'legacy.cookie.description': 'En continuant à naviguer sur le site, vous acceptez notre utilisation des',
  'legacy.footer.headoffice': 'Siège central',
  'legacy.footer.headquarters': 'Siège social',
  'legacy.header.about': 'à propos de nous',
  'legacy.header.contact': 'contactez-nous',
  'legacy.header.jobs': 'jobs',
  'legacy.header.news': 'nouvelles & presse',
  'legacy.header.products': 'produits & services',
  'lockcta.buttonLabel': 'Se connecter',
  'lockcta.description': `Connectez-vous ou créez un compte Arvesta gratuit pour découvrir tous nos articles intéressants. De cette façon, vous avez toujours une longueur d'avance!`,
  'lockcta.title': 'En savoir plus?',
  'logout.label': 'Déconnexion',
  'newscard.readmore': 'En savoir plus',
  'newsletter.email.error': "S'il vous plaît, mettez une adresse email valide",
  'newsletter.email.success': 'Vous vous êtes abonné avec succès. Vous aurez de nos nouvelles sous peu.',
  'newsletter.input.placeholder': 'Adresse e-mail',
  'newsletter.terms.error.sectionOne': 'Veuillez accepter les',
  'newsletter.terms.error.sectionTwo': 'conditions générales de confidentialité',
  'newsletter.terms.privacyPolicy': 'conditions de confidentialité',
  'newsletter.terms.sectionOne': 'Je reconnais avoir reçu et lu la',
  'newsletter.terms.sectionTwo': '.',
  'newsletter.terms.subscribe': 'souscrire',
  'newsletter.terms.subscribe.error':
    "Malheureusement, un problème s'est produit lors de votre inscription. Veuillez réessayer plus tard.",
  'owner.storecard.contact': 'Contactez-nous',
  'owner.storecard.openinghours': 'Heures d’ouverture',
  'owner.storecard.route': 'Directions',
  'owner.storecard.services': 'Services',
  'product.certifications.title': 'Certifications du produit',
  'profile.label': 'Profil',
  'profile.slug': 'profil',
  'sections.advice.loadmore': 'charger plus de conseils',
  'sections.category.loadmore': 'voir plus de produits',
  'sections.contact.offices': "l'offices",
  'sections.contact.openinghours': "Horaires d'ouvertures",
  'sections.contact.salesteam': "l'équipe",
  'sections.contact.viewonmap': 'voir sur la carte',
  'sections.dealer.clear': 'Décocher',
  'sections.dealer.invalidaddress': "Nous n'avons pas pu trouver l'adresse",
  'sections.dealer.loadmore': 'Afficher tout',
  'sections.dealer.maplink': 'voir sur la carte',
  'sections.dealer.placeholder': 'Entrez votre code postal et la commune',
  'sections.dealer.search': 'Rechercher',
  'sections.dealer.total': 'résultats pour',
  'sections.dealerOverview.clear_filters': 'Effacer tous les filtres',
  'sections.dealerOverview.details': 'Détails',
  'sections.dealerOverview.no_results': 'Pas de résultats',
  'sections.dealerOverview.remove_filter': 'Supprimer le filtre',
  'sections.dealerOverview.route': 'Directions',
  'sections.dealerOverview.services': 'Services',
  'sections.dealerOverview.show_all': 'Retour à la liste de tous les magasins',
  'sections.events.loadmore': "charger plus d'événements",
  'sections.events.loadmorepast': "charger plus d'événements passés",
  'sections.news.loadmore': 'charger plus de nouvelles',
  'sections.product.loadmore': 'Voir plus',
  'sections.product.noproductsfoundfor': 'aucun produit trouvé pour',
  'sections.product.table.brochure': 'Brochure',
  'sections.product.table.download': 'Download',
  'sections.product.table.number': 'n°',
  'sections.product.table.product': 'produit',
  'sections.product.table.weight': 'poids',
  'sections.search.events': 'Evénements',
  'sections.search.news': 'Actus',
  'sections.search.noncategorised': 'Non-catégorisé',
  'sections.search.noresults.title': 'résultats pour',
  'sections.search.products': 'products',
  'sections.search.results.title': '{total} résultats pour {query}',
  'sections.search.seeallevents': 'Voir tous les événements ',
  'sections.search.seeallnews': 'Voir toutes les actus',
  'sections.search.seeallproducts': 'Voir tous les produits',
};
