export default {
  'brandListing.all': 'Wszystkie',
  'brandListing.byCategories': 'Według kategorii',
  'brandListing.clear': 'Wyczyść',
  'brandListing.productsAndServices': 'Produkty i usługi',
  'brandListing.seeProductsFor': 'Zobacz produkty dla hasła',
  'brandListing.similarBrands': 'Marki oferujące podobne produkty i usługi',
  'brandListing.visitWebsite': 'Odwiedź serwis internetowy',
  'cards.advice.link': 'Przeczytaj artykuł',
  'cards.category.link': 'Czytaj więcej',
  'cards.event.link': 'Zobacz wydarzenie',
  'cards.news.link': 'Czytaj dalej',
  'cards.product.link': 'Zobacz więcej',
  'footer.copyright': '© {year} Arvesta. Wszelkie prawa zastrzeżone.',
  'footer.newsletter.error': 'Wprowadzone przez Ciebie dane są błędne. Spróbuj jeszcze raz.',
  'footer.newsletter.heading':
    'Czy chcesz otrzymywać aktualności i porady dopasowane do Twoich zainteresowań? Z przyjemnością będziemy Ci je przesyłać!',
  'footer.newsletter.placeholder': 'Wpisz adres e-mail',
  'footer.newsletter.subscribe': 'Subskrybuj',
  'footer.newsletter.success':
    'Dziękujemy, proces subskrypcji został zakończony pomyślnie. Wkrótce otrzymasz od nas wybrane informacje.',
  'footer.subscribe.enteremail': 'Wpisz adres e-mail',
  'footer.subscribe.failure': 'Wprowadzone przez Ciebie dane są błędne. Spróbuj jeszcze raz.',
  'footer.subscribe.invalid_email': 'Nieprawidłowy adres e-mail',
  'footer.subscribe.subscribe': 'Subskrybuj',
  'footer.subscribe.subscribing': 'Subskrypcja',
  'footer.subscribe.success':
    'Dziękujemy, proces subskrypcji został zakończony pomyślnie. Wkrótce otrzymasz od nas wybrane informacje.',
  'forms.contact.description': 'Wypełnij poniższy formularz, a nasz zespół skontaktuje się z Tobą',
  'forms.contact.email.label': 'Adres e-mail',
  'forms.contact.email.placeholder': 'Wpisz adres e-mail',
  'forms.contact.errors.email': 'Nieprawidłowy adres e-mail',
  'forms.contact.errors.required': 'Wymagane jest wypełnienie tego pola',
  'forms.contact.errors.telephone': `Wprowadź tylko cyfry, spacje i symbol "+". Pierwszy znak musi być znakiem "+" lub cyfrą`,
  'forms.contact.failure': 'Wprowadzone przez Ciebie dane są błędne. Spróbuj jeszcze raz.',
  'forms.contact.fullname.label': 'Imię i nazwisko',
  'forms.contact.fullname.placeholder': 'Proszę wpisać swoje imię i nazwisko',
  'forms.contact.message.label': 'Wiadomość',
  'forms.contact.message.placeholder': 'Wpisz swoją wiadomość',
  'forms.contact.newsletter.title':
    'Czy chcesz otrzymywać e-maile z aktualnościami oraz informacjami o zmianach produktów i wydarzeniach? Możesz zapisać się w późniejszym terminie.',
  'forms.contact.other': 'Inny',
  'forms.contact.submit': 'Wyślij',
  'forms.contact.success': 'Dziękujemy, już zajmujemy się Twoją wiadomością',
  'forms.contact.telephone.label': 'Numer telefonu',
  'forms.contact.telephone.placeholder': 'Wpisz swój numer telefonu',
  'forms.contact.title': 'Wyślij nam wiadomość',
  'forms.profile.account.delete.account': 'Usuń konto',
  'forms.profile.account.delete.cancel': 'Anuluj',
  'forms.profile.account.delete.confirm': 'Potwierdź',
  'forms.profile.account.delete.confirmation': 'Czy na pewno chcesz usunąć swoje konto?',
  'forms.profile.account.delete.success':
    'Szkoda, że zdecydowałeś się usunąć swoje konto na tej stronie. Może jeszcze się spotkamy?',
  'forms.profile.account.delete.success.title': 'Konto usunięte',
  'forms.profile.change.password': 'Zmień hasło',
  'forms.profile.preferneces.toast.success': 'Twoje preferencje zostały zapisane pomyślnie',
  'forms.profile.whatsapp': 'Numer telefonu do powiadomień WhatsApp',
  'globals.download': 'Pobierz',
  'globals.featuredProducts': 'Polecane produkty',
  'globals.no': 'Nie',
  'globals.yes': 'Tak',
  'header.search.placeholder': 'Szukaj',
  'header.selectlanguage': 'Wybierz język',
  'legacy.cookie.description': 'Kontynuując przeglądanie strony, wyrażasz zgodę na wykorzystywanie przez nas',
  'legacy.footer.headoffice': 'Centrala',
  'legacy.footer.headquarters': 'Siedziba główna',
  'legacy.header.about': 'o nas',
  'legacy.header.contact': 'kontakt',
  'legacy.header.jobs': 'praca',
  'legacy.header.news': 'aktualności i informacje dla prasy',
  'legacy.header.products': 'produkty i usługi',
  'lockcta.buttonLabel': 'Zaloguj się',
  'lockcta.description':
    'Zaloguj się lub utwórz darmowe konto Arvesta, aby odkryć wszystkie nasze interesujące artykuły. Dzięki temu będziesz zawsze o krok do przodu!',
  'lockcta.title': 'Przeczytaj więcej?',
  'logout.label': 'Wyloguj',
  'newsletter.email.error': 'Proszę wpisać aktualny adres e-mail',
  'newsletter.email.success': 'Pomyślnie zasubskrybowałeś. Wkrótce usłyszysz od nas.',
  'newsletter.input.placeholder': 'adres e-mail',
  'newsletter.terms.error.sectionOne': 'Proszę wyrazić zgodę na',
  'newsletter.terms.error.sectionTwo': 'ogólne warunki prywatności',
  'newsletter.terms.privacyPolicy': 'Polityka prywatności',
  'newsletter.terms.sectionOne': 'zgadzam się z',
  'newsletter.terms.sectionTwo': 'z Proxani',
  'newsletter.terms.subscribe': 'Subskrybuj',
  'newsletter.terms.subscribe.error': 'Niestety, coś poszło nie tak podczas rejestracji. Spróbuj ponownie później.',
  'owner.storecard.contact': 'Kontakt',
  'owner.storecard.openinghours': 'Godziny otwarcia',
  'owner.storecard.route': 'Kierunki',
  'owner.storecard.services': 'Usługi',
  'product.certifications.title': 'Certyfikacje produktów',
  'profile.label': 'Profil',
  'profile.slug': 'profil',
  'sections.advice.loadmore': 'Wczytaj więcej porad',
  'sections.category.loadmore': 'Wczytaj więcej kategorii',
  'sections.contact.offices': 'Oddziały',
  'sections.contact.openinghours': 'Godziny otwarcia',
  'sections.contact.salesteam': 'Zespół',
  'sections.contact.viewonmap': 'Zobacz na mapie',
  'sections.dealer.clear': 'WYCZYŚĆ',
  'sections.dealer.invalidaddress': 'Nie udało się znaleźć adresu',
  'sections.dealer.loadmore': 'Zobacz wszystkich dystrybutorów',
  'sections.dealer.maplink': 'Zobacz na mapie',
  'sections.dealer.placeholder': 'Wprowadź swój kod pocztowy oraz gminę',
  'sections.dealer.search': 'Szukaj',
  'sections.dealer.total': '{total} Wyniki dla hasła "{query}"',
  'sections.dealerOverview.clear_filters': 'Wyczyść wszystkie filtry',
  'sections.dealerOverview.details': 'Szczegóły',
  'sections.dealerOverview.no_results': 'Brak wyników',
  'sections.dealerOverview.remove_filter': 'Usuń filtr',
  'sections.dealerOverview.route': 'Kierunki',
  'sections.dealerOverview.services': 'Serwisy',
  'sections.dealerOverview.show_all': 'Nowrót do listy wszystkich sklepów',
  'sections.events.loadmore': 'Wczytaj więcej wydarzeń',
  'sections.events.loadmorepast': 'Wczytaj więcej minionych wydarzeń',
  'sections.news.loadmore': 'Wczytaj więcej aktualności',
  'sections.product.loadmore': 'Wczytaj więcej',
  'sections.product.noproductsfoundfor': 'Nie znaleziono żadnych produktów dla hasła',
  'sections.product.table.brochure': 'ULOTKA',
  'sections.product.table.download': 'Pobierz',
  'sections.product.table.number': 'ART.NR',
  'sections.product.table.product': 'PRODUKT',
  'sections.product.table.weight': 'WAGA',
  'sections.search.events': 'Wydarzenia',
  'sections.search.news': 'Aktualności',
  'sections.search.noncategorised': 'Nie skategoryzowane',
  'sections.search.noresults.title': 'Niestety nie znaleźliśmy żadnych wyników dla hasła ',
  'sections.search.products': 'Produkty',
  'sections.search.results.title': '{total} Wyniki dla hasła "{query}"',
  'sections.search.seeallevents': 'Zobacz wszystkie wydarzenia',
  'sections.search.seeallnews': 'Zobacz wszystkie aktualności',
  'sections.search.seeallproducts': 'Zobacz wszystkie produkty',
};
