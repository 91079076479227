import { ContentfulNewsConnection } from '../../__generated__/graphql-types';
import extractCta from '../Cta';
import transformNewsItems from '../NewsItems';
import extractShortDescription, { ItemType } from '../ShortDescription';

const transformLatestNewsSection = (item: ItemType, news: ContentfulNewsConnection) => {
  return {
    __typename: 'LatestNewsSection',
    cta: extractCta(item),
    key: item.id,
    latestNews: transformNewsItems(news.edges)
      .filter(newsItem => {
        return newsItem && newsItem.date;
      })
      .sort((firstNewsItem: any, secondNewsItem: any) => {
        // eslint-disable-next-line no-nested-ternary
        return firstNewsItem.date > secondNewsItem.date ? -1 : firstNewsItem.date < secondNewsItem.date ? 1 : 0;
      })
      .map((newsItem: any) => {
        return {
          ...newsItem,
          image: { ...newsItem.image, asset: { gatsbyImageData: newsItem.image?.imageWithFocalPoint } },
        };
      })
      .slice(0, 3),
    shortDescription: extractShortDescription(item),
    title: item.title,
  };
};

export default transformLatestNewsSection;
