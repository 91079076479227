import { ContentfulEmbeddedContent } from '../../__generated__/graphql-types';

const transformEmbeddedContent = (item: ContentfulEmbeddedContent) => {
  return {
    __typename: 'EmbeddedContent',
    ID: item.ID,
    description: item.description,
    embedType: item.embedType,
    key: item.id,
    title: item.title,
  };
};
export default transformEmbeddedContent;
