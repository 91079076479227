import React, { ChangeEvent, FocusEvent, ForwardedRef, ReactElement, forwardRef } from 'react';
import { tv } from 'tailwind-variants';

import type { EventType, FieldType, FormDataTypeOptions } from '../../../../types';

import { StyledError, StyledFormRow, StyledIcon, StyledLabel, StyledTextArea, StyledTextInput } from './Styled';

type InputProps = {
  field: FieldType;
  label: string;
  placeholder?: string;
  handleChange?: (e: EventType, name: FormDataTypeOptions) => void;
  handleKeydown?: (e: React.KeyboardEvent<any>, name: FormDataTypeOptions) => void;
  textarea?: boolean;
  hideLabel?: boolean;
  disabled?: boolean;
  icon?: ReactElement;
  type?: string;
};

const styling = tv({
  slots: {
    disabledStyling: 'opacity-40 pointer-events-none',
  },
});

const InputField = forwardRef<HTMLDivElement, InputProps>(
  (
    {
      field,
      label,
      placeholder,
      disabled = false,
      handleChange,
      textarea,
      handleKeydown,
      hideLabel,
      icon,
      type = 'text',
    }: InputProps,
    ref: ForwardedRef<any>,
  ) => {
    const inputProps = {
      autoComplete: 'off',
      disabled,
      id: field.name,
      name: field.name,
      onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        handleChange(e, field.name);
      },
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        handleChange(e, field.name);
      },
      onKeyDown: (e: React.KeyboardEvent<any>) => {
        if (handleKeydown) handleKeydown(e, field.name);
      },
      placeholder,
      type,
      value: field.value,
      withIcon: !!icon,
    };

    const { disabledStyling } = styling();

    return (
      <StyledFormRow className={field.error.length !== 0 ? 'error' : ''} ref={ref}>
        <StyledLabel isError={!!field.error} htmlFor={field.name} hideFromView={hideLabel}>
          {label}
        </StyledLabel>
        {disabled ? (
          <div className={disabledStyling()}>
            {textarea ? <StyledTextArea {...inputProps} /> : <StyledTextInput {...inputProps} />}
          </div>
        ) : textarea ? (
          <StyledTextArea {...inputProps} />
        ) : (
          <StyledTextInput {...inputProps} />
        )}
        {field.error && <StyledError>{field.error}</StyledError>}
        {icon && !textarea && <StyledIcon labelHidden={hideLabel}>{icon}</StyledIcon>}
      </StyledFormRow>
    );
  },
);

InputField.displayName = 'InputField';

export default InputField;
