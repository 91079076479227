import type { CustomBlock, CustomBlocksSection } from '../../@types';
import extractCta from '../Cta';
import extractShortDescription from '../ShortDescription';

export const extractCustomBlocks = (customBlocks: CustomBlock[]) => {
  if (!customBlocks) {
    return [];
  }
  return customBlocks
    .map((item: CustomBlock) => {
      if (!item) {
        return null;
      }

      const link = extractCta(item);
      return {
        id: item.id,
        image: item.image?.imageWithFocalPoint,
        link: link?.to,
        linkLabel: link?.label,
        shortDescription: extractShortDescription(item),
        showLink: item.showCtaLinkText
          ? item.showCtaLinkText
          : item.showCtaLinkText !== null
            ? item.showCtaLinkText
            : undefined,
        title: item?.title,
      };
    })
    .filter(Boolean);
};

const isTwoColumns = (numberOfCards: number) => {
  if (numberOfCards === 2 || numberOfCards === 4) {
    return true;
  }

  return false;
};

const transformCustomBlocksSection = (item: CustomBlocksSection) => {
  const data = {
    __typename: 'CustomBlocksSection',
    customBlocks: extractCustomBlocks(item?.customBlockCards || null),
    displayCtaCopy: item.displayCtaCopy,
    key: item.id,
    shortDescription: extractShortDescription(item),
    title: item.title,
    twoColumns: isTwoColumns(item?.customBlockCards?.length),
  };

  return data;
};

export default transformCustomBlocksSection;
