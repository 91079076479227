import { type LocaleType, locales } from '@arvesta-websites/i18n';
import { navigate } from 'gatsby-link';
import * as React from 'react';
import { useIntl } from 'react-intl';

import {
  StyledColumn,
  StyledFooterBlock,
  StyledFooterBlockContainer,
  StyledFooterBlockSocial,
  StyledFooterInnerContainer,
  StyledFooterLangSwitcher,
  StyledFooterLangSwitcherLink,
  StyledFooterLangSwitcherListItem,
  StyledFooterSocialIcon,
  StyledFooterWrapper,
  StyledLegalText,
  StyledLogo,
  StyledRow,
} from './Styled';

interface Props {
  currentLocale: LocaleType;
  activeLocales: Array<string>;
}

const renderDisclaimerLink = (currentLocale: LocaleType) => {
  switch (currentLocale.id) {
    case 'en':
      return <a href="https://arvesta.eu/en/disclaimer">Disclaimer</a>;
    case 'nl':
      return <a href="https://arvesta.eu/nl/gebruiksvoorwaarden">Gebruiksvoorwaarden</a>;
    case 'fr':
      return <a href="https://arvesta.eu/fr/avertissement">Avertissement</a>;
    case 'de':
      return <a href="https://arvesta.eu/de/haftungsausschluss">Haftungsausschluss</a>;
    default:
      return null;
  }
};

const renderLocalisedVat = (currentLocale: LocaleType) => {
  switch (currentLocale.id) {
    case 'en':
      return 'VAT BE';
    case 'nl':
      return 'BTW BE';
    case 'fr':
      return 'TVA BE';
    case 'de':
      return 'Mehrwertsteuer BE';
    default:
      return null;
  }
};

const LegacyHeader = ({ currentLocale, activeLocales }: Props) => {
  const intl = useIntl();
  const enabledLocales: Array<LocaleType> = [];

  const handleLocaleChange = (locale: string) => {
    navigate(`/${locale}`);
  };

  Object.keys(locales).forEach((id: string) => {
    const locale: LocaleType = locales[id];
    if (activeLocales.includes(locale.id)) {
      enabledLocales.push(locale);
    }
  });

  return (
    <StyledFooterWrapper>
      <StyledFooterInnerContainer>
        <StyledRow>
          <StyledColumn>
            <StyledFooterLangSwitcher>
              {enabledLocales.map((item: LocaleType) => (
                <StyledFooterLangSwitcherListItem
                  key={item.id}
                  value={item.path}
                  onClick={() => handleLocaleChange(item.path)}
                >
                  <StyledFooterLangSwitcherLink className={item.id === currentLocale.id ? 'active' : ''}>
                    {item.path}
                  </StyledFooterLangSwitcherLink>
                </StyledFooterLangSwitcherListItem>
              ))}
            </StyledFooterLangSwitcher>
            <StyledLogo href="https://arvesta.eu/en">
              <img src="/images/legacy-header-logo.png" width="200" alt="arvesta" />
            </StyledLogo>
            <StyledFooterBlockContainer>
              <StyledFooterBlock>
                <strong>{intl.formatMessage({ id: 'legacy.footer.headoffice' })}</strong>
                <br /> Arvesta
                <br /> Philipssite 5, bus 3
                <br /> 3001 Leuven
                <br /> Belgium
              </StyledFooterBlock>
              <StyledFooterBlock>
                <strong>{intl.formatMessage({ id: 'legacy.footer.headquarters' })}</strong>
                <br /> AVEVE NV
                <br /> Aarschotsesteenweg 84
                <br /> 3012 Wilsele (Leuven)
                <br /> Belgium
              </StyledFooterBlock>
              <StyledFooterBlock>
                tel +32 16 24 26 26
                <br /> info@arvesta.eu
              </StyledFooterBlock>
              <StyledFooterBlockSocial>
                <a href="https://www.facebook.com/GroupAVEVE" rel="noopener noreferrer" target="_blank">
                  <StyledFooterSocialIcon className="fa fa-facebook" />
                </a>
                <a
                  className="social-youtube"
                  href="https://www.youtube.com/channel/UCCi99cloBu0ZcTi94Hq1IUw"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <StyledFooterSocialIcon className="fa fa-youtube-play" />
                </a>
                <a
                  className="social-linkedin"
                  href="https://www.linkedin.com/company/aveve"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <StyledFooterSocialIcon className="fa fa-linkedin" />
                </a>
              </StyledFooterBlockSocial>
            </StyledFooterBlockContainer>
            <StyledLegalText>
              © 2018 Arvesta - {renderLocalisedVat(currentLocale)} 0403 552 464, RPR Leuven -{' '}
              {renderDisclaimerLink(currentLocale)} -{' '}
              <a href={`https://arvesta.eu/${currentLocale.id}/privacy-policy`}>Privacy Policy</a> -{' '}
              <a href={`https://arvesta.eu/${currentLocale.id}/cookie-policy`}>Cookie Policy</a>
            </StyledLegalText>
          </StyledColumn>
        </StyledRow>
      </StyledFooterInnerContainer>
    </StyledFooterWrapper>
  );
};

export default LegacyHeader;
