import { AuthProvider } from '@arvesta-websites/ui-babel';

import PageWrapper from './src/ui-lib/components/Layout/PageWrapper';
import './src/styles.css';

const theme = process.env.THEME_NAME;
const defaultLocale = process.env.DEFAULT_LOCALE;
const locales = process.env.ENABLED_LOCALES.split(',').map(locale => locale.trim());

export const wrapPageElement = ({ element, props }) => {
  return (
    <PageWrapper {...props} theme={theme} defaultLocaleId={defaultLocale} enabledLocales={locales}>
      <AuthProvider>{element}</AuthProvider>
    </PageWrapper>
  );
};
