import { IntlContext } from '@arvesta-websites/i18n';
import * as React from 'react';

//

const { SITE_URL } = process.env;
const dontPrefixPaths = process.env.DONT_PREFIX_PATHS_LOCALE === 'true';

/* eslint-disable import/prefer-default-export */
// This will make sure IE and Safari are correctly polyfilled...
// ... When will they die already?
export const getBrowserPolyfills = () => `
;(function(){
  if (typeof window !== "undefined" && (!window.Promise || !window.fetch || !window.Symbol)) {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.setAttribute('src','https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?version=4.8.0&features=Promise%2CSymbol%2Cfetch%2CArray.from%2CString.prototype.endsWith%2CPromise.prototype.finally%2CNodeList.prototype.forEach');
    script.setAttribute('type','text/javascript');
    script.setAttribute('async','false');
    head.appendChild(script);
  }
}());`;
/* eslint-enable import/prefer-default-export */

export const isInternalUrl = (url: string) => {
  const toUrl = url && url.replace(SITE_URL || '', '');

  const isExternal =
    /^https?.*/i.test(toUrl) || toUrl.startsWith('//') || toUrl.startsWith('mailto:') || toUrl.startsWith('www.');

  return !isExternal;
};

export const getLocale = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl: any = React.useContext(IntlContext);

  return intl;
};

export const getIntlSlug = (slug = '') => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl: any = React.useContext(IntlContext);

  if (dontPrefixPaths) {
    return `/${slug}`.replace('//', '/');
  }

  if (!slug.startsWith(`/${intl.path}`) || !slug.startsWith(`${intl.path}/`)) {
    return `/${intl.path}/${slug}`.replace('//', '/');
  }

  return slug;
};

export const chunkArray = (arr: any[], chunkSize: number) =>
  Array.from(
    {
      length: Math.ceil(arr.length / chunkSize),
    },
    (_, index) => {
      const chunk = arr.slice(index * chunkSize, index * chunkSize + chunkSize);
      const key = chunk.reduce((acc, { id }) => `${acc}-${id}`, '');

      return {
        chunk,
        key,
      };
    },
  );

export const chunkArrayCategories = (array: any, size: any) => {
  const chunked = [];
  let index = 0;

  while (index <= array.length) {
    const chunk: Array<any> = array.slice(index, index + size);
    const key = chunk.reduce((acc: any, { id }) => `${acc}-${id}`, '');
    if (chunk.length > 0) {
      chunked.push({
        chunk,
        key,
      });
    }
    index += size;
  }

  return chunked;
};
