import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';

import { withErrorBoundary } from '../../../../components';
import Lock from '../../../../components/Lock/Lock';
import { useAuth } from '../../../../shared/auth/authContext';

import { StyledDescription, StyledImageWrapper, StyledLink, StyledTitle, StyledWrapper } from './Styled';

type Image = {
  title: string;
  alt?: string;
  asset: { gatsbyImageData: IGatsbyImageData };
};

export interface CarouselItemCardProps {
  key: string;
  title?: string;
  description?: string;
  image?: Image;
  ctaLabel?: string;
  link?: string;
  gated?: boolean;
}

const styling = tv({
  slots: {
    imageWrapper: 'relative',
  },
});

const getImageData = (image?: Image): IGatsbyImageData | null => {
  if (image && image.asset) {
    return image.asset.gatsbyImageData;
  }

  console.warn('NewsCard: No image data provided', JSON.stringify(image));

  return null;
};

const CarouselItemCard = ({ title, description, image, ctaLabel, gated, link, ...rest }: CarouselItemCardProps) => {
  const intl = useIntl();

  const gatedContentAvailable = process.env.GATED_CONTENT && process.env.GATED_CONTENT === 'true';
  const contentfulGated = gated;

  const isLockedForNonAuthenticatedUsers = gatedContentAvailable && contentfulGated;
  const auth = useAuth();
  if (!auth) {
    return null;
  }
  const { state } = auth;
  const { imageWrapper } = styling();

  return (
    <StyledWrapper to={link} {...rest}>
      <StyledImageWrapper className={imageWrapper()}>
        {image && getImageData(image) && (
          <GatsbyImage
            alt={image.alt ? image.alt : image.title}
            image={getImageData(image)}
            objectFit="cover"
            style={{ height: '100%', margin: '0 auto', width: '100%' }}
          />
        )}
        {isLockedForNonAuthenticatedUsers && !state.isAuthenticated && <Lock />}
      </StyledImageWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledWrapper>
  );
};

export default withErrorBoundary(CarouselItemCard, { componentName: 'CarouselItemCard' });
